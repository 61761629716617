$(document).on("turbolinks:load", events);

function events() {
  var companies_filter_id = "dropdown-companies-filter";
  var documents_filter_id = "dropdown-documents-filter";
  var filter_items_for_select =
    "#status-item, #synchronized-item, #executive-item, #client-item, #type-of-document-item, #business-item";
  var actual_element_clicked = "";

  function check_if_element_is_inside_combobox(e) {
    return (
      $(`#${companies_filter_id}`).find(e.target).length > 0 ||
      $(`#${documents_filter_id}`).find(e.target).length > 0
    );
  }

  function resetAllElements() {
    $("#dropdown-documents-filter > li.selected").removeClass("selected"); // unclick the selected list
    $("#dropdown-companies-filter > li.selected").removeClass("selected"); // unclick the selected list
    $("#status-item").removeClass("selected");
    $("#synchronized-item").removeClass("selected");
    $("#executive-item").removeClass("selected");
    $("#client-item").removeClass("selected");
    $("#type-of-document-item").removeClass("selected");
    $("#business-item").removeClass("selected");
  }

  function check_only_one_menu_selected(last) {
    //disable all, and left active only the last one
    resetAllElements();
    last.addClass("selected");
  }

  //simulate hover on click for mobile viewers
  function dropdown_click() {
    var dropdown_companies = $(
      `#${companies_filter_id}, #${documents_filter_id}`
    );

    window.addEventListener("click", function (e) {
      if (check_if_element_is_inside_combobox(e)) {
        e.stopPropagation();
        dropdown_companies.addClass("show"); //mantain open the dropdown

        var closest_li = $(e.target).closest(
          "#dropdown-documents-filter > li, #dropdown-companies-filter > li"
        );

        if ($(e.target).is(filter_items_for_select)) {
          $(e.target).parent().addClass("selected");
        } else if (closest_li.length > 0) {
          closest_li.addClass("selected");
        }
      } else {
        dropdown_companies.removeClass("show");
        resetAllElements();
      }
    });
  }

  $(filter_items_for_select).on("click", function () {
    actual_element_clicked = $(this);
    check_only_one_menu_selected($(this));
  });

  dropdown_click();
}
