$( document ).on('turbolinks:load', events)

function events(){
  $('.load-data-url-on-load').each((_index, element) => {
    const $element = $(element)
    const urlArray = $element.data('url').split(',')
    const method = $element.data('method')

    urlArray.forEach(url => {
      $.ajax({
        type: method || "GET",
        url: url,
        dataType: "script"
      });
    })
  })
}