function formatDateTime(date = new Date()) {
  const formatDate = new Date(date)
  const month = String(formatDate.getMonth() + 1).padStart(2, 0)
  const day = String(formatDate.getDate()).padStart(2, 0)
  const year = String(formatDate.getFullYear()).padStart(4, 0)
  const minutes = String(formatDate.getMinutes()).padStart(2, 0)
  const hour = String(formatDate.getHours()).padStart(2, 0)
  return `${day}-${month}-${year} ${hour}:${minutes}`;
}

module.exports = formatDateTime