$( document ).on('turbolinks:load', common_events)

function common_events(){


    // send ajax form comments
    $('body').on('click', '.send-comment', function(){
        let commentableType = $(this).data("commentable_type")
        let url = $(this).data("url")
        let commentId = $(this).data("id")
        let message = $('#' + $(this).data("input")).val()
        let user = $(this).data("user")
        let commentType = $(this).data("comment_type")
        send_comment(url, commentId, commentableType, message, user, commentType)
    })

    $('.box-coments').on('load',function(){
        $(".box-coments").animate({ scrollTop: $('.box-coments').prop("scrollHeight")}, 1000);
    });

    $('body').on('click','.link-load-more-commentaries' ,function () {
        let $link = $(this);
        let dealId = $(this).data('id')
        let comment_type = $(this).data('comment-type')
        if (comment_type === "" || comment_type === undefined) comment_type = "commentary"
        let url = "/comments"
        $.ajax({
            type: "GET",
            url: url,
            dataType: "script",
            data:{
                deal:dealId,
                comment_type: comment_type
            },
            success: function () {
              $('.loader').fadeOut(500);
              $link.remove();
            },
        });
    })

    $('body').on('click', '.link-reply-comment', function () {
        let id = $(this).data('id')
        $('#form-group-'+ id).toggleClass('d-none')
    })


    $('body').on('click', '.show-replies', function () {
        let id = $(this).data('id')
        let content = $(this).text().trim()
        if(content == "Mostrar respuestas"){
            $(this).html("Ocultar respuestas")
        }else{
            $(this).html("Mostrar respuestas")
        }
        $('#commentary-'+ id).slideToggle('slow')
    })

    function send_comment(url, commentId, commentableType, message, user, commentType){
        $.ajax({
            type: "POST",
            url: url,
            dataType: "script",
            data:{
                commentable_id:commentId,
                commentable_type:commentableType,
                message:message,
                user_id:user,
                comment_type:commentType
            },
        });
    }
    

}