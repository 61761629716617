
$( document ).on('turbolinks:load', events)

function connect () {
  const $roomDataInput = $('input#room_data')
  const room_type = $roomDataInput.data('room-type')
  const room_id = $roomDataInput.data('room-id')
  const current_user_id = $roomDataInput.data('current-user-id')
  window.createRoom(room_id, room_type, current_user_id)
}

function events(){
  connect()
}