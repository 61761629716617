$( document ).on('turbolinks:load', events)

function events(){
   const moment= require('moment/moment.js');
   $('#todos').on('change', function(){
      let inputs = $(this).parent().parent().find("input[type='checkbox']")
      if($(this).is(':checked')){
         inputs.prop('checked', true)
         $('#form-email').removeClass('d-none')
         
      }else{
         $('#form-email').addClass('d-none')
         inputs.prop('checked', false)
      }
   })

   $('.check-send-document').on('change', function(){
      if($('#todos').is(':checked')){
         $('#todos').prop('checked', false)
      }
   })
   

   $('#mail').on('change', function(){
      if($(this).is(':checked')){
         $('#form-email').removeClass('d-none')
      }else{
         $('#form-email').addClass('d-none')
      }
   })

   $('.input-file-send-document').on('change', function(){
      let file = this.files[0];
      let name = file.name;
      if(getFileExtension3(name) == 'pdf'){
         let today = new Date();
         let date = moment(today).format("DD-MM-YYYY");
         $('.file-area').addClass('d-none')
         $('#success-adjunt-document').removeClass('d-none')
         $('#name-file').html(name);
         $('#date-send-document').html(date);
      }else{
         $(this).val('');
         swal({title:"Error de archivo", text:'No es un formato de archivo válido, solo puedes subir pdf', button:'Aceptar'})
      }

   })

   function getFileExtension3(filename) {
      return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
   }

   $('body').on("click", ".show-document-send", function(){
      $('#document-display-div').html($('<embed></embed>', {
         width: "100%", height: "50vh",
         src : this.dataset.url,
         id: "document-opener"
      }))
      $('#show-document').modal('toggle')
   })

   $('body').on("click", ".button-aproved", function(){
      let value = $(this).data('value')
      $("#is_approved").val(value)
      if(!value){
         $('#observations').removeClass('d-none')
      }else{
         $('#observations').addClass('d-none')
         $('#observations').find('textarea').val('')
         $('.submited').click()
      }
   })
   
}