const formatNumber = require('../utils/format_number')

$(document).on('turbolinks:load', function() {
    $('body').on('input', '.mask-input', function(event) {
        const value = validateNewValue(event, $(this))

        const newValue = addNumberMask(value, $(this), event)

        const unMaskedValue = parseMaskedValueToNumber(newValue)

        $(this).val(newValue)
        $(this).siblings('.mask-target').val(unMaskedValue)
    })
})

function validateNewValue(event, $input) {
    const value = $input.val()
    const previousValue = value.slice(0, value.length - 1)
    const newValue = event?.originalEvent?.data
    const decimalMask = $input.attr('mask-decimals')

    if (decimalMask && newValue == ',' && previousValue.includes(',')) return previousValue

    if (newValue !== undefined && isNaN(Number(newValue))) return previousValue

    return value
}

function addNumberMask(validatedValue, $input, event) {
    if (validatedValue.toString().length == 0) return validatedValue

    const newValue = event?.originalEvent?.data
    const maskMiles = $input.attr('mask-miles')
    const maskDecimals = $input.attr('mask-decimals')
    const [integers, decimals] = validatedValue.toString().replaceAll('.', '').replaceAll(',', '.').split('.')
    let result = ''

    if (maskMiles) result += addMilesMask(integers)

    if (maskDecimals) {
        if (decimals || newValue == ',') {
            result += ','
        }

        if (decimals) {
            result += decimals
        }
    }

    return result
}

function addMilesMask(integers) {
    if (integers.toString().length == 0) return integers

    return formatNumber(integers)
}

function parseMaskedValueToNumber(value) {
    const cleanedValue = value.toString().replaceAll('.', '').replaceAll(',', '.')
    return Number(cleanedValue)
}