
$( document ).on('turbolinks:load', events)



function events(){
    $('body').on('change', '#input-deal-archives', function () {
        let files = this.files;
        let url = $(this).data('url');
        var validExtensions = ["png", "jpg", "jpeg", "pdf", "xml", "xlsx", "xls", "doc", "docx"];
        errors = [];
        for (let index = 0; index < files.length; index++) {
            const element = files[index];
            var name = getFileExtension3(element.name);
            if (!validExtensions.includes(name)){
                errors.push("Archivo " + element.name);
            }
        }
        if (errors.length == 0){
            injectLoader(files.length);
            sendArchives(files, url)
        }else{
            var errorHtml = "<div>";
            errors.forEach(function(error){
                errorHtml += "<p>" + error + "</p>";
            })
            errorHtml += "</div>";
            $modals.info({title: 'No podemos procesar los siguientes archivos, extensión no permitida.', message:errorHtml})
        }

    });

    function sendArchives(files, url){
        var formData = new FormData();
        for (var i = 0; i < files.length; i++) {
            formData.append('archives[]', files[i]);
        }

        $.ajax({
            url:url,
            type:'POST',
            dataType: 'script',
            data:formData,
            processData: false,
            contentType: false
        })
    }

    function getFileExtension3(filename) {
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
    }

    function injectLoader(count){
        var container = $('#deal-archives-list');
        for (var i = 0; i < count; i++) {
            var div = $("<div class='gradient-bg-animation archive-row'></div>");
            $(container).prepend(div);
        }
    }

}