$(document).on("turbolinks:load", common_events);

function common_events() {
  $("body").on("change", "#button-add-portfolio", function () {
    $(".loader").fadeIn(100);
    let files = this.files;
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      var name = element.name.split(".")[0];
      var extension = getFileExtension3(element.name);
      if (extension === "xlsx" || extension === "xls") {
        readPortfolio(element, name);
      } else {
        alert("El archivo no es un archivo de lectura válido.");
      }
    }
    $(this).val("");
  });

  var XLSX = require("xlsx/xlsx.js");
  const moment = require("moment/moment.js");

  function readPortfolio(e, nameFile) {
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      var workbook = XLSX.read(data, { type: "binary" });
      let portfolio = [];

      workbook.SheetNames.forEach(function (sheetName) {
        // Here is my object
        var XL_row_object = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheetName],
          { header: 1 }
        );
        var count = 0;
        for (let i = 0; i < XL_row_object.length; i++) {
          const element = XL_row_object[i];
          count += 1;
          // Separate of data
          if (!isNaN(element[4])) {
            let portfolio_item = {
              name_file: nameFile,
              rut: rutParse(element[0]),
              name_client: element[1],
              number_operation: element[4],
              document_type: element[5],
              expire_date: moment(ExcelDateToJSDate(element[15])).format(
                "DD-MM-YYYY"
              ),
              date_cesion: moment(ExcelDateToJSDate(element[7])).format(
                "DD-MM-YYYY"
              ),
              financed_amount: element[11],
              financed_amount_balance: element[12],
              financed_balance: element[26],
              status: element[13],
              number_invoice: element[6],
              debtor_rut: element[2],
              debtor_name: element[3],
              upfront_percentage: element[8],
              last_date_management: moment(ExcelDateToJSDate(element[19])).format("DD-MM-YYYY") == "Invalid date" ? "": moment(ExcelDateToJSDate(element[19])).format("DD-MM-YYYY"),
              collection_observation: element[20],
              collection_executive: element[21],
              sales_executive: element[22],
              collection_status: element[24],
            };

            portfolio.push(portfolio_item);
          }
        }

        sendParamsToBD(portfolio);
        getPortfolio(nameFile);
      });
    };
    reader.onerror = function (ex) {
      console.error(ex);
    };

    reader.readAsBinaryString(e);
  }
  function getFileExtension3(filename) {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  }
  function ExcelDateToJSDate(serial) {
    var utc_days = Math.floor(serial - 25567);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);
    var fractional_day = serial - Math.floor(serial) + 0.0000001;
    var total_seconds = Math.floor(86400 * fractional_day);
    var seconds = total_seconds % 60;
    total_seconds -= seconds;
    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;
    return new Date(
      Date.UTC(
        date_info.getFullYear(),
        date_info.getMonth(),
        date_info.getDate(),
        hours,
        minutes,
        seconds
      )
    );
  }
  function sendParamsToBD(portfolio) {
    $.ajax({
      type: "POST",
      url: "/portfolios",
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        portfolio: portfolio,
      }),
    });
  }

  function getPortfolio(nameFile) {
    $.ajax({
      type: "GET",
      url: "/portfolios_charge",
      dataType: "script",
      data: {
        name_file: nameFile,
      },
      success: function (data, textStatus, xhr) {
        if (xhr.status === 202) {
          setTimeout(getPortfolio, 2000, nameFile);
        }
      },
    });
  }

  $("body").on("click", ".button-of-operations", function () {
    var url = $(this).data("url");
    var rut = $(this).data("rut");
    var table = $(this).data("table");
    $("#" + table).removeClass("d-none");
    // $.ajax({
    //     type:"GET",
    //     url:url,
    //     dataType:"script",

    //     success: function () {
    //         $('#' + table).removeClass('d-none');
    //     },
    // });
  });

  $("body").on("click", ".portfolio-link-name-file", function () {
    let name = $(this).data("name");
    let url = $(this).data("url");
    $(".loader").fadeIn(100);
    $.ajax({
      type: "GET",
      url: url,
      dataType: "script",
      data: { name_file: name },
    });
  });

  $("body").on("click", ".button-of-portfolios-group", function () {
    let rut = $(this).data("rut");
    let name = $(this).data("name");
    let url = $(this).data("url");
    let aria = $(this).attr("aria-expanded");
    $(this).find("#rotation_180deg").toggleClass("down");
    if (aria == "false") {
      $(".loader").fadeIn(100);
      $.ajax({
        type: "GET",
        url: url,
        dataType: "script",
        data: { name_file: name, rut: rut },
      });
    }
  });

  function rutParse(rut) {
    let newRut = rut.replace(/\./g, "").replace(/\-/g, "").trim().toLowerCase();
    let lastDigit = rut.slice(-1);
    let numberRut = rut.slice(0, newRut.length - 1);
    let rutFormat = numberRut.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return (rut = rutFormat + "-" + lastDigit);
  }

  function load_loaders_for_more_data_portfolio(){
    let loader = `<div class="small-ring lds-ring ring-primary"><div></div><div></div><div></div><div></div></div>`;
    $('.date-last-collection-management').html(loader);
    $('.collection-status').html(loader);
    $('.collection-observation').html(loader);
    $('.sales-executive-name').html(loader);
    $('.debtor-folio').html(loader);
    $('.debtor-name').html(loader);
  }

  $('body').on('click','.see-more-data-portfolio' , function(){
    $('loader').fadeIn('100');
    load_loaders_for_more_data_portfolio();
    $.ajax({
      type: "GET",
      url: "/see_more_data_portfolio",
      dataType: "script",
      data: { id: $(this).data("portfolio-id")},
      success: function() {
        $('loader').fadeOut('500');
      }
    });
  });

  $('body').on('keydown', '#portfolio-filter', function(){
    console.log("portfolio input changed...")
    const dataFileName = $('#portfolio-filter').data('file-name');
    const fileName = $('#name-file').html();

    dataFileName != fileName && $('#portfolio-filter').data('file-name', fileName);
  
    const hiddenFileName = $('#name-file-input').val();
    fileName != hiddenFileName && $('#name-file-input').val(fileName);

  
  })
}
