const formatNumber = require('../utils/format_number')
const decamelize = require('decamelize-keys-deep');

function formatMaskNumbers(){
    $('.miles').mask("000.000.000.000", {reverse: true});
    $('.days').mask("000.000", {reverse: true})
    $('.decimal').mask('##0,00', {
        reverse: true,
        placeholder: "0,00",
        maxValue: 100
    })
    $("#upfront_percentage_full").mask("999,99")
};

$( document ).on('turbolinks:load', common_events)
function common_events(){
    require("./client_business_modal")
  require("./partial_deal")();

    var loadingStatus = false;

    $('body').on('load:status:done', function (){
        loadingStatus = false;
    })

    $(".decimal-2").mask("999,99");

    $('body').on('input', '.decimal-2', function() {

        let inputValue = this.value;

        inputValue = inputValue.replace(/[^0-9,.]/g, '');

        // convert commas to point to parse correctly the numbers after commas
        inputValue = inputValue.replace(',', '.');

        if (inputValue.startsWith('-')) {
            inputValue = inputValue.slice(1);
        }

        if (parseFloat(inputValue) < 0.00) {
            inputValue = '0';
        } else if (parseFloat(inputValue) > 100.00) {
            inputValue = '100';
        }

        // replace back points to commas
        inputValue = inputValue.replace('.', ',');
        this.value = inputValue;
    });

    /* var bulletin_status_client = false;
    var bulletin_status_documents = false;
    */
    var bulletin_job_load = false;

    /* $('body').on('load:bulletin_status', function(e,rut){
        update_bulletin_status(rut);
    })

    $('body').on('load:bulletin_status:done', function (){
    }) */

    // Number with points helper 1000 -> 1.000
    function numberWithPoints(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    function truncateString(string, lenght){
        return string.substring(0, lenght) + '...'
    }

    $('#gen-new').modal('hide')


    $('.js-basic-single').select2();


    formatMaskNumbers()

    $('body').on('load', '#legal-representative-form', function(){
        if($(this).find('.input-legal-representative-hidden').length > 0){
            // alert('Debes seleccionar al menos un representante');
            $(this).find('input[type="submit"]').removeClass('disabled');
        }else{
            $(this).find('input[type="submit"]').addClass('disabled');
        }
    })

    $('#legal-representative-form').on("submit", function (e){
        if($(this).find('.input-legal-representative-hidden').length < 1){
            e.preventDefault();
            $('#legal-representative-modal').modal('show');
            setTimeout(function(){
                $('#legal-representative-modal').modal('hide');
            }, 3000);
        }
    })


    $('body').on('click', '#keep-input-data-button', function () {

        let inputData = getInputData();
        let expInput = $('#document-form-modal-dnum').find('.expire-at-input')
        let issuedInput = $('#document-form-modal-dnum').find('.issued-at-input')

        if(expInput.val() == undefined || expInput.val() == null || expInput.val() == ''){
            alertIfNotDate(expInput, issuedInput)
        }else{
            clearInputsAndSave(inputData)
        }

    })

    $('body').on('click', '#use-existing-data-button', function () {
        $modal = $("#doc-exists-modal")
        let expDate, issuedDate
        expDate = $modal.find('.expire-at-input').val()
        issuedDate = $modal.find('.issued-at-input').val()
        let inputData = {
            companyId: $('#input-with-company-id').val(),
            folio: $('#input-folio-deal').val(),
            date: issuedDate,
            expDate: expDate,
            rutReceptor: formatNumber($modal.find('.rut-input').val()),
            debtorName: $modal.find('.name-input').val(),
            amount: $modal.find('.total-amount-input').val(),
            bank: $modal.find('.bank-input').val(),
            bankPlace: $modal.find('.place-input').val(),
            account: $modal.find('.account-number-input').val()
        }
        if(expDate == undefined || expDate == null || expDate == ''){
            alertIfNotDate($modal.find('.expire-at-input'), $modal.find('.issued-at-input'))
        }else{
            clearInputsAndSave(inputData)
        }

    })

    $('body').on('click', '#button-add-resources', function () {
        $('.loader').fadeIn();
        const addButton = $(this);
        addButton.attr('disabled', 'disabled')
        const url = $(this).data('url')
        const resourceType = $('#input_resource_type').val();
        const description = $('#input_description').val();
        const quantity = $('#input_quantity').val();
        const amount = $('#input_amount').val().replaceAll('.','')
        const deliveryDate = $('#input_delivery_date').val();
        const dealId = $('#deal-id').val()

        const data = decamelize({
            resource: {
                dealId,
                resourceType,
                description,
                quantity,
                amount,
                deliveryDate,
            }
        });

        $.ajax({
            type: "POST",
            url,
            data,
            dataType: "script",
        });
    })

    $('body').on('click', '.remove-resource-button',  function () {
        const id = $(this).data('id')

        swal({
            html: true,
            title: 'Aviso!',
            text: "¿Estás seguro de eliminar este activo?",
            buttons: ["Cancelar", "Continuar"],
            dangerMode: false,
        }).then((isConfirming) => {
            if (isConfirming) {
                $.ajax({
                    type: "DELETE",
                    url: `/remove_resource/${id}`,
                    dataType: 'script'
                });
            }
        });
    })

    function getInputData(){

        return {
            companyId: $('#input-with-company-id').val(),
            folio: $('#input-folio-deal').val(),
            date: $('#input-date-deal').val(),
            expDate: $('#input-exp-date-deal').val(),
            rutReceptor: formatNumber($('#input-rut-receptor-deal').val()),
            debtorName: $('#input-name-receptor-deal').val(),
            amount: $('#input-amount-deal').val(),
            bank: $('#input-bank-deal').val(),
            bankPlace: $('#input-bank-place-deal').val(),
            account: $('#input-bank-account-deal').val()
        }

    }

    function clearInputsAndSave(data){
        $('.modal').modal('hide')
        $('.error').addClass('d-none')
        $('.is-invalid').removeClass('is-invalid')
        let inputs = $('.inputs-documents');

        for (let index = 0; index < inputs.length; index++) {
            const element = inputs[index];
            cleanInputs(element);
        }

        replace_info_document(data.folio, data.date, data.rutReceptor, data.amount, data.companyId, "",
            data.debtorName, "","", "",data.expDate,"", data.bank, data.bankPlace, data.account);

        $("#input-bank-deal").val('').trigger('change')
        $("#input-bank-place-deal").val('').trigger('change')
        $('#submit-documents-true').click()
    }

    $('body').on('click', '.save-deal-and-documents', function (e) {
        $('.loader').fadeIn(100)
        if($('#number-of-documents').html() === "0"){
            e.preventDefault();
            swal("No has ingresado facturas",{button:"Aceptar"});
        }
    })

    function subtractionInput(val) {

        val=val.toString().replace(/\./g, '')

        if($('#box-total-amount').html() !== undefined){

            var value = parseFloat(val)
            var subtraction = parseFloat($('#box-total-amount').html().replace(/\./g, '').replace(/\$/g, ''));
            if(subtraction > 0){
                subtraction -= value
            }
            var format = formatNumber(subtraction);
            if(subtraction > 0){
                $('#box-total-amount').html('')
                $('#box-total-amount').html(format)
                $('#total-amount-simulation').html("$ "+format)
            }else{
                $('#box-total-amount').html("0")
                $('#total-amount-simulation').html("0")
            }
            subtractionTr()
        }
    }

    function sumDocumentsTotalAmount() {
        var array1 = [];
        var sum = 0;
        var amount_documents = $('.total-amount-documents');
        for(var i = 0; i < amount_documents.length; i++){
            let element = amount_documents[i];
            array1.push(element.value.toString().replace("$","").replace(/\./g, '')
                .replace(",", "."));
        }

        array1.forEach (function(array1){
            let float = parseFloat(array1);
            if(!isNaN(float)){
                sum += float;
            }
        });
        var array = []
        array = sum.toFixed(0).toString()
        var total = formatNumber(array);
        $('#box-total-amount').html("$ " + total.toString())
        $('#total-amount-simulation').html("$ " + total.toString())
        $('#total_amount_to_pay').html("--")
        $('#amount_deal').html("--")
        $('#dif_precio_deal').html("--")
        sumTrs()
        array1.length = 0;
    }


    function formatNumber(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    function sumTrs() {
        let elements = $('.total-amount-td');
        var nFilas = [];
        for(let i = 0; i < elements.length; i++) {
            let element = elements[i];
            if($(element).html() != ''){
                nFilas.push($(element));
            }
        }

        let filas = nFilas.length;

        if(filas > 0){
            $('#number-of-documents').html(filas);
            $('.multiple-number-archive').html(filas);
        }else{
            $('#number-of-documents').html("0");
            $('.multiple-number-archive').html("1");
        }
    }

    function subtractionTr() {
        var nDocuments = parseFloat($('#number-of-documents').html());
        if(nDocuments > 0){
            nDocuments -= 1;
        }
        if(nDocuments > 0){
            $('#number-of-documents').html(nDocuments);
            $('.multiple-number-archive').html(nDocuments);

        }else{
            $('#number-of-documents').html("0");
            $('.multiple-number-archive').html("1");
        }
    }

    $('body').on('keyup', '.mil', function () {
        let amount = $(this).val();
        formatNumber(amount)
    })

    function addDisabledToInput(folio) {
        $('.input-disabled-'+ folio).attr('disabled', true)
    }

    // Reader of xml

    function parseTextAsXml(text) {
        var parser = new DOMParser(),
            xmlDom = parser.parseFromString(text, "text/xml");
        var nameEmisor, date, folio, rutReceptor, debtor_name, amount, IVA, mntNeto;
        var companyId = $('#input-with-company-id').val();
        let idDocument = "";
        $(xmlDom).find("DTE").each(function () {
            $(this).find("Documento").each(function(){
                $(this).find("Encabezado").each(function(){
                    $(this).find("Emisor").each(function(){
                        nameEmisor = $(this).find('RznSoc').text();
                    });
                    $(xmlDom).find("IdDoc").each(function () {
                        date = $(this).find('FchEmis').text();
                        folio = $(this).find('Folio').text();
                    });
                    $(xmlDom).find("Receptor").each(function () {
                        let rutParseReceptor = $(this).find('RUTRecep').text();
                        rutReceptor = rutParse(rutParseReceptor)
                        debtor_name = $(this).find('RznSocRecep').text();
                    });
                    $(xmlDom).find("Totales").each(function () {
                        amountUnformat = $(this).find('MntTotal').text();
                        amount = formatNumber(amountUnformat)
                        IVA = $(this).find('IVA').text().split('.');
                        mntNetoUnformat = $(this).find('MntNeto').text();
                        mntNeto = formatNumber(mntNetoUnformat)
                    });
                })
            })
        })
        replace_info_document(folio, date, rutReceptor, amount, companyId, IVA, debtor_name, mntNeto, nameEmisor, idDocument, "")
        //ahora, extraer los elementos del xmlDom y asignarlos a los inputs
    }

    function waitForTextReadComplete(reader) {
        reader.onloadend = function(event) {
            var text = event.target.result;
            parseTextAsXml(text);
        }
    }

    function handleFileSelection(file){
        reader = new FileReader();
        waitForTextReadComplete(reader);
        reader.readAsText(file)
    }

    // Reader of xlsx

    function getFileExtension3(filename) {
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
    }

    var XLSX = require("xlsx/xlsx.js");
    const moment= require('moment/moment.js');

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }




    function ExcelDateToJSDate(serial) {
        var utc_days  = Math.floor(serial - 25567);
        var utc_value = utc_days * 86400;
        var date_info = new Date(utc_value * 1000);
        var fractional_day = serial - Math.floor(serial) + 0.0000001;
        var total_seconds = Math.floor(86400 * fractional_day);
        var seconds = total_seconds % 60;
        total_seconds -= seconds;
        var hours = Math.floor(total_seconds / (60 * 60));
        var minutes = Math.floor(total_seconds / 60) % 60;
        return new Date(Date.UTC(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds));
    }

    var count=0;

    function truncateStringToDropdownHTML(string,length){
        // Truncate debtor name
        var truncate = `
        <span class="popover-on-hover text-nowrap" data-content="${string}">
            ${truncateString(string, length)}
        </span>`;
        return truncate;
    }

    function generateInputDeal(folio, date, rutReceptor, amount, companyId, IVA, debtor_name,
                               mntNeto, nameEmisor, idDocument, expirationDate, transfer_status,
                               bank, bank_place,account, from_sii,replaceTemplate = true){

        let index = parseInt($("#documents-number-field").val()) || 0;

        count++;
        let trTemplate = $('#tr-document-template').html()


        if (replaceTemplate){
            trTemplate = trTemplate.replaceAll('[join_doc_template]', '[deals_documents_attributes]['+index+']')
            trTemplate = trTemplate.replaceAll('join_doc_template', 'deals_documents_attributes_'+ index)
            trTemplate = trTemplate.replaceAll('document_template', 'document_attributes')

            if (folio == 0){
                trTemplate = trTemplate.replaceAll('dnum', count)
            }else{
                trTemplate = trTemplate.replaceAll('dnum', folio)
            }
        }

        trTemplate = '<tr>'+trTemplate+'</tr>';

        let $tr = $(trTemplate);

        $tr.find('.lds-ring').removeClass('d-none');
        $tr.find('.document-number-input').attr('value', folio);
        $tr.find('.issued-at-input').attr({value:date, disabled: from_sii});
        $tr.find('.expire-at-input').attr('value',expirationDate);
        $tr.find('.rut-input').attr({value:rutReceptor,disabled: from_sii});
        $tr.find('.name-input').attr({value:debtor_name,disabled: from_sii});
        $tr.find('.total-amount-input').attr({value:amount,disabled: from_sii});
        $tr.find('.bank-input option[value="'+bank+'"]').attr({selected:true,disabled: from_sii});
        $tr.find('.place-input option[value="'+bank_place+'"]').attr({selected:true, disabled: from_sii});
        $tr.find('.account-number-input').attr({value:account,disabled: from_sii});

        $tr.find('.issued-at-td').html(date);
        $tr.find('.expire-at-td').html(expirationDate);
        $tr.find('.debtor-rut-td').html(rutReceptor);

        $tr.find('.debtor-name-td').html(truncateStringToDropdownHTML(debtor_name, 15));
        $tr.find('.total-amount-td').html('$'+amount);
        $tr.find('.bank-td').html(bank);
        $tr.find('.place-td').html(bank_place);
        $tr.find('.account-number-td').html(account);

        $("#documents-number-field").val(count+1)
        let dteType = $('#deal_product').val();
        if(dteType == 'check'){
            return '<tr id="tr-document-'+rutReceptor.replaceAll('.', '').replace('-', '')+'-'+folio+'">'+$tr.html()+'</tr>'
        }else{
            return '<tr id="tr-document-'+folio+'">'+$tr.html()+'</tr>'
        }

    }

    function renderInputDeal(html, from_sii=false){
        let tbodyDeal = $('#tbody-deal-add-documents');
        let $html = $(html)
        $html.hide().prependTo(tbodyDeal).fadeIn(500);

        $html.find('.bank-input').select2();
        $html.find('.place-input').select2();

        if(from_sii){
            $html.find('.modal').modal("show");
        }

        sumDocumentsTotalAmount()

        MultiplicationForDocument()
        MultiplicationForDebtor()
        calculateCostChecked()
    }


    function deleteTrDocument(data, amount, debtorRut) {
        if($('#deal_product').val() == 'check'){
            $('#tr-document-' + debtorRut.replaceAll('.','').replace('-', '') + '-' + data).fadeOut(500, function() {$(this).delay(2000).addClass("d-none")});
            $('#null-document-'  + debtorRut.replaceAll('.','').replace('-', '') + '-' + data).val(null);
            $('#destroy-document-'  + debtorRut.replaceAll('.','').replace('-', '') + '-' + data).val(true);
        }else{
            $('#tr-document-' + data).fadeOut(500, function() {$(this).delay(2000).addClass("d-none")});
            $('#null-document-' + data).val(null);
            $('#destroy-document-' + data).val(true);
        }

        subtractionInput(amount)
        MultiplicationForDocument()
        MultiplicationForDebtor()
        calculateCostChecked()

        $('#submit-documents-true').click()
    }

    function rutParse(rut) {
        let newRut = rut.replace(/\./g,'').replace(/\-/g, '').trim().toLowerCase();
        let lastDigit = rut.slice(-1);
        let numberRut = rut.slice(0, newRut.length-1)
        let rutFormat = numberRut.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return rut = rutFormat + '-' + lastDigit;
    }

    function cleanInputs(key) {
        $(key).val('');
        $(key).removeClass('disabled-input');
    }

    function hasNumber(myString) {
        return /\d/.test(myString);
    }

    $('body').on('change', '#deal_financing_rate_full', function () {
        const cost = 0.55;
        var dif_precio = $(this).val().replace(',','.');
        let convert = parseFloat(dif_precio);
        var total = convert - cost;
        let value_to_hidden_field = (total/100).toFixed(4)
        let convertToString = total.toFixed(2).toString()

        $('#tasa-total').html(convertToString.replace('.',','))
        $('#deal_spread').val(value_to_hidden_field)

    })

    let linkEditDisabled = $('.form-check-input-cost-deal')
    linkEditDisabled.each(function (input) {
        const $check = $(this)
        let $idLinkEdit = $($check.data('edit'))
        let $classLinkEdit = $($check.data('edit').replace('#', '.'))
        if($check.is(':checked')){
            $idLinkEdit.prop('disabled', true)
            $idLinkEdit.addClass('disabled')
            $classLinkEdit.prop('disabled', true)
            $classLinkEdit.addClass('disabled')
        }
    })

    $('body').on('click', '.edit', function() {
        let tr = $(this).parents('tr');
        tr.find('.expiration-input').removeClass("disabled-deal")
        tr.find('.expiration-input').removeClass("disabled")
        tr.find('.expiration-input').prop("disabled", false)

    })

    $('body').on('click', '.load-status-transfer', function() {
        let folio = $(this).data('folio');
        $('.document-status-'+folio).html('<div class="text-center small-ring lds-ring ring-primary d-flex justify-content-center align-items-center" id="document-status-loader"><div></div><div></div><div></div><div></div></div>')
        $(`#last-feasibility-load-${ folio }`).html('<div class="text-center small-ring lds-ring ring-primary d-flex justify-content-center align-items-center" id="document-status-loader"><div></div><div></div><div></div><div></div></div>')
    })

    $('body').on("change", ".expiration-input", function (){
        if( $(this).hasClass("keep-disabled")){
            $(this).prop("disabled", true);
        }
        $(this).addClass("disabled-deal")
        $(this).addClass("disabled")
    })
    // close modal
    $('body').on("click", ".dismiss-modal", function (){
        $('.modal').modal('hide');
    })

    // In plus or minus button add or subtract the multiple manually

    $('body').on("click", ".btn-link-cost", function () {
        let typeButton = $(this).data('type')
        let costId = $(this).data('costid')
        let amount = $('#' + $(this).data('amount')).val().toString().replaceAll('.','')
        let hiddenMultiplier = $(this).data('multiplier')
        let costType = $(this).data('costtype')
        let value = parseInt($('.multiple-number-'+ costId).html().toString())
        if(typeButton == 'sum'){
            if(value >= 1){
                value++
            }
        }else{
            if(value > 1){
                value--
            }
        }
        let resultado = parseInt(amount) * value;
        $('#amount-total-cost-'+costId).html(formatNumber(resultado))
        $('.multiple-number-'+ costId).html(value)
        $('#'+ hiddenMultiplier).val(value)
    })


    // sum the costs checked
    function calculateCostChecked(){
        let checkbox = $('.form-check-input-cost-deal');
        let totalAmount = [];
        for (var i = 0; i < checkbox.length; i++) {
            let element = checkbox[i];
            if($(element).is(':checked')){
                const elementAmountId = $(element).data('amount')
                let amount = parseInt($(`${elementAmountId?.includes('#') ? '' : '#'}${elementAmountId}`).html().replace('.',''))
                totalAmount.push(amount)
            }
        }
        var numbers = totalAmount, sum = 0;
        numbers.forEach (function(number){
            sum += number;
        });
        $('#amount-cost-deal').html(formatNumber(sum))
    }
    // function to multiply base amount by number of documents
    function MultiplicationForDocument(){
        let numberDocuments = $('#number-of-documents').text() || 1
        $('.multiple-number-archive').html(numberDocuments)
        if (numberDocuments > 0){
            for (let i = 0; i < $('.multiple-number-archive').length; i++) {
                const element = $('.multiple-number-archive')[i];
                var nameCost, costId, multiplier, amount, result;
                nameCost = $(element).data('namecost')
                costId = $(element).data('id').toString()
                multiplier = parseInt($(element).html().toString())
                amount = $(element).data('amount')
                result = formatNumber(amount * multiplier)
                $('#multiplier-value-' + costId).val(multiplier)
                $('#amount-total-cost-' + costId).html(result)
            }
        }
    }
    // function to multiply base amount by number of debtors
    function MultiplicationForDebtor() {
        var arrayDebtorTd = [];
        for (var i = 0; i < $('.input-rut-documents-td').length; i++) {
            let element = $('.input-rut-documents-td')[i];

            if(element.textContent){
                arrayDebtorTd.push(element.textContent)
            }
        }

        const dataArr = new Set(arrayDebtorTd);
        let multiplier = [...dataArr].length;

        for (let i = 0; i < $('.multiple-number-debtor').length; i++) {
            let element = $('.multiple-number-debtor')[i];
            let nameCost = $(element).data('namecost').replace(" ", "-")
            let amount = $('#multiple-number-debtor-'+ nameCost).data('amount')
            let costId = $('#multiple-number-debtor-'+ nameCost).data('id')

            if(multiplier > 1){
                let result = formatNumber(amount * multiplier)
                $('#multiple-number-debtor-'+ nameCost).html(multiplier)
                $('#multiplier-value-'+ costId).val(multiplier)
                $('#amount-total-cost-' + costId).html(result)
            }else{
                $('#multiple-number-debtor-'+ nameCost).html('1')
                $('#multiplier-value-'+ costId).val('1')
                $('#amount-total-cost-' + costId).html(amount)
            }
        }
        MultiplicationForDocument();
    }
    // function to multiply base amount and multiply
    function changeTotalAmount() {
        const $amountLabel = $($(this).data('amount-label'));
        const multiplier = parseInt($('.' + $(this).data('multiplier')).text())
        const currentAmount = parseInt($(this).val().toString().replaceAll('.', ''));
        $amountLabel.text(formatNumber(currentAmount * multiplier))
    }

    $('body').on('keyup', '.input-cost-deal-modal', changeTotalAmount)

    $('body').on('click', '#deleteall-button', function () {
        var respond = confirm('Estás seguro que quieres eliminar la ultima carga de documentos?');
        if (respond){
            $('.last-charge').fadeOut(500, function() {
                $(this).delay(1000).remove()
                sumDocumentsTotalAmount()
                MultiplicationForDebtor()
                MultiplicationForDocument()
                calculateCostChecked()
            })
        }
    })

    function editTrDocument(folio, date, rutReceptor, amount, debtor_name, expirationDate, bank, bank_place, account) {
        let $tr;
        if($('#deal_product').val() == 'check'){
            $tr = $('#tr-document-' + rutReceptor.replaceAll('.','').replace('-','') + '-' + folio);
        }else{
            $tr = $('#tr-document-'+ folio);
        }

        $tr.find('.lds-ring').removeClass('d-none');

        $tr.find('.issued-at-input').val(date);
        $tr.find('.expire-at-input').val(expirationDate);
        $tr.find('.rut-input').val(rutReceptor);
        $tr.find('.name-input').val(debtor_name);
        $tr.find('.total-amount-input').val(amount);
        $tr.find('.bank-input').val(bank);
        $tr.find('.place-input').val(bank_place);
        $tr.find('.account-number-input').val(account);

        $tr.find('.issued-at-td').html(date);
        $tr.find('.expire-at-td').html(expirationDate);
        $tr.find('.debtor-rut-td').html(rutReceptor);
        $tr.find('.debtor-name-td').html(debtor_name);
        $tr.find('.total-amount-td').html(`$${amount}`);
        $tr.find('.bank-td').html(bank);
        $tr.find('.place-td').html(bank_place);
        $tr.find('.account-number-td').html(account);

        sumDocumentsTotalAmount()
    }

    function replace_info_document(folio, date, rutReceptor, amount, companyId, IVA, debtor_name,
                                   mntNeto, nameEmisor, idDocument, expirationDate,transfer_status, bank, bank_place, account){
        var array = []
        for(var i = 0; i < $('.tr-document').length; i++){
            let element = $('.tr-document')[i];
            let numberFolio;
            if($('#deal_product').val() == 'check'){
                numberFolio = $(element).attr('id').replace('tr-document-'+rutReceptor.replaceAll('.','').replace('-','')+'-', '')
            }else{
                numberFolio = $(element).attr('id').replace('tr-document-', '')
            }
            array.push(numberFolio)
        }
        if (array.includes(folio.toString()) && folio.toString() !== '0'){
            swal({
                title: "El Nº de documento " +folio+ " ya fue agregado",
                text: "¿Desea reemplazar la información ya agregada?",
                buttons: ["Cancelar", "Aceptar"],
                dangerMode: false,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        editTrDocument(folio, date, rutReceptor, amount, debtor_name, expirationDate, bank, bank_place, account)
                        swal("Tu documento N°" +folio+" fué reemplazado.", {button: "Aceptar"});
                    }
                });
        }else{
            let html = generateInputDeal(folio, date, rutReceptor, amount, companyId, IVA, debtor_name,
                mntNeto, nameEmisor, idDocument, expirationDate,transfer_status,bank,bank_place, account, false, true)
            renderInputDeal(html)
        }
    }

    $('body').on("click", ".file-button", function (){
        let target = $(this).data('target');
        $(target).trigger("click");
    });

    $('body').on("change", "#operation-not-approved-check, #operation-approved-check", function (){
        if ($(this).attr('id') === "operation-not-approved-check" && $(this).prop('checked')){
            $('#operation-approved-check').prop("checked",false)
        }
        else if ($(this).attr('id') === "operation-approved-check" && $(this).prop('checked')){
            $('#operation-not-approved-check').prop("checked",false)
        }
        $('#operation-approval-form').find('.btn').removeClass("disabled");
    })
    // check if the deal is approved
    $('body').on('click', '.form-check-input-approved', function () {
        let checkbox = $(this).attr('id');
        if(checkbox == 'approved-check' || checkbox == 'partner_is_aval_yes' && $(this).is(':checked')){
            $('#not-approved-check, #partner_is_aval_no').prop('checked', false);
        }else{
            $('#approved-check, #partner_is_aval_yes').prop('checked', false);
        }
    })

    $('body').on('click', '#button-confirm', function () {
        $('.loader').fadeIn(100);
    });

    $('body').on('change', '#filter-client', function () {
        let client = $(this).val();
        let url = $(this).data('url');
        $.ajax({
            url: url,
            dataType: 'script',
            method: "GET",
            data: {
                debtor_company_id: client
            }
        });
    })

    $('body').on('keydown', '.search-documents-sii', function(e){
        if(e.which == 13){
            let element = $(this);
            searchDocuments(element)

        }
    })
    $('body').on('change', '.search-documents-sii', function(){
        let element = $(this);
        searchDocuments(element)

    })

    function searchDocuments(element){
        let query = element.val();
        $("#filter-client > option[value='#{val}']").attr('selected',true);
        $.ajax({
            url: element.data('url'),
            dataType: 'script',
            method: "GET",
            data: {
                query: query,
                document_sii:true
            }, success: presentNumberDocument()
        })
    }

    $('body').on('click', '#button-search-document-sii', function () {
        let element = $('.search-documents-sii');
        element.val('');
        $('#filter-client').val('');
        searchDocuments(element)

    })

    // sort for documents add to deal
    $('body').on('click', '.th-sort',function(){
        var table = $(this).parents('table').eq(0)
        var rows = table.find('tr:gt(0)').toArray().sort(comparer($(this).index()))
        this.asc = !this.asc
        if (!this.asc){rows = rows.reverse()}
        for (var i = 0; i < rows.length; i++){table.append(rows[i])}
    })

    function comparer(index) {
        return function(a, b) {
            var valA = getCellValue(a, index), valB = getCellValue(b, index)
            return $.isNumeric(valA) && $.isNumeric(valB) ? valA - valB : valA.toString().localeCompare(valB)
        }
    }

    function getCellValue(row, index){

        return $(row).children('td').eq(index).text().replace(/[$. ]/g,'')

    }

    // search for documents added in deal
    $("body").on('keyup', '.search-deal-documents-add-rut',function(){
        _this = this;
        $.each($("#tbody-deal-add-documents .td-search"), function() {
            var td = $(this);
            searchTd(td, _this)
        });
    });
    $("body").on('keyup', '.search-deal-documents-add',function(){
        _this = this;
        $.each($("#tbody-deal-add-documents .td-search-number"), function() {
            var td = $(this);
            searchTd(td, _this)
        });
    });

    function searchTd(td, _this){
        if($(td).text() != ''){
            if($(td).text().indexOf($(_this).val()) === -1){
                $(td).parent().hide();
            }else{
                $(td).parent().show();
            }
        }
    }

    $("body").on('click', "#button-search-document-add", function(){
        $('.search-deal-documents-add').val('');
        $('.search-deal-documents-add-rut').val('');
        $('.td-search-number').parent().show();
        $('.td-search').parent().show();
    })


    try{
        let title_deals = document.querySelectorAll(".title-deal-collapse")
        let selections = document.querySelectorAll(".selection")
        let bank_select = selections[1].children[0]
        let place_select = selections[2].children[0]

        bank_select.setAttribute("id", "select-bank-place")
        place_select.setAttribute("id", "select-bank-place")
    }
    catch (e) {}

    $('.deal-approval-status').hover(function(){
        $(this).trigger('click');
    });

    function presentNumberDocument(){
        var inputs = $('.number-document');
        for(var i = 0; i < inputs.length; i++){
            if($(inputs[i]).val() != '0'){
                $('#check-'+$(inputs[i]).val()).prop('checked', true);
            }
        }
    }

    $('body').on('keyup', '.email-transfer-documents', function(){
        let button = $(this).data('button');
        const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if ($(this).val().length > 0){
            $(button).removeClass('disabled')
        }else{
            $(button).addClass('disabled')
        }
    })

    // send email to debtor_company of archive in deals
    $('body').on('click', '.button-send-email', function(){
        let id, emails, url, index, company_id, deal_id;
        id = $(this).data('email');
        emails = $(id).val();
        url = $(this).data('url');
        index = $(this).data('index');
        company_id = $(this).data('id');
        deal_id = $(this).data('deal');
        $('.loader').fadeIn(100);
        $.ajax({
            url:url,
            dataType: 'script',
            method:'GET',
            data:{
                emails:emails,
                index:index,
                company_id:company_id,
                deal_id:deal_id
            }
        })
    })

    $('body').on('click', '.resolve-button', function(){
        let document, button, rejected;
        button = $(this)
        document = button.parent().data('document')
        rejected = $('.rejected-button-' + document)
        rejected.removeClass('d-none').attr('data-document', document)
        $('#email-of-debtor-' + document).addClass('d-none')
        button.removeClass('btn-link resolve-button')
            .addClass('btn-primary confirm-transfer-button')
            .html('Confirmar')
            .attr('data-document', document)
        setTimeout(function(){
            button.attr('data-toggle', 'modal')
            button.attr('data-target', '#modal-confirm-document')
            rejected.attr('data-toggle', 'modal')
            rejected.attr('data-target', '#modal-confirm-document')
        }, 100)
    })

    $('body').on('click', '.confirm-transfer-button', function(){
        var document, confirm;
        document = $(this).data('document')
        confirm = $(this).data('confirm')
        if (confirm) {
            $('#select_reason_confirm').removeClass('d-none')
            $('#confirm_button').removeClass('btn-danger').addClass('btn-primary').html('Confirmar')
        }else{
            $('#select_reason_confirm').addClass('d-none')
            $('#confirm_button').removeClass('btn-primary').addClass('btn-danger').html('No Confirmar')
        }
        $('#confirmed_reason').val('')
        $('#confirm_button').attr('data-document', document)
        $('#confirm_button').attr('data-transfer_confirmed', confirm)
        $('#message-confirm-document').html('')
    })

    $('body').on('click', '#confirm_button', function(e){
        let url, document, button, reason, type_reason, transfer_confirmed;
        e.preventDefault();
        button = $(this)
        transfer_confirmed = button.data('transfer_confirmed')
        url = "/confirm_transfer_document"
        reason = $('#confirmed_reason').val().toString()
        type_reason = $('#select_reason_confirm').val()
        document = button.data('document')
        $('.loader').fadeIn(100);
        $.ajax({
            url:url,
            dataType: 'script',
            method: "PUT",
            data:{
                id:document,
                transfer_confirmed:transfer_confirmed,
                confirmed_reason:type_reason,
                reason:reason
            }
        })

    })
    // Sidebar on class change event
    var sidebar = $("#sidebar");
    var observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            var attributeValue = $(mutation.target).prop(mutation.attributeName);

            $.ajax({
                method: "POST",
                dataType: "script",
                url: $('#toggle-menu').data('url'),
                data: { collapse_status: attributeValue}
            });
        });
    });
    if (sidebar.html() != undefined){
        observer.observe(sidebar[0], {
            attributes: true,
            attributeFilter: ['class']
        });
    }

    $('body').on('change','#resolution-deal > div > input[id*="approval-invoice-client"],#resolution-deal > div > input[id*="refuse-invoice-client"]',function () {
        if ($(this).attr("id") === "approval-invoice-client" && $(this).prop("checked")){
            $('#refuse-invoice-client').prop("checked",false)
        }
        else if ($(this).attr('id') === "refuse-invoice-client" && $(this).prop("checked")){
            $('#approval-invoice-client').prop("checked",false)
        }
    });

    $('body').on('click', '#button-send-confirm-email-debtor', function(){
        $(".loader").fadeIn('slow');
    })

    $('body').on('click', '.loading-icon', function(e){
        $(e.target).replaceWith('<div class="small-ring lds-ring ring-primary"><div></div><div></div><div></div><div></div></div>')
        $('button').blur();
    });


    $('body').on('click', '.collapse-deal, .collapse-link', function () {
        var target = $(this).data('target')
        $('.icon-accordion-right').removeClass('down')
        if ($(target).hasClass('show')){
            $(this).find('.icon-accordion-right').removeClass('down')
        }else{
            $(this).find('.icon-accordion-right').addClass('down')
        }
    });

    $('body').on('change', '.change-input, .form-check-input-approved', function () {
        let upfront = $('#upfront_percentage_full').val()
        let financed_rate = $('#financing_rate').val()

        if(upfront != '' && financed_rate != ''){
            $('.loader').fadeIn()
            $('#submit-documents-true').click();
            $('#inputs-simulation-submit').click();
        }
    })


    $('body').on('click', ".save-deal-button", function () {
        $('.loader').fadeIn(100)
        $('#submit-documents-true').click();
    })

    $('body').on('click', '.button-accept-cost-deal', function () {
        let upfront = $('#upfront_percentage_full').val()
        let financed_rate = $('#financing_rate').val()

        if(upfront != '' && financed_rate != ''){
            $('#submit-documents-true').click();
            $('#inputs-simulation-submit').click();
        }
    })

    $('body').on('input', '#deal_applied_amount', function(){
        if ($(this).val()) {
            $('#content_deal_description_applied_amount').removeClass('d-none')
        } else {
            $('#content_deal_description_applied_amount').addClass('d-none')
        }
    })

    $('body').on('click', '[data-toggle="close-modal"]', function(){
        let modal = $(this).data('modal')
        $(modal).modal('hide')
    })


    $('body').on('click', '.accept-document', function (e) {
        sumTrs()
        const check = $(e.target).data('check');
        let documentNumber = $(e.target).data('folio');
        let receptorRut = $(e.target).data('debtorRut');
        if(check){
            $modal = $('#document-form-modal-' + receptorRut.replaceAll('.','').replace('-','') + '-' + documentNumber);
        }else{
            $modal = $('#document-form-modal-'+ documentNumber);
        }

        console.log("Accepted document")

        let issuedAt = $modal.find('.issued-at-input').val();
        let expireAt = $modal.find('.expire-at-input').val();
        let debtorRut = $modal.find('rut-input').val();
        let debtorName = $modal.find('name-input').val();
        let totalAmount = $modal.find('.total-amount-input').val()
        let bank = $modal.find('.bank-input').val()
        let accountNumber = $modal.find('.account-number-input').val()
        let place = $modal.find('.place-input').val()
        let product_type = $('#deal_product option:selected').val()
        if(product_type == 'check'){
            $tr = $('#tr-document-'+debtorRut.replaceAll('.','').replace('-','')+'-'+ documentNumber)
        }else{
            $tr = $('#tr-document-'+ documentNumber)
        }

        $tr.find('.issued-at-td').html(issuedAt)
        $tr.find('.expire-at-td').html(expireAt)

        $tr.find('.debtor-rut-td').html(debtorRut)
        $tr.find('.debtor-name-td').html(debtorName)
        $tr.find('.total-amount-td').html(totalAmount)
        $tr.find('.bank-td').html(bank)
        $tr.find('.account-number-td').html(accountNumber)
        $tr.find('.place-td').html(place)

        if (product_type == "promissory_note"){ // promisory note doesnt had issuedAt
            $('#submit-documents-true').trigger('click')
            $('.modal').modal('hide')
        }
        else if(expireAt == undefined || expireAt == '' || ((issuedAt == undefined || issuedAt == '') && !check) ){
            console.log("alert if not date or expiration!")
            alertIfNotDate($modal.find('.expire-at-input'), $modal.find('.issued-at-input'))
        }
        else{
            $('#submit-documents-true').trigger('click')
            $('.modal').modal('hide')
        }

    })

    function alertIfNotDate(expireInput, issuedInput){
        let expireAt = $(expireInput).val()
        let issuedAt = $(issuedInput).val()
        if (expireAt == undefined || expireAt == null || expireAt == ''){
            $(expireInput).addClass('is-invalid')
            $('.error-expDate').removeClass('d-none').html('Debes agregar fecha de vencimiento')
        }
        if (issuedAt == undefined || issuedAt == null || issuedAt == ''){
            $(issuedInput).addClass('is-invalid')
            $('.error-issuedDate').removeClass('d-none').html('Debes agregar un fecha')
        }
    }

    $('body').on('keyup', '.input-emails-contact', function(){
        let button = $(this).closest("tr").find('.edit-email-contact')
        const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if ($(this).val().match(emailRegex)){
            $(button).removeClass('disabled')
        }else{
            $(button).addClass('disabled')
        }
    })

    $('body').on('click', '.edit-email-contact', function() {
        let target, input, id, url, deal, debtor, method, add
        target = $(this).parent().parent().parent().find('input');
        input = $(target).val();
        id = $(this).data('id');
        url = $(this).data('url');
        deal = $(this).data('deal');
        add = $(this).data('add');
        debtor = $(this).data('debtor');
        method = $(this).data('method');
        $('.loader').fadeIn(100)
        $.ajax({
            method:method,
            url:url,
            dataType:'script',
            data:{
                add:add,
                id:id,
                emails:input,
                deal_id:deal,
                company_id:debtor
            }
        })

    })

    $('body').on('click', '.delete-row', function() {
        $(this).closest('tr').fadeOut(300, function (){
            $(this).remove();
        });
    })
    $('body').on('click', '#confirm-all', function() {
        $('.form-check-label').click();
    })

    function getDealId(){
        let formUrlParts = $('#id-deal-new').attr("action").split('/')
        if(formUrlParts.length === 3){
            return formUrlParts[2]
        }
    }

    function update_bulletin_status(rut){
        //Solicitud del bulletin status
        setTimeout(function (){
            $.ajax({
                type: "POST",
                url: $('#open-modal-boletin-concursal').attr('data-bulletin-status-url'),
                data: {deal_id: $('#open-modal-boletin-concursal').attr('data-deal-id'),
                    rut: rut,
                    type: "client"},
                dataType: "script",
                success: function (){
                    console.info("Bulletin status success!");
                }
            });
        }, 5000)
    }

    $('body').on('click', '#open-modal-boletin-concursal', function(){
        $('#modal-boletin-concursal').modal('toggle');


        //bulletin job only it executes one when the bulletin modal button is clicked
        if (!bulletin_job_load){
            $.ajax({
                type: "POST",
                url: $('#open-modal-boletin-concursal').attr('data-bulletin-job-url'),
                data: {deal_id: $('#open-modal-boletin-concursal').attr('data-deal-id')},
                dataType: "script",
            });
            bulletin_job_load = true;
        }


        var documents = $('.bulletin-document-rut');

        /* if (!bulletin_status_client){
            // load client bulletin
            setTimeout(function (){
                $.ajax({
                    type: "POST",
                    url: $('#open-modal-boletin-concursal').attr('data-bulletin-status-url'),
                    data: {rut: $('.bulletin-client-rut').html(), deal_id: getDealId()},
                    dataType: "script",
                });
            }, 5000)
            bulletin_status_client = true;
        }

        if (!bulletin_status_documents){
            //Load Documents bulletin
            documents.each(function(index, doc){
                setTimeout(function (){
                    $.ajax({
                        type: "POST",
                        url: $('#open-modal-boletin-concursal').attr('data-bulletin-status-url'),
                        data: {rut: $(doc).html(), deal_id: getDealId()},
                        dataType: "script",
                    });
                }, 5000)
            });
            bulletin_status_documents = true;
        } */

    });

    // when there is a 'Si' in the bulletin modal, then we add a click listener to trigger a request to get the response body
    $('body').on('click', '.open-bulletin-detail', function(){
        $.ajax({
            type: "POST",
            url: $(this).data('url'),
            data: {rut: $(this).data('rut'), deal_id: getDealId()},
            dataType: "script",
        });
        $('#modal-boletin-concursal-detail').modal('toggle');
    });

    var instructions_headers = $(".instruction-header");
    var is_first = true;
    if (instructions_headers.length > 0) {
        instructions_headers.each(function(index,item){
            if (is_first && $(item).attr('data-completed') == "ONHOLD"){ // Solo seteamos la primera instancia como NEXT
                is_first = false
                //Header
                $(item).attr('data-completed', "NEXT");
                //Sub-Header
                $(item).next().attr('data-completed', 'NEXT');
                //Hiden buttons
                $(item)
                    .next()
                    .children(".instruction-start")
                    .removeClass("hide");
                $(item)
                    .next()
                    .children()
                    .children(".instruction-start")
                    .removeClass("hide");
            }
        });
    }

    $(".open-sii-modal").on('click', function(){
        $("#modal-sii-buttons").modal("show");
    });
    $(".sii-sync-button").on('click', function(){
        $("#modal-sii-buttons").modal("hide");
    });

    $('body').on('click', '.step-container', function() {
        $('#current_step').val($(this).data('step'))
    })

    $('body').on('click', '#button-save-deal-lost', function(){
        console.log('ejecutado')
        $("#submit-deal-lost").trigger('click');
    })


    // for styling shadown efect toggle on concentration of debtors (not LOADED)
    $("body").on("click", ".concentration-of-debtors", function () {
        let concentration_container = $(".concentration-of-debtors");
        console.log(`Concentrat: ${concentration_container}`);
        if (concentration_container.hasClass("shadown-active")) {
            concentration_container.removeClass("shadown-active");
            concentration_container.addClass("shadown-remove");
        } else if (concentration_container.hasClass("shadown-remove")) {
            concentration_container.removeClass("shadown-remove");
            concentration_container.addClass("shadown-active");
        } else {
            concentration_container.addClass("shadown-remove");
        }
    });

    $('body').on('click', '.page-link', function(){
        $('.loader').fadeIn(100)
    });

    $('body').on('click', '#button-save-deal-lost', function(){
        console.log('ejecutado')
        $("#submit-deal-lost").trigger('click');
    })

    $('body').on('click', '#button-accept-cost-deal', function () {
        let amount_total = $('.cost-deal-total-amount').html()
        $('#input-amount-in-simulation').val(amount_total).trigger("input").trigger('change');
    })

    $('body').on('click', '#input-amount-in-simulation', function () {
        $('.miles-cost').mask("000.000.000.000", {reverse: true});
    })

    $('body').on('click', '#operation-collapse', function(){
        if ($(this).data('confirmation-protocol-loaded') != "loaded"){
            $.ajax({
                type: "POST",
                url: "/read_confirmation_protocol",
                data: {deal_id: getDealId()},
                dataType: "script",
            });
        }

        $(this).data("confirmation-protocol-loaded", "loaded");
    });

    $('body').on('click', '.confirmation-protocol-td', function () {
        let loader = '<div class="text-center small-ring lds-ring ring-primary d-flex justify-content-center align-items-center"><div></div><div></div><div></div><div></div></div>'
        $('#show-detail-conf-protocol-tbody').find('.rut').html(loader);
        $('#show-detail-conf-protocol-tbody').find('.name').html(loader);
        $('#show-detail-conf-protocol-tbody').find('.obs').html(loader);

        $.ajax({
            type: "POST",
            url: $(this).data('url'),
            data: {deal_id: getDealId(), document_id: $(this).data('document-id')},
            dataType: "script",
        });
        $('#show-detail-confirmation-protocol').modal('show');
    });

    $('body').on('click', '.collapse-get', function(){
        let url = $(this).data('url');
        let target = $(this).data('get');
        let loader = $(target).find('.loader-box');

        if($(loader).length){
            $.ajax({
                url: url,
                method: 'GET',
                dataType: 'script'
            })
        }
    })

    $('body').on('click', '.close-sii-document-modal', function() {
        $(`#sii-document-checkbox-${ $(this).data('document-id') }`).prop('checked', false)
    })

    $('body').on('confirm:complete', '.sii-document-checkbox', function(event) {
        const isConfirmed = event.detail[0];
        if (isConfirmed) {
            $('.loader').fadeIn()
        } else {
            $(this).prop('checked', !$(this).is(':checked'))
        }
    })

    $('body').on('confirm:complete', '.delete-document-btn', function(event) {
        const isConfirmed = event.detail[0];
        if (isConfirmed) {
            $('.loader').fadeIn()
        }
    })
    
    $('body').on('change', '#deals-filter input , #deals-filter select', function(){
        $('#deals-filter').attr('last-input-change-id', $(this).attr('id'))
    });

    $('body').on('change', '#vehicle_type_select', function() {
        const value = $(this).val()
        if (value === 'Otro') {
            $('#resource_resourceable_vehicle_type').val('')
            $('#vehicle_type_input_container').removeClass('d-none')
        } else {
            $('#vehicle_type_input_container').addClass('d-none')
        }
    })

    $('body').on('click', '.add-legal-representative-deal', function(){
        let legal_representative_selector = $('#select_legal_representative')
        let url = $(legal_representative_selector).data('url');
        let deal_id = $(legal_representative_selector).data('deal');
        let legal_representative_id = $(legal_representative_selector).val()
        $.ajax({
            url: url,
            method: 'post',
            dataType: 'script',
            data: {
                legal_representative_id: legal_representative_id,
                deal_id: deal_id
            }
        })
    })

    $('body').on('click', '.add-partner-deal', function(){
        let partner_selector = $($(this).data('target'))
        let url = $(partner_selector).data('url');
        let deal_id = $(partner_selector).data('deal');
        let partner_id = $(partner_selector).val()
        $.ajax({
            url: url,
            method: 'post',
            dataType: 'script',
            data: {
                partner_id: partner_id,
                deal_id: deal_id
            }
        })
    })


    $("body").on("click", "#deal-in-progress-tab", function () {
        console.log("clicked deals");
        $("#client-th").text("Cliente");
        $("#client-status-th").text("Estado Cliente");

        $("#archived").val(false);
        submitDealsFilter();
      });

      $("body").on("click", "#history-tab", function () {
        console.log("clicked archived deals");
        $("#client-th").text("Deudor");
        $("#client-status-th").text("Estado Deudor");

        $("#archived").val(true);
        submitDealsFilter();
      });

      const submitDealsFilter = () => {
        $("input#page").val("1");
        $("#deals-filter").find(":submit").trigger("click");
      };


    $('body').on('click', '.show-modal-message', function(){
        let message = $(this).data('message');
        $modals.info({ message: message });
    })

    $('body').on('click', '.cost-deal-multiplier-button', function() {
        const $tableRow = $(this).parents('.cost-deal-row').first()
        const $multiplierInput = $tableRow.find('.cost-deal-multiplier-input')
        const currentuValue = Number($multiplierInput.val())
        let newValue = currentuValue || 1
        const isSubtraction = $(this).data('subtraction') && true
        const isAddition = $(this).data('addition') && true

        if (isSubtraction) {
            newValue -= 1
        }

        if (isAddition) {
            newValue += 1
        }

        if (newValue <= 0) {
            newValue = 1
        }

        $multiplierInput.val(newValue).trigger('change')
    })

    $('body').on('click', '.cost-deal-edit-button', function() {
        const $tableRow = $(this).parents('.cost-deal-row').first()
        $('#cost-deal-form').find(':submit').attr('disabled', true)
        $(this).addClass('d-none')
        $tableRow.find('.cost-deal-save-button').removeClass('d-none')
        $tableRow.find('.cost-deal-input').removeClass('disabled')
        const $btnGroup = $(this).parents('.cost-deal-button-group').first()
        $('.cost-deal-button-group').filter((_index, btnGroup) => btnGroup != $btnGroup[0]).addClass('d-none')
    })

    $('body').on('click', '.cost-deal-save-button', function() {
        const $tableRow = $(this).parents('.cost-deal-row').first()
        $('#cost-deal-form').find(':submit').removeAttr('disabled', true)
        $(this).addClass('d-none')
        $tableRow.find('.cost-deal-edit-button').removeClass('d-none')
        $tableRow.find('.cost-deal-input').addClass('disabled')
        $('.cost-deal-button-group').removeClass('d-none')
        costDealCalculateRow($tableRow)
        costDealCalculateModal()
    })

    $('body').on('change', '.cost-deal-select-input', function() {
        costDealCalculateModal()
    })

    $('body').on('change', '.cost-deal-currency-type-input', function() {
        const value = $(this).val()
        const $tableRow = $(this).parents('.cost-deal-row').first()
        const $currencyAmountInput = $tableRow.find('.cost-deal-currency-amount-input')
        const $maskInput = $currencyAmountInput.siblings('.mask-input')

        if (value !== 'clp') return $maskInput.attr('mask-decimals', true)

        const currencyValue = Number($currencyAmountInput.val())
        let newValue = Math.trunc(currencyValue)
        $maskInput.removeAttr('mask-decimals').val(newValue).trigger('input')
    })
}


function costDealCalculateRow($tableRow) {
    const $totalElement = $tableRow.find('.cost-deal-row-total')
    const $currencyAmountInput = $tableRow.find('.cost-deal-currency-amount-input')
    const $currencyTypeInput = $tableRow.find('.cost-deal-currency-type-input')
    const $multiplierInput = $tableRow.find('.cost-deal-multiplier-input')
    const currencyType = $currencyTypeInput.val()
    const currencyValueText = currencyType === 'clp' ? 1 : $(`#currency-${currencyType}-value`).text()
    const currencyValue = Number(currencyValueText.toString().replaceAll('$', '').replaceAll('.', '').replaceAll(',', '.'))
    const currencyAmount = Number($currencyAmountInput.val())
    const multiplier = Number($multiplierInput.val())

    if (isNaN(currencyValue) || isNaN(multiplier) || isNaN(currencyAmount)) return

    const totalValue = (currencyAmount * currencyValue) * multiplier
    $totalElement.text(formatNumber(Math.round(totalValue)))
}

function costDealCalculateModal() {
    const $tableRows = $('.cost-deal-row:has(.form-check-input:checked)')
    const $totalizers = $tableRows.find('.cost-deal-row-total')
    const arrayValues = $totalizers.map((_, element) => Number($(element).text().replaceAll('.', '').replaceAll(',', '.')) || 0).get()
    const total = arrayValues.reduce((current, acum) => acum + current, 0)
    $('#cost-deal-total-amount').text(formatNumber(Math.round(total)))
}

window.formatMaskNumbers = formatMaskNumbers;
