$( document ).on('turbolinks:load', events)

function events(){
  $('body').on('click', '.show-password', function() {
    const targetId = $(this).data('target')
    const type = $(targetId).attr('type') === 'password' ? 'text' : 'password';
    $(targetId).attr('type', type)
    $(this).find('#icon-eye-blue').toggleClass('d-none')
    $(this).find('#icon-eye-blue-hide').toggleClass('d-none')
  })
  
  $('body').on('form-create-user-submit-button', () => {
    
  })
}