const formatNumber = require('../utils/format_number');
const formatDate = require('../utils/format_date');
$( document ).on('turbolinks:load', events)

const createNewClientDealFormGroup = (documentId, debtorCompanyId, totalAmount) => {
  const index = $('.client-new-deal-form-group').length + 1
  const elementString = `<div class="client-new-deal-form-group" document-id="${ documentId }">
    <input
      class="deals-document-document-id-input"
      type="hidden"
      name="deal[deals_documents_attributes][${ index }][document_id]"
      id="deal_deals_documents_attributes_${ index }_document_id"
      value="${ documentId }"
      data-debtor-company-id="${ debtorCompanyId }"
      data-total-amount="${ totalAmount }"
    >
  </div>`

  return $.parseHTML(elementString)
}

const updateOperationResume = () => {
  const $formGroups = $('#deals-documents-form-groups-inputs').find('.client-new-deal-form-group')
  const newDocuments = $formGroups.filter(':not(:has(.deals-document-destroy-input))')
  const documentsToPerserve = $formGroups.filter(':has(.deals-document-destroy-input[value="false"])')
  const documentsCount = newDocuments.length + documentsToPerserve.length
  let totalAmount = 0;
  const debtorIds = []

  $.merge(newDocuments, documentsToPerserve).find('.deals-document-document-id-input').each((_index, input) => {
    const $input = $(input)
    const debtorId = $input.data('debtor-company-id');
    totalAmount += Number($input.data('total-amount'))
    if (!debtorIds.includes(debtorId)) debtorIds.push(debtorId)
  })

  $('#debtors_count').text(debtorIds.length)
  $('#documents_count').text(documentsCount)
  $('#documents_amount').text(formatNumber(totalAmount))
  $('.save-form').attr('disabled', !documentsCount)
}

const getSiiDocumentsFromApi = (page) => {
  $('#sii-documents-table-body').attr('loading', 'true')
  const dealId = $('#deal_id').val()
  const query = $('#filter-sii-documents-input').val()
  const order = $('#order').val()
  const direction = $('#direction').val()
  let url = `/clients/deals/sii_documents?page=${ page }`
  if (query.length) url += `&query=${ query }`
  if (dealId.length) url += `&deal_id=${ dealId }`
  if (order.length) url += `&order=${ order }`
  if (direction.length) url += `&direction=${ direction }`

  $.ajax({
    type: 'GET',
    dataType: "script",
    url,
  })
}

function events(){
  $('body').on('change', '.checkbox-select-deal', function() {
    const isChecked = $(this).is(":checked")
    const dealsDocumentId = $(this).data('deals-document-id')
    const documentId = $(this).data('document-id')
    const totalAmount = $(this).data('total-amount')
    const debtorCompanyId = $(this).data('debtor-company-id')
    const $formGroup = $(`.client-new-deal-form-group[document-id="${ documentId }"]`)

    if (dealsDocumentId) {
      $formGroup.find('.deals-document-destroy-input').val(!isChecked)
    } else if (isChecked) {
      const element = createNewClientDealFormGroup(documentId, debtorCompanyId, totalAmount)
      $('#deals-documents-form-groups-inputs').append(element)
    } else {
      $(`.client-new-deal-form-group[document-id="${ documentId }"]`).remove()
    }

    $(`#document-info-${documentId}`)
      .find('.toggle-document-mobile-button')
      .toggleClass('btn-primary')
      .toggleClass('btn-danger')
      .text(isChecked ? 'Eliminar': 'Agregar')

    updateOperationResume()
  })

  $('body').on('click', '#deal-return-button', function() {
    $('#new-deal-steps').attr('current-step', '1')
    updateOperationResume()
  })

  $('body').on('click', '.delete-deal-document', function() {
    let totalAmount = 0;
    const debtorIds = []
    const currentValue = $(this).siblings('input').val() === 'true'
    const $tableRow = $(this).parents('tr')

    $(this).siblings('input').val(!currentValue)
    $(this).find('.icon-restore').toggleClass('d-none')
    $(this).find('.icon').toggleClass('d-none')

    if (currentValue) {
      $tableRow.removeAttr('disabled')
      $tableRow.find('.select-date-modal-toggle').removeAttr('disabled')
    } else {
      $tableRow.attr('disabled', 'disabled')
      $tableRow.find('.select-date-modal-toggle').attr('disabled', 'disabled')
    }

    const rowsToPerserve = $('#client-select-date-deal-form').find('.documents-select-date-table').find('tr').filter(':has(.delete-deal-document-input[value="false"])')
    const documentsCount = rowsToPerserve.length

    rowsToPerserve.each((index, tr) => {
      const $tr = $(tr)
      const debtorId = $tr.data('debtor-company-id');
      totalAmount += Number($tr.data('total-amount'))
      if (!debtorIds.includes(debtorId)) debtorIds.push(debtorId)
    })

    $('#debtors_count').text(debtorIds.length)
    $('#documents_count').text(documentsCount)
    $('#documents_amount').text(formatNumber(totalAmount))
    $('.save-form').attr('disabled', !documentsCount)
  })

  $('body').on('click', '.select-date-delete-deal-document', function() {
    const documentId = $(this).data('document-id')
    const isDelete = $(this).attr('class').includes('btn-danger')
    $(`#select-date-document-row-${ documentId }`).find('.delete-deal-document').trigger('click')
    $(this).toggleClass('btn-danger btn-primary').text(isDelete ? 'Agregar' : 'Eliminar')
  })

  $('body').on('change', '.uniq-checkbox-group', function(e) {
    if ($(e.target).is('input:text')) return
    const newDate = new Date($(e.target).val())
    const checkboxes = $(this).find('input[type="checkbox"]').filter((_index, checkbox) => checkbox != e.target)
    checkboxes.prop('checked', false)
  })

  $('body').on('change', '.change-global-expire-at', function() {
    const newDate = new Date($(this).val())
    $('.document-expire-at-input').val($(this).val())
    $('.document-expire-at-label').text(formatDate(newDate)).removeClass('text-danger')
    $('.document-expire-at-subtitle').text(`${ $(this).data('days') } días`).removeClass('text-danger')
  })

  $('body').on('hide.bs.modal', '#document-select-date-modal', function() {
    $('#datepicker-hidden-field').val('')
    $('#selected-date-table').find('th').text('')
    $('.change-uniq-expire-at').prop('checked', false)
  })

  $('body').on('click', '.select-date-modal-toggle', function() {
    const date = $(this).siblings('input[type="hidden"]').val()
    $('#datepicker-hidden-field').val(formatDate(date))
    $('#selected-date-table').find('th').text(formatDate(date))
    $('#document-select-date-modal').find('#save-date-button').data('document-id', $(this).data('document-id'))
  })

  $('body').on('change', '.change-uniq-expire-at', function() {
    const date = new Date($(this).val())
    $('#datepicker-hidden-field').val(formatDate(date))
    $('#selected-date-table').find('th').text(formatDate(date))
  })

  $('body').on('click', '#save-date-button', function() {
    const $tableRow = $(`#select-date-document-row-${ $(this).data('document-id') }`)
    const $dateInput = $('#datepicker-hidden-field')
    const $dateCell = $tableRow.find('.date-cell')

    if ($dateInput.val()) {
      const [day, month, year] = $dateInput.val().split('-')
      const newDate = new Date(`${ month }-${ day }-${ year }`)
      const diffTime = Math.abs(new Date() - newDate);
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); 
      $dateCell.find('.document-expire-at-input').val(newDate.toISOString())
      $dateCell.find('.document-expire-at-label').text(formatDate(newDate))
      $dateCell.find('.document-expire-at-subtitle').text(`${ diffDays } días`)
      if (diffDays <= 0) {
        $dateCell.find('.document-expire-at-label').addClass('text-danger')
        $dateCell.find('.document-expire-at-subtitle').addClass('text-danger')
      } else {
        $dateCell.find('.document-expire-at-label').removeClass('text-danger')
        $dateCell.find('.document-expire-at-subtitle').removeClass('text-danger')
      }
    }

    $('#document-select-date-modal').modal('hide')
  })

  $('body').on('click', '#paginate-row', function() {
    const paddingTop = Number($(this).css('padding-top').replace('px', ''))
    const paddingBottom = Number($(this).css('padding-bottom').replace('px', ''))
    const currentScroll = $(this).get(0).scrollHeight - $(this).scrollTop() - paddingTop - paddingBottom

    if (currentScroll === $(this).height()) {
      const newPage = Number($(this).data('current-page')) + 1
      getSiiDocumentsFromApi(newPage)
    }
  })

  $('body').on('change', '#filter-sii-documents-input', function() {
    $('#order').val('')
    $('#direction').val('')
    getSiiDocumentsFromApi(1)
  })

  $('body').on('click', '#sii-documents-card .th-sort', function() {
    const order = $(this).data('order')
    let direction = ''

    if ($('#order').val() == order) {
      direction = $('#direction').val() == 'desc' ? 'asc' : 'desc'
    } else {
      direction = 'desc'
    }

    $('#order').val(order)
    $('#direction').val(direction)
    getSiiDocumentsFromApi(1)
  })

  $('body').on('click', '#new-deal-steps .step', function() {
    const currentStep = $('#new-deal-steps').attr('current-step')
    const step = $(this).attr('step')
    if (currentStep === step) return

    if (step === '1') {
      $('#deal-return-button').trigger('click')
    }

    if (step === '2' && !$('#next-step-button').is(':disabled')) {
      $('#next-step-button').trigger('click')
    }
  })

  $('body').on('click', '#next-step-button', function() {
    $('#sii-documents-table-body').attr('loading', 'true')
    $(this).prop('disabled', 'true')
  })

  $('body').on('click', '.change-global-expire-at + label', function(e) {
    const isChecked = $(this).siblings('input[type="checkbox"]').is(':checked')
    if (isChecked) e.preventDefault()
  })

  $('body').on('change', '#uniq-expire-at-input', function(e) {
    const value = $(this).val()
    $('#datepicker-hidden-field').val(value)
    $('#selected-date-table').find('th').text(value)
    $(this).val('')
  });

  $('body').on('click', '#simulate-button', function() {
    $('#select-date-table-container').find('tbody').attr('loading', 'true')
    $(this).prop('disabled', 'true')
    $('#deal-return-button').prop('disabled', 'true')
  })

  $('body').on('click', '.toggle-table-row-collapse', function() {
    const targetId = $(this).data('target');
    $(targetId).toggleClass('active')
    $(this).toggleClass('active')
  })

  $('body').on('click', '.document-row', function(e) {
    const isMobile = $('#toggle-sidebar-mobile').css('display') === 'block';
    const $target = $(e.target)
    const isCheckbox = $target.attr('class') && $target.attr('class').includes('checkbox-select-deal')
    const isChecked = $(this).find('.checkbox-select-deal').is(':checked')
    const isToggleInfo = $(e.target).attr('class')?.includes('toggle-table-row-collapse')

    if (isMobile && !(isCheckbox || isChecked || isToggleInfo)) {
      $(this).find('.checkbox-select-deal').trigger('click')
    }
  })

  $('body').on('click', '.toggle-document-mobile-button', function() {
    const documentId = $(this).data('document-id')
    const $checkBox = $(`.checkbox-select-deal[data-document-id="${ documentId }"]`)
    const isChecked = $checkBox.is(':checked')

    $checkBox.trigger('click')
  })

  $('body').on('click', '#toggle-operation-resume', function() {
    $(this).parents('#operation-resume').find('table').toggleClass('open')
  })

  $('body').on('change', '.change-deal-status-filter', function() {
    const newStatus = $(this).val()
    $('#deals-filters #status').val(newStatus).trigger('change')
  })

  $('body').on('click', '.sidebar-group-items', function() {
    $('#client-sidebar').attr('close', false)
  })

  $('body').on('submit', '.deal-chat-form', function() {
    $(this).attr('loading', 'true')
  })

  $('body').on('input', '.deal-chat-form input', function() {
    const $submit = $(this).siblings(':submit')

    if ($(this).val().length > 0) {
      $submit.removeAttr('disabled')
    } else {
      $submit.attr('disabled', 'disabled')
    }
  })

  $('body').on('ready', '#select-client-deal', function(){
    $('#select-client-deal').select2({
      ajax: {
        url: '/deals',
        dataType: 'json',
        method: 'GET',
        cache: false,
        delay: 400,
        data: function(params){
          var name = {name: params.term}
          return name
        },
        processResults: function (response) {
          if(response != 'null'){
            return {
              results: response
            };
          }
        }
      },
      language: 'es',
      dropdownParent: $(this)
    });
  });
}