import Chart from 'chart.js/auto';
const formatNumber = require('./utils/format_number');
const moment = require("moment/moment.js");
import { breakpointUp, breakpointDown } from './utils/breakpoints';


$( document ).on('turbolinks:load', common_events)

function common_events(){
    $('#sii-modal-not-valid').modal('show');

    $('#show, #hide').on('click', function () {
        $('#element').slideToggle('slow');
        $('#rotation_180deg').toggleClass("down");
        $('#collapseTwoChevron').toggleClass("down");
        $('#collapseOneChevron').toggleClass("down");
    });

    $('body').on('click', '.hide-link', function () {
        $(this).parent().parent().slideToggle('slow');
    });

    if ($('#module-resumen').length > 0){
        $.ajax({
            method: 'GET',
            url:'/resumen', 
            dataType: 'script',
        })
    }

    if ($('#module-resumen-executive').length > 0){
        $.ajax({
            method: 'GET',
            url:'/resumen_executive', 
            dataType: 'script',
        })
    }

    if ($('#content-clients-inactive').length > 0){
        $.ajax({
            method: 'GET',
            url:'/clients_inactive', 
            dataType: 'script',
        })
    }

    $('body').on('change', '#select_executive_resumen, #select_executive_clients_inactive', function(e){
        e.preventDefault();
        $('.loader').fadeIn(100)
        let url = $(this).data('url');
        $.ajax({
            type: 'GET',
            url: url,
            dataType: 'script',
            data:{
                sales_executive_id:$(this).val()
            }
        })
    })

    $('.charge-loader').on('click', function () {
        $('.loader').fadeIn(100)
    })

    $('body').on('click', '.chart-link', function (e) {
        let url = $(this).data('url');
        let company = $(this).data('company')
        let range = $(this).data('range');
        let chart = $(this).data('chart');
        $('.loader').fadeIn(100)
        $.ajax({
            type: "GET",
            url: url,
            dataType: "script",
            data:{
                chart:chart,
                company_id:company,
                range: range,
            },
            success: function () {
                $('.loader').fadeOut(500);
            },
        });
    $('.loader').fadeOut(500);
    });

    $('body').on('change', '#select_sales_executive_resumen, #select_company_resumen', function (e) {
        e.preventDefault();
        var target, value, company_id, sales_executive_id;
        target = $(this);
        value = target.val();
        if (target.data('typeselect') == 'company'){
            company_id = value
        }else{
            sales_executive_id = value
        }
        $('.loader').fadeIn(100)
        $.ajax({
            type: 'GET',
            url:"/resumen",
            dataType: "script",
            data:{
                document_type:target.data('documenttype'),
                sales_executive_id:sales_executive_id,
                year:target.data('year'), 
                company_id:company_id,
                client:target.data('client')
            }
        });
    })

    $('body').on('change', '#chart-resumen-year', function () {        
        $('.loader').fadeIn(100)
        $.ajax({
            type:'GET',
            url:"/resumen",
            dataType: "script",
            data:{
                sales_executive_id:$(this).data('salesexecutive'),
                company_id:$(this).data('company'),
                document_type: $(this).data('documenttype'),
                client:$(this).data('client'),
                year:$(this).val()
            }
        })
    })

    $('body').on('click', '.button-chart-resumen', function (e) {
        e.preventDefault();
        let url, documentType, sales_executive, year, company_id
        url = "/resumen"
        documentType = $(this).data('documenttype')
        sales_executive = $(this).data('salesexecutive')
        company_id = $(this).data('company')
        year = $(this).data('year')
        $('.loader').fadeIn(100)
        $.ajax({
            type: 'GET',
            url: url,
            dataType: 'script',
            data:{
                sales_executive_id:sales_executive,
                company_id:company_id,
                action_view:$(this).data('actionview'),
                document_type: documentType,
                year:year, 
                client:$(this).data('client')
            }
        })
    })

    $('.bar').hover(function(){
        $(this).trigger('click');
    });

    $('body').on('click', '#button-collapse-ten', function(){
        $.ajax({
            type: 'GET',
            url:'/portfolios_charge',
            dataType: 'script',
            data:{
                name_file:$(this).data('namefile')
            }
        })
    })

    $('body').on('click', '.copy', function(){
        let element = $(this)
        let text = $($(this).data('target')).html();
        setClipboard(text, element)
    })

    function setClipboard(text, element) {
        const type = "text/plain";
        const blob = new Blob([text], { type });
        const data = [new ClipboardItem({ [type]: blob })];
        let message = $(element).parent().find('.copy-content')
        navigator.clipboard.write(data).then(
            () => {
                $(message).fadeIn('slow')
                setTimeout(() => {
                    $(message).fadeOut('slow')
                }, 2000)
            },
            () => {
                console.log('no copiado...');
                /* failure */
            }
        );
    }

    $('body').on('click', '.see-last-deal-info', function () {
        console.log(`${$(this).data('deal-type')}`)
        $('.last-deal-type').html($(this).data('deal-type'));
        $('.last-deal-upfront-percentage').html($(this).data('difference-of-price'));
        $('.last-deal-doc-counter').html($(this).data('doc-counter'));
        $('.last-deal-amount').html($(this).data('amount'));
        $('.last-deal-upfront-percentage').html($(this).data('upfront-percentage'));
        $('#show-last-deal-info').modal('toggle');
    });

    function copyToClipboard(element) {
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val($(element).text()).select();
        document.execCommand("copy");
        $temp.remove();
    }

    function cli_graph_tabs(tab, tab_active) {

        if (tab_active) {

            $(`.${tab}` + "-hide").hide('slow'); // hide cards
            $(`.${tab}` + "-show").show('slow'); // show cards

            switch (tab) {   //margin added to top

                case "home-tab":
                    $("#income-graph-card").removeClass('mt-40').addClass("mt-1");
                    $("#discharge-graph-card").removeClass('mt-40').addClass("mt-1");
                    break;

                case "income-tab":
                    $("#discharge-graph-card").removeClass('mt-40').addClass("mt-1");
                    $('#margin-graph-card').addClass("mt-1").removeClass("mt-40");
                    break;
                case "expenses-tab":
                    $("#discharge-graph-card").addClass('mt-40').removeClass("mt-1");
                    $('#margin-graph-card').addClass("mt-1").removeClass("mt-40");
                    break;
                case "margin-tab":
                    $("#discharge-graph-card").removeClass('mt-40').addClass("mt-1");
                    $('#margin-graph-card').addClass("mt-40").removeClass("mt-1");
                    break;

                case "cession-tab":
                    $("#factoring-por-cesionario").addClass("mt-40").removeClass("mt-1");
                    $("#discharge-graph-card").removeClass('mt-40').addClass("mt-1");
                    break;
                case "debtor-tab":
                    $("#top-10-debtors-graph-card").addClass("mt-40").removeClass("mt-1");
                    $('#margin-graph-card').addClass("mt-40").removeClass("mt-1");
                    break;
            }
        }
    }

    // Dashboard cli tabs on tab clicked
    $('body').on('click', '.tab', function () {
        var tabs = $('.tab');
        var clicked_item = this

        if (!$(this).hasClass("tab-active")) {
            $(this).toggleClass('tab-active'); // change to tab-active when is not active when clicked!

            cli_graph_tabs($(this).data("tab"), $(this).hasClass('tab-active'));

        }

        tabs.each(function (index, item) {
            if (clicked_item.classList != item.classList) {
                $(item).removeClass("tab-active");
            }
        });
    })

    function formatValueChart(value) {
        if (value >= 1000000000) {
            return (value / 1000000000).toFixed(0) + ' bill';
        } else if (value >= 1000000) {
            return (value / 1000000).toFixed(0) + ' mill';
        } else if (value >= 1000) {
            return (value / 1000).toFixed(0) + ' mil';
        }
        return value;
    }

    function donutClientDocuments(data) {
        const ctx = document.getElementById('data-documents');
        if (data){

            var labels = data.map(function(item) {
                return item[0];
            });
    
            var amounts = data.map(function(item) {
                return item[1];
            });
    
            new Chart(ctx.getContext('2d'), {
                type: 'doughnut',
                data: {
                    labels: false,
                    datasets: [{
                        data: amounts,
                        backgroundColor: ["#2196F3", "#FF7A6D", "#2FBC6B", "#FF9D42"],
                    }]
                },
                options: {
                    responsive: true,
                    cutout: "50%",
                    animation: {
                        easing: 'easeOutQuart'
                    },
                    hoverOffset: 5,
                    borderRadius: 14,
                    borderWidth: 10,
                    borderJoinStyle: 'round',
                    elements: {
                        arc: {
                            backgroundColor: 'rgba(0,0,0,0)',
                        }
                    },
                    plugins: {
                        tooltip: {
                            enabled: false
                        }
                    },
                    onClick: function(event, elements) {
                        if (elements.length < 1) {
                            $("#info").fadeOut('fast')
                            $(".popover").remove();
                        }
                    },
                    onHover: function(event, elements) {
                        if (elements.length > 0) {
                            var region = elements[0];
                            var dataIndex = region.index;
                            var category = data[dataIndex][0];
                            var totalAmount = data[dataIndex][2];
                            var status_index = data[dataIndex][3];
                            var first_day = moment().startOf('month').format("DD-MM-YYYY");
                            var last_day = moment().endOf('month').format("DD-MM-YYYY");
                            if (!$('.popover').hasClass('popover-'+status_index) || $('.popover').length < 1 && breakpointUp('sm')){
                                $('.popover').remove()
                                var popover = document.createElement('div');
                                popover.classList.add('popover-tool');
                                popover.classList.add('popover');
                                popover.classList.add('popover-'+status_index);
                                popover.innerHTML = `<div class="custom-info text-center chartjs-tooltip "><div><div class="custom-title">Monto total ${category}</div><div class="custom-value ${category}">$${formatNumber(totalAmount)}</div></div><a href="clients/documents/?status=${status_index}&expire_at[]=${first_day}&expire_at[]=${last_day}" data-date="" class="custom-link pt-2">Ver más</a></div>`;
                            }

                            if (event.type === 'click' && breakpointUp('sm')) {
                                if ($('.popover').length < 1){
                                    var mouseX = event.native.clientX;
                                    var mouseY = event.native.clientY;
                                    popover.style.left = mouseX-50 + 'px';
                                    popover.style.top = mouseY-10 + 'px';
                                }
                                $('.popover-tool').fadeIn('fast');
                            }else if (event.type === 'mousemove' && breakpointUp('sm')){
                                if ($('.popover').length < 1){
                                    var mouseX = event.native.clientX;
                                    var mouseY = event.native.clientY;
                                    popover.style.left = mouseX-50 + 'px';
                                    popover.style.top = mouseY + 'px';
                                }
                                $('.popover-tool').fadeIn('fast');
                            }
                            if(breakpointDown('sm')){
                                $(".popover").remove();
                                $("#info").html(`<div class="custom-info"><div><div class="custom-title">Monto total ${category}</div><div class="custom-value ${category}">$${formatNumber(totalAmount)}</div></div><a href="clients/documents/?status=${status_index}&expire_at[]=${first_day}&expire_at[]=${last_day}" data-date="" class="custom-link">Ver más</a></div>`)
                                $("#info").fadeIn('fast')
                            }
                            if ($('.popover').length < 1 && breakpointUp('sm')){
                                document.body.appendChild(popover);
                            }

                            isClickOrTouchEndEventTriggered = true;

                            setTimeout(function() {
                                isClickOrTouchEndEventTriggered = false;
                            }, 200);
                            // this is for selected element
                            var datasets = this.data.datasets;
                            datasets.forEach(function(dataset, datasetIndex) {
                                var datasetBackgroundColor = dataset.backgroundColor;
                                datasetBackgroundColor.forEach(function(backgroundColor, index) {
                                    if (datasetIndex === region.datasetIndex && index === dataIndex) {
                                        datasetBackgroundColor[index] = adjustOpacity(backgroundColor, 1);
                                    } else {
                                        datasetBackgroundColor[index] = adjustOpacity(backgroundColor, 0.3);
                                    }
                                });
                            });
                            this.update();
                        }
                    }
                },
                plugins: [{
                    id:'eventLeave',
                    beforeEvent(chart, args, pluginOptions) {
                        const event = args.event;
                        if (event.type === 'mouseout') {
                            if (!$('.popover').is(':hover')){
                                $(".popover").remove();
                            }
                        }
                    }
                }]
            });
        }
    }

    function adjustOpacity(color, opacity) {
        var rgbaColor = color.replace('rgb', 'rgba').replace(')', ', ' + opacity + ')');
        return rgbaColor;
    }

    var isClickOrTouchEndEventTriggered = false


    function barClientSales(data) {
        const bar_client_sales = document.getElementById('bar-chart-client-sales');

        if (Array.isArray(data)) {
            var labels = Object.keys(data[0].data);
            var datasets = data.map(function(item) {
                return {
                    label: item.name,
                    backgroundColor: item.dataset.backgroundColor,
                    maxBarThickness: item.dataset.maxBarThickness,
                    borderRadius: item.dataset.borderRadius,
                    categoryPercentage: item.dataset.categoryPercentage,
                    barPercentage: item.dataset.barPercentage,
                    data: Object.values(item.data).map(function(value) {
                        return parseInt(value);
                    })
                };
            });
            new Chart(bar_client_sales.getContext('2d'), {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: datasets
                },
                options: {
                    maintainAspectRatio: false,
                    responsive:true,
                    scales: {
                        x: {
                            grid: {
                              display: false // Oculta las líneas verticales
                            }
                        },
                        y: {
                            beginAtZero: true,
                            grid: {
                                borderDash: 2,
                                drawBorder: false,
                            },
                            ticks: {
                                callback: function(value, index, values) {
                                    return formatValueChart(value);
                                }
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    var value = context.parsed.y;
                                    var formattedValue = formatValueChart(value);
                                    return '$' + formattedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                }
                            }
                        }
                    }
                }
            });
        } else {
            console.error("El formato de data_array no es válido.");
        }
    }

    // On load Graphs Cli
    $(document).ready(function () {
        window.barClientSales = barClientSales;
        window.donutClientDocuments = donutClientDocuments;

        $("#discharge-graph-card").removeClass('mt-40').addClass("mt-1");
        $("#discharge-graph-card").show('slow');

        $("#income-graph-card").show('slow');

        $("#assignment-graph-card").hide('slow');
        $("#top-10-debtors-graph-card").hide('slow');
        $('#margin-graph-card').hide('slow');
        $('#factoring-por-cesionario').hide('slow');


        const margin_ctx = $('#margin-graph-cli')
        const top_10_debtors_ctx = $('#top-10-debtors-cli')

        if (top_10_debtors_ctx.length || margin_ctx.length) {

            //Margin graph
            new Chart(margin_ctx, {
                type: 'line',
                data: {
                    datasets: gon.margin_graph
                },
                options: {
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: ({ label, formattedValue }) => `${formattedValue}%`
                            }
                        },
                        legend: {
                            display: false
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            ticks: {
                                callback: function (value) {
                                    return value + '%';
                                }
                            },
                            beginAtZero: true
                        }
                    }
                }
            });

            // Top 10 debtors
            const data = {
                labels: gon.top_10_debtors_labels,
                datasets: [{
                    axis: 'y',
                    data: gon.top_10_debtors_data,
                    borderRadius: 6,
                    borderDash: [10, 5],
                    fill: false,
                    backgroundColor: [
                        'rgba(33, 150, 243, 1)'
                    ]
                }]
            }

            const config = {
                type: 'bar',
                data,
                options: {
                    scales: {
                        x: {
                            ticks: {
                                callback: function (value) {
                                    return value + '%';
                                }
                            }
                        },
                        y: {
                            grid: {
                                display: false
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            labels: {
                                generateLabels: chart => chart.data.labels.map((l, i) => ({
                                    index: i,
                                    text: "test",
                                }))
                            },
                            display: false
                        },
                        tooltip: {
                            callbacks: {
                                label: ({ label, formattedValue }) => `${formattedValue}%`
                            }
                        }
                    },
                    indexAxis: 'y',
                    responsive: true,
                    maintainAspectRatio: false
                }
            };

            new Chart(top_10_debtors_ctx, config);
        }


    });

    function notificationToExecutive(company_id){
        let client = parseInt(company_id)
        $.ajax({
            type: 'GET',
            dataType: 'script',
            url:'/notificate_to_executive_prime',
            data:{
                company_id: client
            }
        })
    }

    $('body').on('click', '#prime-btn-link', function(){
        notificationToExecutive($(this).data('client'))
    });

    $('body').on('show.bs.collapse', '.collapse-tr', function(){
        $('.collapse').collapse('hide');
        $($(this).data('tr')).addClass('collapse-parent');
    })
    $('body').on('hide.bs.collapse', '.collapse-tr', function(){
        $($(this).data('tr')).removeClass('collapse-parent');
    })

}