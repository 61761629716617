$( document ).on('turbolinks:load', events)

function events() {
  //if ($('#debtors-table').length > 0) {
  //  let url = $('#debtors-table').data('url')
  //  console.log(url)
  //  $.ajax({
  //    url: url,
  //    method: 'GET',
  //    dataType: 'script'
  //  })
  //}
}