import customer from '../../../channels/consumer';

const createRoom = (room_id, room_type, current_user_id) => {
  customer.subscriptions.create(
    { channel: 'ChatChannel', room_type, room_id },
    {
      received(data) {
        const { user_id } = data;
        if (Number(current_user_id) !== Number(user_id)) require(`./${ room_type }`)(data)
      }
    }
  )
}

export default createRoom;