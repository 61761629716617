$(document).on("turbolinks:load", common_events);

function common_events() {
  $('body').on('change', '#purchase_order_bank_account_id', function() {
    const value = $(this).val()
    $('#bank-account-attributes-inputs :input').val('')
    if (value == '0') {
      $('#bank-account-attributes-inputs').removeClass('d-none')
    } else {
      $('#bank-account-attributes-inputs').addClass('d-none')
    }
  })
}