import consumer from "./consumer"

const balanceDump = (company_id, init) => {
  console.log("sss")
  console.log(init)
  if (init){
		$('.balance-button-'+company_id).addClass('disabled');
		$('.message-copy-file-'+company_id).html('El archivo está siendo creado, cuando esté listo actualizaremos esta sección.')
	}else{
		if ($("#google_sheet-"+company_id).length){
      $.ajax({
        url: '/balance_sheet_tab',
        method: 'GET',
        dataType: 'script',
        data: { company_id: company_id }
      })
    }
	}
}

consumer.subscriptions.create("CompanyChannel", {
  received(data) {
    console.log("data: ", data)
    if (data.action === 'balance_dump') balanceDump(data.company_id, data.init)
  }
});
