$( document ).on('turbolinks:load', events)

function events(){
  $('.paginate-on-scroll').on('scroll', function() {
    const scrollLoading = ['true', 'done'].includes($(this).attr('scroll-loading'))
    const paddingTop = Number($(this).css('padding-top').replace('px', ''))
    const paddingBottom = Number($(this).css('padding-bottom').replace('px', ''))
    const currentScroll = $(this).get(0).scrollHeight - $(this).scrollTop() - paddingTop - paddingBottom
    const requestHeight = $(this).height() * 2
    const paginateFormId = $(this).data('paginate-form-id')
    const $pageInput = paginateFormId ? $(paginateFormId).find('#page') : $('#page')
    const currentPage = $pageInput.val()
    if (scrollLoading) return

    if (currentScroll > requestHeight) return

    $(this).attr('scroll-loading', true)
    $pageInput.val(Number(currentPage) + 1).trigger('change')
  })

  $('body').on('click', '.sort-table', function() {
    let newDirection;
    const $target = $($(this).data('target'))
    const currentOrder = $target.val()
    const newOrder = $(this).data('order')
    const currentDirection = $(this).data('direction') || $('#direction').val()
    $(this).parents('.table-responsive').scrollTop(0)

    if (newOrder !== currentOrder) {
      newDirection = 'asc'
    } else {
      newDirection = currentDirection == 'asc' ? 'desc' : 'asc'
    }

    $(this).data('order', newOrder)
    $(this).data('direction', newDirection)
    $target.siblings('input#page').val('1')
    $target.siblings('input#direction').val(newDirection)
    $target.val(newOrder).trigger('change')
  })

  $('body').on('click', '.select-input', function(event) {
    const $target = $(event.target)
    const $inputValue = $(this).find('input[type="hidden"]')
    const $inputText = $(this).find('input[type="text"]')
    const isOpen = $(this).attr('open')
    const currentValue = $(this).find('input[type="hidden"]').val()
    let newValue = $target.attr('value')
    let newText = $target.text()

    if (isOpen) {
      $(this).removeAttr('open')
    } else {
      $(this).attr('open', 'open')
    }

    if ($target.is('li')) {
      $(this).find('li').removeAttr('selected')

      if (newValue == currentValue) {
        newValue = ''
        newText = ''
      } else {
        $($target).attr('selected', 'selected')
      }

      $inputText.val(newText)
      $inputValue.val(newValue).trigger('change')
    }
  })

  $('body').on('click', '.clear-input', function() {
    const targetId = $(this).data('target')
    $(this).closest("form").attr("data-calendar-datetimepicker-r", "yes")
    if ($(targetId).length == 0) return;
    if ($(targetId).hasClass('datetimepicker')) return $(targetId)[0]._flatpickr.clear()

    $(targetId).val('').trigger('change')
  });

  $('body').on('click', '#export_debtors', function() {
    let typeController = $(this).attr('id').split('_')[1];

    $('#' + typeController + '-filter').attr('data-remote', 'false')
    $('#format').val('xlsx').trigger('change')
    $('#' + typeController + '-filter').attr('data-remote', 'true')
    $('#format').val('js')
    $('#' + typeController + '-filter').find(':submit').removeAttr('disabled')
    $('.loader').fadeOut('fast')
  });

  $(window).on('click', function(e) {
    const isSelectInput = $(e.target).parents('.select-input').length > 0

    if (!isSelectInput) {
      $('.select-input').removeAttr('open')
    }
  });

  $('body').on('change', '.table-loader-on-change', function() {
    const tableId = $(this).data('table')
    const $table = $(tableId)
    $table.attr('loading', true)
    $table.attr('scroll-loading', true)
    $('#page').val('1')
    $table.scrollTop(0)
  })

  $('body').on('click', '.table-loader-on-click', function() {
    const targetId = $(this).data('target')
    if (!targetId) return
    const $target = $(targetId).attr('loading', true)
  })
}