$(document).on("turbolinks:load", common_events);

function common_events() {
  window.of_deal = "false";

  $("#edit-legal-representatives").on("click", function () {
    $(".hide-legal-options").toggleClass("d-none");
    if ($(this).html() == "Finalizar") {
      $(this).html("Editar Representantes");
    } else {
      $(this).html("Finalizar");
    }
  });

  $("body").on("click", ".checkbox-legal-representative", function (e) {
    let id = $(this).data("id");
    let url = "/add_representant_id";
    let checkbox = $(this);
    let checkboxes = $(".checkbox-legal-representative");

    if (checkbox.is(":checked")) {
      $.ajax({
        type: "GET",
        url: url,
        dataType: "script",
        data: {
          id_legal_representative: id,
        },
      });
    } else {
      $("#input-legal_representative-" + id).remove();
    }
    enabledButton(checkboxes, "#legal-representative-button");
  });

  function enabledButton(checkboxes_array, button_id) {
    let arrayChecked = [];
    for (let index = 0; index < checkboxes_array.length; index++) {
      let element = checkboxes_array[index];
      if ($(element).is(":checked")) {
        arrayChecked.push(element);
      }
    }
    if (arrayChecked.length > 0) {
      $(button_id).removeClass("disabled");
    } else {
      $(button_id).addClass("disabled");
    }
  }

  function setLegalStatusOnDualCheckBoxValue() {
    // Change legal status input depending if the dual checkbox of is_from_notary is checked or not
    if ($("#is_from_one_day_company").prop("value") == "true") {
      var $one_day_company_container = $(".one-day-company-container");
      let $company_name = $one_day_company_container
        .find(".company-name")
        .text();
      let date = $one_day_company_container.find(".one-day-company-form").val();

      $("#legal_status").val(
        `La personería para representar a ${$company_name} consta en el Registro Electrónico de Empresas del Ministerio de Economía con fecha ${date}`
      );
    } else {
      var $notary_container = $(".notary-container");
      let $company_name = $notary_container.find(".company-name").text();
      let date = $notary_container.find(".date").val();
      let city = $notary_container.find(".city").val();
      let whois_notary = $notary_container.find(".whois-notary").val();

      $("#legal_status").val(
        `La personería para representar a ${$company_name} consta de la escritura pública de fecha ${date}, otorgada en la Notaria de ${city} de ${whois_notary}`
      );
    }
  }

  $("body").on("change", "#is_from_one_day_company", function (e) {
    if ($(this).prop("checked")) {
      $(".one-day-company-container").removeClass("hide");
      $(".notary-container").addClass("hide");
    } else {
      console.log("NOT CHECKED check-is-from-one-day-company");
      $(".notary-container").removeClass("hide");
      $(".one-day-company-container").addClass("hide");
    }
  });

  $("body").one("click", "#add-legal-representatives-from-deal", function () {
    window.of_deal = "true";
  });

  $("body").on("click", "#edit-legal-representatives", function () {
    $(".of_deal").val(window.of_deal);
  });

  $("body").on("click", "#submit-legal-representative", function () {
    $(".of_deal").val(window.of_deal);
    setLegalStatusOnDualCheckBoxValue();
  });

  $('body').on('change', '#authorization_report_action', function (){
    if($(this).val() == 'other'){
      $('#authorization_report_action_description').parent().removeClass('hide');
    }else{
      $('#authorization_report_action_description').parent().addClass('hide');
      $('#authorization_report_action_description').val('')
    }
  })
  
  $('.radio-button-permissions').on('change', function () {
    let checkbox = $(this)
    let checked = checkbox.is(':checked');
    let target = checkbox.data('target');

    $(target).prop('disabled', !checked);
  });

  $('body').on('change', '.check-input-siblings', function(){
    $(this).parent().find('input[type="hidden"]').val($(this).val())
  })
}
