const { getBusinessReportFromApi, getStateFromInfocred } = require('./utils/get_business_report');
const filePreview = require('./utils/file_preview');
var metabase_vars = {
    last_debtor: '',
    last_start_date: '',
    last_end_date: ''
}

$( document ).on('turbolinks:load', events)


function events(){

    // for trigger only once the balance sheet excel from google
    var balance_sheet_dum_tax_folder_init = false;

    function getCompanyQuerySearch() {
        const status_active_input = $("#active-companies-input");
        const status_inactive_input = $("#without-business-companies-input");
        const synchronized_input = $("#synchronized-companies-input");
        const not_synchronized_input = $("#not-synchronized-companies-input");
        const search_box_clients = $('#search-box-clients, #search-box-users');
        const search_box_clients_add_client = $('#search-box-clients-add-client');
        const format = $('#companies-filter-format').val() || "js";

        const executives_ids = $('.executive-checkbox:checked').map(function() {
            return $(this).data("id");
          }).get();

        return {
            state: status_active_input.is(":checked") === true ? "active" : status_inactive_input.is(":checked") === true ? "inactive" : "",
            sii_credential: synchronized_input.is(":checked") === true ? "active" : not_synchronized_input.is(":checked") === true ? "inactive" : "",
            query: search_box_clients.val() || search_box_clients_add_client.val(),
            sales_executives_ids: executives_ids,
            format
        }
    }

    // getCompanyFromApi(url<string>)
    function getCompanyFromApi(url) {
        const data = getCompanyQuerySearch();
        $.ajax({
            url,
            dataType: "script",
            method: "GET",
            data
        });
    }

    // Cambiando la barra de estado según tabs,
    $('#info-tab').on('click', function () {
        $('#title-navbar').html('Información Básica de mi Empresa');
        $('.button-navbar-company').addClass('d-none');
        $('#edit-info').removeClass('d-none');
        $('#new-company').removeClass('d-none');
        $('#edit-info-ico').removeClass('d-none');
    });

	$('#lines-tab').on('click', function () {
        $('#title-navbar').html('Líneas y Sublíneas');
        $('.button-navbar-company').addClass('d-none');
        $('.lines-buttons').removeClass('d-none');
    });
	$('#partner-tab').on('click', function () {
        $('#title-navbar').html('Socios');
        $('.button-navbar-company').addClass('d-none');
        $('.partners-button').removeClass('d-none');
    });
	$('#archives-tab').on('click', function () {
        $('#title-navbar').html('Documentos');
        $('.button-navbar-company').addClass('d-none');
        $('.archive-buttons').removeClass('d-none');
    });
	$('#legal-representatives-tab').on('click', function () {
        $('#title-navbar').html('Representantes Legales');
        $('.button-navbar-company').addClass('d-none');
        $('.legal-representatives-buttons').removeClass('d-none');
    });
	$('#administration-tab').on('click', function () {
        $('#title-navbar').html('Administración');
        $('.button-navbar-company').addClass('d-none');
        $('.administration-buttons').removeClass('d-none');
    });
	$('body').on('click', '#debtors-tab', function () {
        $('#title-navbar').html('Deudores');
        $('.button-navbar-company').addClass('d-none');
        $('.debtors-buttons').removeClass('d-none');
    });
	$('#sii-tab').on('click', function () {
        $('#title-navbar').html('SII');
        $('.button-navbar-company').addClass('d-none');
        $('.sii-buttons').removeClass('d-none');
    });
	$('#banks-tab').on('click', function () {
        $('#title-navbar').html('Cuentas Bancarias');
        $('.button-navbar-company').addClass('d-none');
        $('.bank-buttons').removeClass('d-none');
    });

    // Checkbox seleccionar predeterminado
    // Boton siguiente en tablist

    $('#next-lines').on('click', function (){
        $('#nav-tab a[href="#partner"]').tab('show');
    });
    $('#next-partner').on('click', function (){
        $('#nav-tab a[href="#archives"]').tab('show');
    });
    $('#next-archives').on('click', function (){
        $('#nav-tab a[href="#legal_representatives"]').tab('show');
    });
    $('#next-legal_representatives').on('click', function (){
        $('#nav-tab a[href="#administration"]').tab('show');
    });
    $('#next-administration').on('click', function (){
        $('#nav-tab a[href="#debtors"]').tab('show');
    });
    $('#next-debtors').on('click', function (){
        $('#nav-tab a[href="#sii"]').tab('show');
    });
    $('#next-sii').on('click', function (){
        $('#nav-tab a[href="#banks"]').tab('show');
    });

    function resetClientFilters(){
        $('#filters-selected-container').children().remove();
        $('input.companies-filter').prop('checked', false);
    }

    // checkbox selecciona solo uno

    $('body').on('click', '.company-filter-button', (event) => {
        if ($(event.currentTarget).hasClass('refresh-button')) {
            $('#search-box-clients, #search-box-clients-add-client').val('')
            resetClientFilters();
        }
        $(".loader").fadeIn("fast")

        getCompanyFromApi($(event.currentTarget).data('url'))
    })

    // boton desactivar activar formularios

    $('body').on('click', '.edit-info', function(e) {
        e.preventDefault();
        $('input:not(.rut-input) , .content-table:not(.rut-input)').removeClass('disabled-input');
        $('#save-info').removeClass('d-none');
        $('.edit-info').toggle();
        $('#link-file').addClass('d-none');
        $('#delete-file-pep').removeClass('d-none');
        $('#show-status-history-modal-button').addClass('d-none')
    });

    $('body').on('click', '#delete-file', function (){
        $('#file-pep').addClass('d-none');
        $('#doc-pep').removeClass('d-none');
        $('#input-file-pep').val('');
        $('.success').css({"display": "none"});
        $('.default').removeClass('d-none');
        // Delete file field
        $('#company_file_pep').val(null);
    })


    $('.region-input').on('change', function () {
        $.ajax({
            url: $('.region-input').data("url"),
            dataType: 'script',
            method: "GET",
            data: {region: $(this).val()}
        })
    });

    $('body').on('change','.activity-input-active' ,function () {
        $.ajax({
            url: $('.activity-input').data("url"),
            dataType: 'script',
            method: "GET",
            data: {activity: $(this).val()}
        })
    });

    $('.economic-sector-input').on('change', function () {
        $.ajax({
            url: $('.economic-sector-input').data("url"),
            dataType: 'script',
            method: "GET",
            data: {economic_sector: $(this).val()}
        })
    });

    // cambia el titulo del dropdown selector de tabs

    $('.dropdown-tabs').on('click', function () {
        let dropdownId = $(this).data('id')
        $('.title-button-dropdown-'+ dropdownId).text($(this).text())
        $('.dropdown-tabs').removeClass('active')
    });

    $('body').on('hidden.bs.modal', '#modal-sii', function (){
        const $modalsii = $('#modal-sii');
        $modalsii.find('input:not([type="submit"])').val("");
        $modalsii.find('.file-preview .close').trigger('click');
        $modalsii.find('.success-message').removeClass('success-message');
        $modalsii.find('.error').html("");
        $modalsii.find('.is-invalid').removeClass('is-invalid');
    });

    $('#company_pep_client_true').on('click', viewFilePep)
    $('#company_pep_client_false').on('click', noneFilePep)

    function viewFilePep() {
        $('#file-pep, #file-pep-update').fadeOut(500);
        $('#doc-pep').removeClass('d-none');
        $('#input-file-pep').val('');
    }
    function noneFilePep() {
        $('#file-pep, #file-pep-update').addClass('d-none');
        $('#doc-pep').addClass('d-none');
        $('#input-file-pep').val('');
    }

    $('body').on('change', '.input-file-pep', function(){
        $('.default').addClass('d-none');

        $('.success').fadeIn(500);
    })

    $(document).ready(function () {
        let nameNavBar = $('#title-navbar-label').html()
        if(nameNavBar === "Creando Cliente"){
            $('#save-info').removeClass('d-none')
            $('#submit-info').addClass('click-save-company')
        }
    })
    $('body').on('click', '.click-save-company', function () {
        $('#save-company').click()
        $('.edit-info').addClass('d-none')
    })
    // Cambia el icono de check en backoffice info company
    $(document).ready(function () {
            var check = arrayInputsObligatories();
            if(check) {
                $(".icon-accordion-unfinished").addClass('d-none');
                $(".icon-accordion-check").removeClass('d-none');
            }
            else {
                $(".icon-accordion-unfinished").removeClass('d-none');
                $(".icon-accordion-check").addClass('d-none');
            }
    })

    function arrayInputsObligatories(){
        var arrayInput = new Array();
        var inputValues = $('.input-obligatory'),
        nameValues = [].map.call(inputValues, function (dataInput) {
            arrayInput.push(dataInput.value);
        });
        var camposRellenados = true;
        arrayInput.forEach(function (inputsValuesData) {
            if( inputsValuesData == "" ) {
                camposRellenados = false;
                return false;
            }
        });
        if(camposRellenados == false) {
            return false;
        }
        else {
            return true;
        }
    }

    $('body').on('hide.bs.modal',"#admin-modal", function (){
        $(this).find('input:not([type="submit"])').val("");
        $(this).find('input:not([type="submit"])').removeClass("is-invalid");
        $(this).find('.error').html("");
    });

    $('body').on('change', '.rut-company-load', function (event) {
        $('.loader').fadeIn(100);
        var rut_company = $(this).val();

        event.preventDefault();
        $.ajax({
            type: "GET",
            url: "/company_exist",
            dataType: "script",
            data:{
                rut:rut_company,
            },
            success: function () {

            },
        });
    })

    // botones de edicion de lineas
    $('body').on('click','#edit-lines', function (){
        if($('.edit-line-button').hasClass("d-none") ){
            $('.edit-line-button').removeClass("d-none");
            $(this).html("Finalizar");
        }else{
            $('.edit-line-button').addClass("d-none");
            $(this).html("Editar Líneas");
        }
    });

    // para girar
    $('body').on('click', '.collapse-link', function () {
        var icon = $(this).find('.icon-accordion-right')
        var collapse = $(this).find('.collapse')

        $(collapse).on('hidden.bs.collapse', function () {
            $(icon).toggleClass("down");
        })
        $(collapse).on('shown.bs.collapse', function () {
            $(icon).toggleClass("down");
        })
    })

    $('body').on('click', '.collapse-operations', function () {
        var target = $(this).data('target');
        if ($(target).hasClass('show')) {
            $(this).find('.icon-accordion-right').removeClass('down')
        } else {
            $(this).find('.icon-accordion-right').addClass('down')
        }
    })

    // select locked
    $('body').on('change', '#company_state', function () {
        let val = $(this).val();
        let locked = "locked";
        const previousStatus = $(this).data('previous-status')

        if((val == locked) || (previousStatus === locked && val !== locked)){
            $('#user-locked').html('')
            $('#company_locked_commentary').val('')
            $('#locked-commentary-area').removeClass('d-none')
        }else{
            $('#locked-commentary-area').addClass('d-none')
            $('#locked-commentary-area').val('')
        }
    })
    // Previene q los subfiltros se cierren al darle click a los checkbox
    $(document).on('click', '.dropdown-submenu', function (e) {
        e.stopPropagation();
    });

    var active_chbox = $('#active-companies-input');
    var inactive_chbox = $('#without-business-companies-input');
    var synchronized_chbox = $('#synchronized-companies-input');
    var not_synchronized_chbox = $('#not-synchronized-companies-input');

    active_chbox.on("change", function() {
        if (active_chbox.prop('checked')) {
            inactive_chbox.prop('checked', false);
            $('.filter-selected > div:contains("Inactivo")').parent().remove()
        }else{
            $('.filter-selected > div:contains("Activo")').parent().remove()
        }
    });

    inactive_chbox.on("change",function() {
        if (inactive_chbox.prop('checked')) {
            active_chbox.prop('checked', false);
            $('.filter-selected > div:contains("Activo")').parent().remove()
        }
        else{
            $('.filter-selected > div:contains("Inactivo")').parent().remove()
        }
    });



    synchronized_chbox.on("change", function() {
        if (synchronized_chbox.prop('checked')) {
            not_synchronized_chbox.prop('checked', false);
            $('.filter-selected > div:contains("No Sincronizado")').parent().remove()
        }
        else{
            $('.filter-selected > div:contains("Sincronizado")').parent().remove()
        }
    });

    not_synchronized_chbox.on("change",function() {
        if (not_synchronized_chbox.prop('checked')) {
            synchronized_chbox.prop('checked', false);
            $('.filter-selected > div:contains("Sincronizado")').parent().remove()
        }
        else{
            $('.filter-selected > div:contains("No Sincronizado")').parent().remove()
        }
    });

    // Cuando la pagina se cargue o re-cargue se dejaran todos los check-box sin checkear.
    $( document ).ready(function() {
        $("#dropdown-companies-filter .companies-filter").prop("checked", false);
        // Limpieza del text input de clients
        $('#search-box-clients').val("");
    });

    // Al presionar el boton close los checkboxs se unchekean
    $("body").on('click', '.filter-selected > button.close', function(){

        var with_business_input = $("#active-companies-input");
        var without_business_input = $("#without-business-companies-input");
        var synchronized_input = $("#synchronized-companies-input");
        var not_synchronized_input = $("#not-synchronized-companies-input");


        if (this.dataset.selected === "Activo"){
            with_business_input.prop("checked", false);
            without_business_input.prop("disabled", false);
            $('#filters-selected-container > .filter-selected > div:contains("Activo")').parent().remove();
        }
        else if(this.dataset.selected === "Inactivo"){
            without_business_input.prop("checked", false);
            with_business_input.prop("disabled", false);
            $('#filters-selected-container > .filter-selected > div:contains("Inactivo")').parent().remove();
        }
        else if(this.dataset.selected === "Esta Sincronizado"){
            $("#synchronized-companies-input").prop("checked", false);
            $("#not-synchronized-companies-input").prop("disabled", false);
            $('#filters-selected-container > .filter-selected > div:contains("Esta Sincronizado")').parent().remove();
        }
        else if(this.dataset.selected === "No Sincronizado"){
            not_synchronized_input.prop("checked", false);
            synchronized_input.prop("disabled", false);
            $('#filters-selected-container > .filter-selected > div:contains("No Sincronizado")').parent().remove();
        }
        else if (this.dataset.selected === "Ejecutivos"){
            $('#filters-selected-container > .filter-selected > div:contains("Ejecutivos")').parent().remove();
            $('.executive-container input.executive-checkbox').prop("checked", false);
        }
    });


    function check_not_added_content(checkboxs, title) {
        var array = [];
        var count = 0;

        checkboxs.each(function (index_chbox, chbox) {
          var in_div = $(
            "#filters-selected-container > .filter-selected > div:contains(" +
              title +
              ") > div > span:contains(" +
              chbox.dataset.name +
              ")"
          );
          if (in_div.length > 0) {
            in_div.each(function (index, item) {
              if (item.innerHTML === chbox.dataset.name) {
                return false;
              } else {
                count++;
              }
            });
          }
          if (count === in_div.length) {
            array.push(chbox);
          }
          count = 0;
        });
        return array;
      }


    // input tiene q ser un array, content[0]: tiene el contenido que se va agregar y content[1]: contiene el mensaje de estado de cuanto se va agregar
  function get_append_content(checked_chboxs, left_to_add, title) {
    var content = ["", ""];
    if (checked_chboxs.length === 0 || left_to_add.length === 0) {
      content[0] = "";
      content[1] = "nothing to add";
    } else if (checked_chboxs.length === left_to_add.length) {
      content[0] =
        '<div class="filter-selected filters-selected-container ml-3"> <div>' + title + ": <div>";
      checked_chboxs.each(function (index, item) {
        content[0] =
          content[0] +
          "<span data-id=" +
          item.dataset.id +
          " >" +
          item.dataset.name +
          "</span>" +
          "<br>";
      });
      content[0] =
        content[0] +
        '</div></div><button class="close" type="button" data-selected=' +
        title +
        '> <img class="cancel-btn" src="/assets/icons/icon_cancel-0e3424b5cd4edd5c2e8d5bc727eb07a72c4c5bb5ac34416c90ca3c4df983bc9b.svg"> </button>';
      content[1] = "all content";
    } else {
      left_to_add.forEach(function (item, index) {
        content[0] =
          content[0] +
          "<span data-id=" +
          item.dataset.id +
          ">" +
          item.dataset.name +
          "</span>" +
          "<br>";
      });
      content[1] = "some content";
    }
    return content;
  }


    $("input.companies-filter").on('change', function() {
        var status_active_input = $("#active-companies-input");
        var status_inactive_input = $("#without-business-companies-input");
        var synchronized_input = $("#synchronized-companies-input");
        var not_synchronized_input = $("#not-synchronized-companies-input");
        var search_box_clients = $('#search-box-clients');
        var checked_executives_chboxs = $('.executive-checkbox:checked');

        // Selecciona todos los filtros para verificar si es que ya se ha agregado un div de el filtro clickeado correspondiente
        var check_status_active_div = $('#filters-selected-container > .filter-selected > div:contains("Activo")');
        var check_status_inactive_div = $('#filters-selected-container > .filter-selected > div:contains("Inactivo")');
        var check_synchronized_div = $('#filters-selected-container > .filter-selected > div:contains("Esta Sincronizado")');
        var check_not_synchronized_div = $('#filters-selected-container > .filter-selected > div:contains("No Sincronizado")');
        var not_added_content_executives = check_not_added_content(
            checked_executives_chboxs,
            "Ejecutivos"
          );

        var checked_executives_checkboxs_content = get_append_content(
            checked_executives_chboxs,
            not_added_content_executives,
            "Ejecutivos"
        );

        $('#filters-selected-container').append(
            status_active_input.is(":checked") === true && check_status_active_div.length == 0 ?
            '<div class="filter-selected ml-3"> ' +
            '<div>Activo</div>' +
            '<button class="close" type="button" data-selected="Activo"> <img class="cancel-btn" src="/assets/icons/icon_cancel-0e3424b5cd4edd5c2e8d5bc727eb07a72c4c5bb5ac34416c90ca3c4df983bc9b.svg"> </button>' +
            '</div>'
            : status_inactive_input.is(":checked") === true && check_status_inactive_div.length == 0 ?
            '<div class="filter-selected ml-3"> ' +
            '<div>Inactivo</div>' +
            '<button class="close" type="button" data-selected="Inactivo"> <img class="cancel-btn" src="/assets/icons/icon_cancel-0e3424b5cd4edd5c2e8d5bc727eb07a72c4c5bb5ac34416c90ca3c4df983bc9b.svg"> </button>' +
            '</div>'
            : synchronized_input.is(":checked") === true && check_synchronized_div.length == 0 ?
            '<div class="filter-selected ml-3"> ' +
            '<div>Esta Sincronizado</div>' +
            '<button class="close" type="button" data-selected="Esta Sincronizado"> <img class="cancel-btn" src="/assets/icons/icon_cancel-0e3424b5cd4edd5c2e8d5bc727eb07a72c4c5bb5ac34416c90ca3c4df983bc9b.svg"> </button>' +
            '</div>'
            : not_synchronized_input.is(":checked") === true && check_not_synchronized_div.length == 0 ?
            '<div class="filter-selected ml-3"> ' +
            '<div>No Sincronizado</div>' +
            '<button class="close" type="button" data-selected="No Sincronizado"> <img class="cancel-btn" src="/assets/icons/icon_cancel-0e3424b5cd4edd5c2e8d5bc727eb07a72c4c5bb5ac34416c90ca3c4df983bc9b.svg"> </button>' +
            '</div>'
            : checked_executives_checkboxs_content[1] == "all content" ? checked_executives_checkboxs_content[0]
            : ""
        )


        // Si es q ya hay checkbox selecionados agregamos el contenido que queda
        if (checked_executives_checkboxs_content[1] === "some content") {
            $(
            '#filters-selected-container > .filter-selected > div:contains("Ejecutivos") > div'
            ).append(checked_executives_checkboxs_content[0]);
        }

        // cuando es uncheckeado
        if (!$(this).is(":checked")) {
            if ($(this).hasClass("executive-checkbox")) {

            var spans = $(
                '#filters-selected-container > .filter-selected > div:contains("Ejecutivos") > div > span'
            );
            var unchecked_span = $(
                '#filters-selected-container > .filter-selected > div:contains("Ejecutivos") > div > span:contains(' +
                $(this).data("name") +
                ")"
            );
            if (spans.length === 1) {
                unchecked_span.parent().parent().parent().remove();
            } else {
                unchecked_span.next().remove(); // delete br
                unchecked_span.remove();
            }
            }
        }
    });

    $('body').on('click', '.button-sum-amounts', function() {
        let id = $(this).data('id')
        $('.col-company-del-'+id).addClass('hide')
        $('#col-company-'+id)
            .find('.amount_for_expires')
            .addClass('hide')
        $('#col-company-'+id).attr('colspan', '3')
        $('#loader-partner-'+id).removeClass('d-none')

    })

    $('body').on('click', '#resumen-tab', function() {
        $.ajax({
            type:'GET',
            url:"/resumen",
            dataType: "script",
            data:{
                sales_executive_id:$(this).data('salesexecutive'),
                company_id:$(this).data('company'),
                action_view:$(this).data('actionview'),
                document_type: $(this).data('documenttype'),
                year:$(this).data('year')
            }
        })
    })


    $('body').on('click', '#link-modal-observation', function(){
        $.ajax({
            type:'GET',
            url:"/get_show_document_modal",
            dataType: "script",
            data:{ archive_id: $(this).data("archive-id")}
        })
    });

    $('body').on('keyup', '#search-box-clients, #search-box-clients-add-client, #search-box-users', function(event) {
        if (event.which == 13) {
            getCompanyFromApi($(event.currentTarget).data('url'))
        }
    })

    $('body').on('click', '.btn-back-to-amount_for_expires', function() {
        const id = $(this).data('id')

        $('.col-company-del-'+id)
            .removeClass('hide')

        $('#col-company-'+id)
            .attr('colspan', '1')

        $('#col-company-'+id)
            .find('.amount_for_expires')
            .removeClass('hide')

        $('#col-company-'+id)
            .find(`#loader-partner-${id}`)
            .addClass('hide')

        $('#col-company-'+id)
            .find('.total_amount_for_expires').addClass('hide')
    })

    $("#modal-status-history").on('show.bs.modal', function () {
        $("html").addClass("modal-open")
    }).on("hidden.bs.modal", function () {
        $("html").removeClass("modal-open")
    });

    $('body').on('click', '#providers-search-button', function() {
        const companyId = $(this).data('company-id')
        const value = $('#providers-rut-input').val()
        const sortBy = $(this).data('sort') || ''

        $.ajax({
            url: `/companies/${companyId}/debtors/providers_cross_sales?provider_filter=${value}&sort_by=${sortBy}`,
            dataType: 'script',
            method: "GET",
        });
    })

    $('body').on('click', '#providers-refresh-button', function() {
        $('#providers-rut-input').val('')
        $('#providers-search-button').removeData('sort')
        $('#providers-search-button').click()
    })

    $('body').on('click', '#clients-search-button', function() {
        const companyId = $(this).data('company-id')
        const value = $('#clients-rut-input').val()
        const sortBy = $(this).data('sort') || ''

        $.ajax({
            url: `/companies/${companyId}/debtors/clients_cross_sales?client_filter=${value}&sort_by=${sortBy}`,
            dataType: 'script',
            method: "GET",
        });
    })

    $('body').on('click', '#clients-refresh-button', function() {
        $('#clients-rut-input').val('')
        $('#clients-search-button').removeData('sort')
        $('#clients-search-button').click()
    })

    $('body').on('click', '.cross-sales-providers-sort', function() {
        $('#providers-search-button').data('sort', $(this).data('sort'))
        $('#providers-search-button').click()
    })

    $('body').on('click', '.cross-sales-clients-sort', function() {
        $('#clients-search-button').data('sort', $(this).data('sort'))
        $('#clients-search-button').click()
    })

    $('body').on('click', '.cross-sales-detail', function() {
        const companyId = $(this).data('company-id')
        const debtorCompanyId = $(this).data('debtor-company-id')

        $('.loader').fadeIn(100);

        $.ajax({
            url: `/companies/${companyId}/debtors/cross_sales_detail?debtor_company_id=${debtorCompanyId}`,
            dataType: 'script',
            method: "GET",
        });
    })

    $('#cross-sales-detail').on('hide.bs.modal', function (e) {
        $(this).find('thead').html('')
        $(this).find('tbody').html('')
    })


    $('body').on('click', '#risk-tab', function(){

        var tabs_panel = $('.tab-pane');

        tabs_panel.each(function(el){
            if ($(this).hasClass("active")){
                $(this).removeClass("active");
            }
        });

        var risk_panel = $('#risk-panel')
        risk_panel.addClass('active');
        risk_panel.removeClass('fade');

    });

    $("body").on('click', '#debtor-tab-tab, .debtor-refresh', function(){
        $.ajax({
            url:$(this).data("url"),
            dataType: "script",
            type: "GET"
        })
    })

    $('body').on('click', '#client-business-tab', function () {
        const companyRut = $(this).data('company-rut')
        if (companyRut) {
            getBusinessReportFromApi(companyRut)
        }
    })

    $('body').on('click', '#deal-lost-dashboard', function(){
        let url = $(this).data('url');
        $.ajax({
            type: 'GET',
            url: url,
            dataType: 'script'
        })
    })

    $('body').on('keypress', '#providers-rut-input', function(event) {
        if (event.keyCode == 13) {
            $('#providers-search-button').trigger('click')
        }
    })

    $('body').on('keypress', '#clients-rut-input', function(event) {
        if (event.keyCode == 13) {
            $('#clients-search-button').trigger('click')
        }
    })

    $('body').on('click', '#debts-tab', function() {
        if ($(this).data('rut')) {
            getStateFromInfocred($(this).data('rut'), '#sbif-table')
            $(this).data('rut', '')
        }
    })

    $('body').on('click', '#debts-dicom-accordion', function() {
        if ($(this).data('url')) {
            $.ajax({
                type: "GET",
                url: $(this).data('url'),
                dataType: "script",
                success: function () {
                    $('.loader').fadeOut(500);
                },
                error: function (){
                    $('.loader').fadeOut(500);
                    alert("Hubo un error inesperado!");
                }
            });
            $(this).data('url', '')
        }
    })

    $('body').on('click', '#debts-dicom-refresh', function() {
        $('#dicom-table-container').find('.table-loader').removeClass('d-none').addClass('d-flex')
        $('#dicom-table-container').find('table').addClass('d-none')
    })

    $('body').on('submit', '#line-form', function() {
        $(this).find('.miles').unmask()
    })

    $('body').on('change', '.visit_pictures', function() {
        filePreview(this,'#image-visit', 'visit-image')
        $(this).siblings('label').addClass('d-none')
        createInputVisitPicture()
    })

    function createInputVisitPicture(){
        var count = Math.random().toString(36).substr(2, 9);
        var formGroup = document.createElement("div");
        formGroup.className = "form-group";

        var label = document.createElement("label");
        label.setAttribute("for", "visit-pictures-"+count);
        label.className = "link-a";
        label.textContent = "Subir Imagen";

        var fileField = document.createElement("input");
        fileField.setAttribute("type", "file");
        fileField.setAttribute("id", "visit-pictures-"+count);
        fileField.setAttribute("name", "visit[visit_pictures][]");
        fileField.setAttribute("accept", "image/png, image/jpeg");
        fileField.className = "d-none visit_pictures";
        fileField.setAttribute("multiple", "true");

        var errorDiv = document.createElement("div");
        errorDiv.className = "error";

        formGroup.appendChild(label);
        formGroup.appendChild(fileField);
        formGroup.appendChild(errorDiv);

        var container = document.getElementById("new_picture");
        container.appendChild(formGroup);
    }

    $('body').on('click', '.link-resumen-client', function(e){
        let id = $(this).data('id')
        let url = $(this).data('url')
        setTimeout(function(){
            document.location=url+id
        }, 500)
        setTimeout(function(){
            window.scrollBy({top:5});
        }, 1000)
        $(id).trigger('click');
        e.preventDefault()
    })

    // Delete Patrimonios
    $('body').on('click', '.delete-circulante, .delete-vehicle, .delete-leasing, .delete-possession, .delete-bank, .delete-leasing-bank, .delete-leasing-no-bank', function () {
        let ID = $(this).data('id');
        let company_id = $(this).data('company-id');
        let type = $(this).data('type');
        let message;


        switch (type) {
            case 'circulante':
                message = "esta circular"
                break;
            case 'possession':
                message = "esta propiedad"
                break;
            case 'leasing':
                message = "este leasing"
                break;
            case 'leasing-bank':
                message = "esta deuda de leasing"
                break;
            case 'leasing-no-bank':
                message = "esta deuda de leasing no bancaria"
                break;
            case 'bank':
                message = "esta deuda"
                break;
            case 'vehicle':
                message = "este vehiculo"
                break
        }

        swal({
            html: true,
            title: 'Aviso!',
            text: `¿Estás seguro de eliminar ${message}?`,
            buttons: ["Cancelar", "Continuar"],
            dangerMode: false,
        }).then((isConfirming) => {
            if (isConfirming) {
                $('.loader').fadeIn(100);
                $.ajax({
                    type: "POST",
                    url: $(this).data('url'),
                    dataType: "script",
                    data: {id: ID, company_id: company_id, resource_type: type},
                    success: function () {
                        $('.loader').fadeOut(500);
                    },
                    error: function (){
                        $('.loader').fadeOut(500);
                        alert("Hubo un error inesperado!");
                    }
                });
            }
        });
    });

    $('body').on('click', '.edit-bank, .edit-circulante, .edit-vehicle, .edit-bienes-leasing, .edit-possession, .edit-leasing-bank, .edit-leasing-no-bank', function () {
        $('#modal-edit-heritage').modal('toggle');
        let ID = $(this).data('id');
        let company_id = $(this).data('company-id');
        let type = $(this).data('type');

        $('.loader').fadeIn(100);
        $.ajax({
            type: "POST",
            url: $(this).data('url'),
            dataType: "script",
            data: {id: ID, company_id: company_id, type: type},
            success: function () {
                $('.loader').fadeOut(500);
            },
            error: function (){
                $('.loader').fadeOut(500);
                alert("Hubo un error inesperado!");
            }
        });
    });

    $('body').on('click', '#clean-report-cesion', function(){
        $('#content-resumen-cesion').html('')
        $('#start_date').val('')
        $('#end_date').val('')
    })

    $('body').on('change', '#select-cesionario', function(){
        let table = $($(this).data('table')).find("table")
        let value = $(this).val();
        searchSelectTable(table, value)
    })

    function searchSelectTable(table, valueSelect) {
        table.find("tbody").find("tr").each(function() {
            const row = $(this);
            const textRow = row.text().toLowerCase();
            const coincide = textRow.includes(valueSelect.toLowerCase());

            if (coincide) {
                row.show();
            } else {
                row.hide();
            }
        });
    }

    $('body').on('click', '#filter-clean', function(){
        $('.loader').fadeIn('fast');
        $('#form-transferred-invoice').find('input[type="submit"]').trigger('click');
    })

    $('body').on('click', '#filter-select', function () {
        let select = $('#select-cesionario')
        let debtor = $('#select-debtor')
        let transferredCompanyId = $(select).val();
        let debtorCompanyId = $(debtor).val();
        console.log(debtorCompanyId)
        let url = $(select).data('url');
        $('.loader').fadeIn(100)
        $.ajax({
            url: url,
            method: 'GET',
            dataType: 'script',
            data:{
                transferred_company_id: transferredCompanyId,
                debtor_company_id: debtorCompanyId
            }
        })
    })

    if ($("#ant-financial-tab").length){
        $("#link-resumen").trigger("click")
    }

    $('body').on('change', '#years_period_checkbox, #unlimited_checkbox', function() {
        const isUnlimitedCheckbox = $(this).val() == '-1'

        if (isUnlimitedCheckbox) {
            $('#period_duration_years').attr('disabled', 'disabled')
            $('#period_duration_years').val('')
            return
        }

        $('#period_duration_years').removeAttr('disabled')
        $('#period_duration_years').val('').trigger('change')
    })

    $('body').on('change', '#company_company_type', function() {
        const value = $(this).val()
        const $civilStatusInput = $('#company_civil_status')
        const $civilStatusParent = $civilStatusInput.parent()

        if (value == 'natural_person') return $civilStatusParent.removeClass('hide')

        $civilStatusInput.val('').trigger('change')
        $civilStatusParent.addClass('hide')
    })

    $('body').on('change', '#company_civil_status', function() {
        const value = $(this).val()
        const $maritalRegimeInput = $('#company_marital_regime')
        const $maritalRegimeParent = $maritalRegimeInput.parent()
        const $spouseInputContainer = $('#company-spouse-input-container')

        if (value == 'Casado/a') {
            $maritalRegimeParent.removeClass('hide')
            $spouseInputContainer.removeClass('hide')
            return
        }

        $maritalRegimeInput.val('')
        $maritalRegimeParent.addClass('hide')
        $spouseInputContainer.addClass('hide')
    })

    $('#company_phone_number').mask('(+000) 000000000');
    $('#company_employees_number').mask('000000000000#');

    $('body').on('click', '#companies-xlsx-export, .companies-xlsx-export', function(){

      $('#companies-filter-format').val("xlsx");

      let data = getCompanyQuerySearch()
      data["no_limit"] = true
      let url = "/clientes?"
      var counter = 0

      $.each(data, function(key, value){
        if (counter == 0){
            url = url + key.toString() + "=" + value.toString();
        }else{
            url = url + "&" + key.toString() + "=" + value.toString();
        }
        counter += 1;
      });

      window.open(url, "_blank");
      $('#companies-filter-format').val("html");
    });

    $('body').on('click', '#delete-file-pep', function(){
        $('#delete-file-pep').parent().remove();
        $('#destroy-file-pep').val("true");
        $('#file-pep').data("file-pep", "false");
        $('#doc-pep').show();
        // Purge file and show the default state of the file
        let file_dummy = $('#company_file_pep').next()
        file_dummy.find('.success').css('display', 'none')
        file_dummy.find('.default').removeClass('d-none')
    });

    $('body').on('submit', '#company-resumen-metabase-form', function(){

        var attributes_inputs = ["#ft2-debtor-select", '#filter-metabase-start-date', '#filter-metabase-end-date'] // values from html input
        var attrs_inputs_on_change = ['#ft2-debtor-changed', '#cli-meta-start-date-changed', '#cli-meta-end-date-changed'] // input that advise controller to update or not certains graphs
        var attrs_last_val_keys = ['last_debtor', 'last_start_date', 'last_end_date'] // keys to access last value saved from far metabase_vars

        attributes_inputs.forEach(function(key, index){
            var $selector_input = $(key)
            var $selector_input_on_change = $(attrs_inputs_on_change[index])

            if ($selector_input.val() != metabase_vars[attrs_last_val_keys[index]]){
                $selector_input_on_change.val('true');
            }else{
                $selector_input_on_change.val('false');
            }

            var last_value_key = attrs_last_val_keys[index];
            metabase_vars[last_value_key] = $selector_input.val();
        });
    });


    $('body').on('click','#balance-sheet-dump-tax-folder-accordion', function(){
			if (!balance_sheet_dum_tax_folder_init){
				$.ajax({
						url: '/balance_sheet_tab',
						method: 'GET',
						dataType: 'script',
						data: { company_id: $(this).data("company") }
					})
				balance_sheet_dum_tax_folder_init = true;
			}
    });


    if($('#select-executive').length > 0){
        console.log("pene")
        $('#select-executive').val('')
        $('#select-executive').select2({
            ajax: {
                url: '/charge_executives',
                dataType: 'json',
                method: 'GET',
                cache: false,
                delay: 400,
                data: function(params){
                    var name = {name: params.term}
                    return name
                },
                processResults: function (response) {
                    if(response != 'null'){
                        return {
                            results: response
                        };
                    }
                }
            },
            language: 'es',
        });
    }


    $('body').on('click', '#add-client-tab', function(){
        $.ajax({
            type: "GET",
            url: $(this).data('url'),
            dataType: "script",
            success: function () {
                $('.loader').fadeOut()
            },
        });
    });

    $('body').on('click', '#refresh-button-add-client', function(){
        //Clear add_clients_filters
        $('#filter-add-clients-form').find("#query").val("");
    });

}
