import updateLastPowerJudicialSessionStorage from './update_last_power_judicial_session_storage';
import renderPowerJudicialModal from './render_power_judicials_modal';

$( document ).on('turbolinks:load', events)

function events(){
  const $powerJudicialsModal = $('#power-judicials-modal')

  $('body').on('click', '.show-details-power', function(){
    const data = $(this).data('details')
    const data_name = $(this).data('name')
    const target = $(this).data('target')
    const view = $(this).data('view')

    $.ajax({
      type: 'POST',
      url:'/get_data_details',
      dataType: 'script',
      data:{
        data,
        data_name,
        target,
        view
      }
    })
  })

  $('body').on('change', '#include_dte, #include_dte_tool', function (){
    const $label = $(this).siblings('label')
    if($(this).prop('checked')){
      $(this).val("true")
      $label.addClass('active')
    }else{
      $(this).val("false")
      $label.removeClass('active')
    }
  })

  $('#form-power-judicial').on('submit', function(){
    $.ajax({url:'/pending', dataType: 'script', method: 'GET'})
  })

  $('body').on('click', '#power-judicials-modal-clear', (e) => {
    $powerJudicialsModal.find('#power-judicials-table').addClass('d-none')
    $powerJudicialsModal.find('#name-input').val('')
    $powerJudicialsModal.find('#rut').val('')
    $powerJudicialsModal.find('#power-judicials-modal-tabs').html('')
    $powerJudicialsModal.find('#power-judicials-modal-tabs-content').html('')
    $powerJudicialsModal.find('#include-dte-container').find('#include_dte').prop('checked', false);
    $powerJudicialsModal.find('#include-dte-container').find('label').removeClass('active')
    $('#power-judicials-modal-message').addClass('d-none')
    $('#power-judicials-modal-message').find('.message').text('')
    updateLastPowerJudicialSessionStorage(null, true)
  })
  
  $('body').on('click', '#power-judicials-modal-submit', (e) => {
    $powerJudicialsModal.find('#power-judicials-modal-loader').removeClass('d-none')
    $powerJudicialsModal.find('#power-judicials-modal-message').addClass('d-none')
    $powerJudicialsModal.find('#power-judicials-table').addClass('d-none')
    $powerJudicialsModal.find('#power-judicials-modal-tabs').html('')
    $powerJudicialsModal.find('#power-judicials-modal-tabs-content').html('')

    updateLastPowerJudicialSessionStorage({
      rut: $powerJudicialsModal.find('#rut').val(),
      name: $powerJudicialsModal.find('#name-input').val(),
      include_dte: $powerJudicialsModal.find('#include_dte').prop('checked'),
      pending: true,
    }, true)
  })

  $('body').on('click', '#open-power-judicials-modal-btn', (e) => {
    $(e.currentTarget).find('.count-notifications').addClass('d-none')
    const lastPowerJudicialRequest = sessionStorage.getItem('last_power_judicial_request')
    if (lastPowerJudicialRequest) {
      renderPowerJudicialModal(JSON.parse(lastPowerJudicialRequest))
      updateLastPowerJudicialSessionStorage({ not_seen: false })
    }
  })
}