// https://getbootstrap.com/docs/4.0/components/alerts/
export default function sendNotification (title = '', message = '') {
  if (("Notification" in window)) {
    console.log(Notification.permission)
    if (Notification.permission === "granted") {
      const notification = new Notification(title, { body: message });
      
      // close the notification after 10 seconds
      setTimeout(() => {
        notification.close();
      }, 10 * 1000);
    }
  }
}