$(document).on("turbolinks:load", common_events);

console.log("test");

function checked_checkbox_array_to_comma_split(array) {
  var str = "";
  array.each(function (index, item) {
    if (item.checked) {
      if (index === 0 || index - 1 === array.length) {
        str = str + item.dataset.id;
      } else {
        str = str + "," + item.dataset.id;
      }
    }
  });
  return str;
}

function checked_checkbox_array_to_comma_split_doc_filter(array) {
  var str = "";
  array.each(function (index, item) {
    if (item.checked) {
      if (index === 0 || index - 1 === array.length) {
        str = str + item.dataset.value;
      } else {
        str = str + "," + item.dataset.value;
      }
    }
  });
  return str;
}

function getCurrentCheckedFilters() {
  const executives_checkboxs = $(
    "#dropdown-documents-filter input.executive-checkbox"
  );
  const companies_checkboxs = $(
    "#dropdown-documents-filter input.companies-checkbox"
  );
  const typeofdoc_checkboxs = $(
    "#dropdown-documents-filter input.typeofdoc-checkbox"
  );
  const with_business_checkbox = $("#has-business-documents-checkbox1");
  const without_business_checkbox = $("#has-business-documents-checkbox2");
  const query = $("#search-box-document");
  const created_at = $('#created_at');
  const direction = $('#direction');
  return {
    executives_checkboxs,
    companies_checkboxs,
    typeofdoc_checkboxs,
    with_business_checkbox,
    without_business_checkbox,
    query,
    created_at,
    direction
  };
}

function getDocumentsFromApi(order = "") {
  const {
    executives_checkboxs,
    companies_checkboxs,
    typeofdoc_checkboxs,
    with_business_checkbox,
    without_business_checkbox,
    query,
    created_at,
    direction
  } = getCurrentCheckedFilters();

  // ajax data para enviar
  const companies_str =
    checked_checkbox_array_to_comma_split(companies_checkboxs);
  const executive_str =
    checked_checkbox_array_to_comma_split(executives_checkboxs);
  const typeofdoc_str =
    checked_checkbox_array_to_comma_split_doc_filter(typeofdoc_checkboxs);

  // excel href build
  var has_business_data =
    with_business_checkbox.is(":checked") === true
      ? "Si"
      : without_business_checkbox.is(":checked") === true
      ? "No"
      : "";
  let export_document_table = $("#export_document_table");
  export_document_table.attr(
    "href",
    "/documents.xlsx?company_id=" +
      companies_str +
      "&sales_executive_ids=" +
      executive_str +
      "&has_business_id=" +
      has_business_data +
      "&dte=" +
      typeofdoc_str +
      "&query=" +
      query.val()
  );
  const name = $("#company-search").val();

  const path = window.location.pathname;
  const params = window.location.search;

  const companyId = path.split("/")[1];

  const data = {
    query: name,
    url: path + params,
    company_id: companyId,
    sales_executive_ids: executive_str,
    has_business_id:
      with_business_checkbox.is(":checked") === true
        ? "Si"
        : without_business_checkbox.is(":checked") === true
        ? "No"
        : "",
    dte_filter: typeofdoc_str,
    order,
    created_at: created_at.val(),
    direction: direction.val()
  };

  if (order) data.page = 1;
  $.ajax({
    url: data.url,
    dataType: "script",
    method: "GET",
    data,
  });
}

function common_events() {
  setSiiDocumentsScrollPagination();

  $("body").on("deal:update", setSiiDocumentsScrollPagination);

  $("body").on("click", ".checkbox-documents", function (e) {
    let id = $(this).data("id");
    let rut = $(this).data("rut");
    let url = "add_document_input";
    let checkbox = $(this);
    if (checkbox.is(":checked")) {
      $.ajax({
        type: "GET",
        url: url,
        dataType: "script",
        data: {
          id_document: id,
          rut_document: rut,
        },
      });
    } else {
      $("#input-document-" + id).remove();
    }
  });

  $("body").on("click", "#send-documents-deal", function (e) {
    e.preventDefault();
    let form = $("#create-deal-submit");
    let arrayInputs = $(".checkbox-documents:input:checked");
    let array = [];
    let check_boxs_info = []; // mantain all the data of the checkbox clicked like rut, doc_type, etc.
    for (let i = 0; i < arrayInputs.length; i++) {
      element = $(arrayInputs[i]).data("rut");
      check_boxs_info.push($(arrayInputs[i]));
      array.push(element);
    }
    let unique = Array.from(new Set(array));
    if (unique.length == 1) {
      // check if the documents are of diferent types
      let same_doc_type = true;
      let last_doc = "";
      check_boxs_info.forEach(function (item, index) {
        if (last_doc != "") {
          if (last_doc.data("document-type") != item.data("document-type")) {
            same_doc_type = false;
            return false;
          }
        }
        last_doc = item;
      });
      if (same_doc_type) {
        form.click();
      } else {
        // diferent doc types trow error
        $("#deal-error-in-document").html("");
        $("#title-modal").html("El negocio no puede crearse");
        $("#subtitle-modal").html(
          "No se pudo crear porque se presentaron los siguientes errores"
        );
        $("#deal-error-in-document").html(
          "Los documentos agregados deben ser del mismo tipo."
        );
        $("#errors-modal-deal-documents").modal("toggle");
      }
    } else {
      $("#deal-error-in-document").html("");
      $("#title-modal").html("El negocio no puede crearse");
      $("#subtitle-modal").html(
        "No se pudo crear porque se presentaron los siguientes errores"
      );
      $("#deal-error-in-document").html(
        "No pueden ser facturas de diferentes clientes"
      );
      $("#errors-modal-deal-documents").modal("toggle");
    }
  });

  $(document).ready(function (e) {
    let checkboxs = $(".checkbox-documents");
    for (let i = 0; i < checkboxs.length; i++) {
      let checkbox = checkboxs[i];
      if (checkbox.length > 0) {
        $(checkbox).prop("checked", false);
      }
    }
  });

  // Permite q solo un check-box sea true a la vez (solo con 2 check-box). Ademas elimina los divs en "filtros seleccionados"
  function only_one_checkbox_active(chbox1, chbox2) {
    if (chbox1.is(":checked") === true) {
      chbox2.prop("disabled", true);
    } else if (
      chbox1.is(":checked") === false &&
      chbox2.prop("disabled") === true
    ) {
      chbox2.prop("disabled", false);
      var filter_selected_div =
        chbox1.attr("id") === "with-business-companies-input"
          ? $(
              '#filters-selected-container > .filter-selected > div:contains("Con Negocio")'
            )
          : chbox1.attr("id") === "synchronized-companies-input"
          ? $(
              '#filters-selected-container > .filter-selected > div:contains("Esta Sincronizado")'
            )
          : "";
      if (filter_selected_div.length > 0) {
        filter_selected_div.parent().remove();
      }
    } else if (chbox2.is(":checked") === true) {
      chbox1.prop("disabled", true);
    } else if (
      chbox2.is(":checked") === false &&
      chbox1.prop("disabled") === true
    ) {
      var filter_selected_div =
        chbox2.attr("id") === "without-business-companies-input"
          ? $(
              '#filters-selected-container > .filter-selected > div:contains("Sin Negocio")'
            )
          : chbox2.attr("id") === "not-synchronized-companies-input"
          ? $(
              '#filters-selected-container > .filter-selected > div:contains("No Sincronizado")'
            )
          : "";
      if (filter_selected_div.length > 0) {
        filter_selected_div.parent().remove();
      }
      chbox1.prop("disabled", false);
    }
  }

  // Cuando la pagina se cargue o re-cargue se dejaran todos los check-box y text-input sin checkear y vacio.
  $(document).ready(function () {
    $("#dropdown-documents-filter .documents-filter").prop("checked", false);
    // Esto borra todos los filtros seleccionados en la derecha
    // $('#filters-selected-container > .filter-selected > div:contains("Con Negocio")').parent().remove();
    // Limpieza del text input de documents
    $("#executive-search-documents").val("");
    $("#companies-search-documents").val("");
    $("#search-box-document").val("");
  });

  $(".search-input").keyup(function (e) {
    var span = $("#" + this.id).prev();
    var img = span.prev();
    // si el input esta vacio
    if (!this.value) {
      span.show();
      img.show();
    } else {
      span.hide();
      img.hide();
    }
  });

  function return_checked_checkboxs(input) {
    var array = [];
    input.each(function (index, item) {
      if (item.checked) {
        array.push(item);
      }
    });
    return array;
  }
  // input tiene q ser un array, content[0]: tiene el contenido que se va agregar y content[1]: contiene el mensaje de estado de cuanto se va agregar
  function get_append_content(checked_chboxs, left_to_add, title) {
    var content = ["", ""];
    if (checked_chboxs.length === 0 || left_to_add.length === 0) {
      content[0] = "";
      content[1] = "nothing to add";
    } else if (checked_chboxs.length === left_to_add.length) {
      content[0] =
        '<div class="filter-selected-doc ml-3"> <div>' + title + ": <div>";
      checked_chboxs.forEach(function (item, index) {
        content[0] =
          content[0] +
          "<span data-id=" +
          item.dataset.id +
          " >" +
          item.dataset.name +
          "</span>" +
          "<br>";
      });
      content[0] =
        content[0] +
        '</div></div><button class="close" type="button" data-selected=' +
        title +
        '> <img class="cancel-btn" src="/assets/icons/icon_cancel-0e3424b5cd4edd5c2e8d5bc727eb07a72c4c5bb5ac34416c90ca3c4df983bc9b.svg"> </button>';
      content[1] = "all content";
    } else {
      left_to_add.forEach(function (item, index) {
        content[0] =
          content[0] +
          "<span data-id=" +
          item.dataset.id +
          ">" +
          item.dataset.name +
          "</span>" +
          "<br>";
      });
      content[1] = "some content";
    }
    return content;
  }

  function check_not_added_content(checkboxs, title) {
    var array = [];
    var count = 0;
    var content_to_check = $(
      "#filters-selected-container > .filter-selected-doc > div:contains(" +
        title +
        ") > div > span"
    );

    checkboxs.forEach(function (chbox, index_chbox) {
      var in_div = $(
        "#filters-selected-container > .filter-selected-doc > div:contains(" +
          title +
          ") > div > span:contains(" +
          chbox.dataset.name +
          ")"
      );
      if (in_div.length > 0) {
        in_div.each(function (index, item) {
          if (item.innerHTML === chbox.dataset.name) {
            return false;
          } else {
            count++;
          }
        });
      }
      if (count === in_div.length) {
        array.push(chbox);
      }
      count = 0;
    });
    return array;
  }

  // On btn close event
  $(document).on("click", ".filter-selected-doc > button.close", function (e) {
    var spans = $(this).prev().children().children();
    if ($(this).data("selected") === "Ejecutivos") {
      spans.each(function (index, item) {
        $("#executive-documents-checkbox" + item.dataset.id).prop(
          "checked",
          false
        );
      });
      $(this).parent().remove();
    } else if ($(this).data("selected") === "Clientes") {
      spans.each(function (index, item) {
        $("#company-documents-checkbox" + item.dataset.id).prop(
          "checked",
          false
        );
      });
      $(this).parent().remove();
    } else if ($(this).data("selected") === "Tipos") {
      spans.each(function (index, item) {
        if (item.innerHTML === "Factura") {
          $("#invoice-documents-checkbox").prop("checked", false);
        } else if (item.innerHTML === "Factura Exenta") {
          $("#exempt-invoice-documents-checkbox").prop("checked", false);
        } else if (item.innerHTML === "Cheque") {
          $("#check-documents-checkbox").prop("checked", false);
        } else if (item.innerHTML === "Letras") {
          $("#letters-documents-checkbox").prop("checked", false);
        } else if (item.innerHTML === "Pagaré") {
          $("#promissory-note-documents-checkbox").prop("checked", false);
        }
      });
      $(this).parent().remove();
    } else if ($(this).data("selected") === "Sin Negocio") {
      $("#has-business-documents-checkbox2").prop("checked", false);
      $("#has-business-documents-checkbox1").prop("disabled", false);
      $(this).parent().remove();
    } else if ($(this).data("selected") === "Con Negocio") {
      $("#has-business-documents-checkbox1").prop("checked", false);
      $("#has-business-documents-checkbox2").prop("disabled", false);
      $(this).parent().remove();
    }

    getDocumentsFromApi();
  });

  $(".documents-filter").on("change", function () {
    // cuando es uncheckeado
    if (!$(this).is(":checked")) {
      if ($(this).hasClass("executive-checkbox")) {
        var spans = $(
          '#filters-selected-container > .filter-selected-doc > div:contains("Ejecutivos") > div > span'
        );
        var unchecked_span = $(
          '#filters-selected-container > .filter-selected-doc > div:contains("Ejecutivos") > div > span:contains(' +
            $(this).data("name") +
            ")"
        );
        if (spans.length === 1) {
          unchecked_span.parent().parent().parent().remove();
        } else {
          unchecked_span.next().remove(); // delete br
          unchecked_span.remove();
        }
      } else if ($(this).hasClass("companies-checkbox")) {
        var spans = $(
          '#filters-selected-container > .filter-selected-doc > div:contains("Clientes") > div > span'
        );
        var unchecked_span = $(
          '#filters-selected-container > .filter-selected-doc > div:contains("Clientes") > div > span:contains(' +
            $(this).data("name") +
            ")"
        );
        if (spans.length === 1) {
          unchecked_span.parent().parent().parent().remove();
        } else {
          unchecked_span.next().remove(); // delete br
          unchecked_span.remove();
        }
      } else if ($(this).hasClass("typeofdoc-checkbox")) {
        var spans = $(
          '#filters-selected-container > .filter-selected-doc > div:contains("Tipos de Documentos") > div > span'
        );
        var unchecked_span = $(
          '#filters-selected-container > .filter-selected-doc > div:contains("Tipos de Documentos") > div > span:contains(' +
            $(this).data("name") +
            ")"
        );
        if (spans.length === 1) {
          unchecked_span.parent().parent().parent().remove();
        } else {
          unchecked_span.next().remove(); // delete br
          unchecked_span.remove();
        }
      } else if ($(this).hasClass("with-business-checkbox")) {
        var div = $(
          '#filters-selected-container > .filter-selected-doc > div:contains("Con Negocio")'
        );
        div.parent().remove();
      } else if ($(this).hasClass("without-business-checkbox")) {
        var div = $(
          '#filters-selected-container > .filter-selected-doc > div:contains("Sin Negocio")'
        );
        div.parent().remove();
      }
    }

    const {
      executives_checkboxs,
      companies_checkboxs,
      typeofdoc_checkboxs,
      with_business_checkbox,
      without_business_checkbox,
      query,
      direction
    } = getCurrentCheckedFilters();

    only_one_checkbox_active(with_business_checkbox, without_business_checkbox);

    //Checked checkboxs
    var checked_executives_checkboxs =
      return_checked_checkboxs(executives_checkboxs);
    var checked_companies_checkboxs =
      return_checked_checkboxs(companies_checkboxs);
    var checked_typeofdoc_checkboxs =
      return_checked_checkboxs(typeofdoc_checkboxs);

    // build contenido para el append
    var not_added_content_exec = check_not_added_content(
      checked_executives_checkboxs,
      "Ejecutivos"
    );
    var checked_executives_checkboxs_content = get_append_content(
      checked_executives_checkboxs,
      not_added_content_exec,
      "Ejecutivos"
    );

    var not_added_content_companies = check_not_added_content(
      checked_companies_checkboxs,
      "Clientes"
    );
    var checked_companies_checkboxs_content = get_append_content(
      checked_companies_checkboxs,
      not_added_content_companies,
      "Clientes"
    );

    var not_added_content_typeofdoc = check_not_added_content(
      checked_typeofdoc_checkboxs,
      "Tipos de Documentos"
    );
    var checked_typeofdoc_checkboxs_content = get_append_content(
      checked_typeofdoc_checkboxs,
      not_added_content_typeofdoc,
      "Tipos de Documentos"
    );

    var check_with_business_div = $(
      '#filters-selected-container > .filter-selected-doc > div:contains("Con Negocio")'
    );
    var check_without_business_div = $(
      '#filters-selected-container > .filter-selected-doc > div:contains("Sin Negocio")'
    );

    // cuando no se han agregado items al los filtros seleccionados, agregamos todo el body al filtro
    $("#filters-selected-container").append(
      with_business_checkbox.is(":checked") === true &&
        check_with_business_div.length == 0
        ? '<div class="filter-selected-doc ml-3"> ' +
            "<div>Con Negocio</div>" +
            '<button class="close" type="button" data-selected="Con Negocio"> <img class="cancel-btn" src="/assets/icons/icon_cancel-0e3424b5cd4edd5c2e8d5bc727eb07a72c4c5bb5ac34416c90ca3c4df983bc9b.svg"> </button>' +
            "</div>"
        : without_business_checkbox.is(":checked") === true &&
          check_without_business_div.length == 0
        ? '<div class="filter-selected-doc ml-3"> ' +
          "<div>Sin Negocio</div>" +
          '<button class="close" type="button" data-selected="Sin Negocio"> <img class="cancel-btn" src="/assets/icons/icon_cancel-0e3424b5cd4edd5c2e8d5bc727eb07a72c4c5bb5ac34416c90ca3c4df983bc9b.svg"> </button>' +
          "</div>"
        : checked_executives_checkboxs_content[1] === "all content"
        ? checked_executives_checkboxs_content[0]
        : checked_companies_checkboxs_content[1] === "all content"
        ? checked_companies_checkboxs_content[0]
        : checked_typeofdoc_checkboxs_content[1] === "all content"
        ? checked_typeofdoc_checkboxs_content[0]
        : ""
    );
    // Si es q ya hay checkbox selecionados agregamos el contenido que queda
    if (checked_executives_checkboxs_content[1] === "some content") {
      $(
        '#filters-selected-container > .filter-selected-doc > div:contains("Ejecutivos") > div'
      ).append(checked_executives_checkboxs_content[0]);
    } else if (checked_companies_checkboxs_content[1] === "some content") {
      $(
        '#filters-selected-container > .filter-selected-doc > div:contains("Clientes") > div'
      ).append(checked_companies_checkboxs_content[0]);
    } else if (checked_typeofdoc_checkboxs_content[1] === "some content") {
      $(
        '#filters-selected-container > .filter-selected-doc > div:contains("Tipos de Documentos") > div'
      ).append(checked_typeofdoc_checkboxs_content[0]);
    }

    getDocumentsFromApi();
  });

  function filter_combobox(input, elements) {
    elements.each(function (index, elem) {
      if (
        elem.innerHTML.toLowerCase().indexOf(input.val().toLowerCase()) >= 0
      ) {
        if ($(elem).closest("li").css("display") === "none") {
          $(elem).closest("li").css("display", "");
        }
      } else {
        $(elem).closest("li").css("display", "none");
      }
    });
  }

  $(".search-input").on("keypress", function (e) {
    var input = $("#" + this.id);
    // Si es que la tecla presionada es enter
    console.log("Key press");
    if (e.keyCode == 13) {
      console.log("Enter press!");

      console.log("ID:");
      console.log(input.id);
      if (input.attr("id") == "companies-search-documents") {
        var elements = $(".client-val");
        filter_combobox(input, elements);
        return false; // prevent the button click from happening
      } else if (input.attr("id") == "executive-search-documents") {
        var elements = $(".executive-val");
        filter_combobox(input, elements);
        return false; // prevent the button click from happening
      }
    }
  });

  $("#credit-notes-link").on("click", function (e) {
    let url = $(this).data("url");
    if (url != undefined) {
      $.ajax({ url: url, dataType: "script", method: "GET" });
    }
  });

  $("body").on("click", "#credit-notes-link", function () {
    $(this).find(".icon-accordion-right").toggleClass("down");
  });

  $("body").on("click", ".sort", function () {
    getDocumentsFromApi($(this).data("sort"));
  });

  if($('#select-client, select-debtor-client').length > 0){
    $('#select-client, select-debtor-client').val('')
    $('#select-client, select-debtor-client').select2({
      ajax: {
        url: '/charge_companies',
        dataType: 'json',
        method: 'GET',
        cache: false,
        delay: 400,
        data: function(params){
          var name = {name: params.term}
          return name
        },
        processResults: function (response) {
          if(response != 'null'){
            return {
              results: response
            };
          }
        }
      },
      language: 'es',
    });
  }
}

function setSiiDocumentsScrollPagination() {
  $("#scroll-deal").on("scroll", function () {
    if (
      $(this).scrollTop() + $(this).height() >=
      parseInt($("#scroll-deal .table").height())
    ) {
      let url = $("#paginate-documents-sii .pagination .next a").attr("href");
      if (url != undefined) {
        $.ajax({ url: url, dataType: "script", method: "GET" });
      }
    }
  });
}
