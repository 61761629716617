import consumer from "./consumer"

function errors(errors){
  const body = createTable(JSON.parse(errors))
  $modals.error({body: body})
  $('.modal-dialog').addClass('modal-lg')
  $('.modal-dialog').addClass('modal-dialog-scrollable')
  $('.modal-dialog').find('table').addClass('w-100')
}

function createTable(data) {
  const table = document.createElement("table");
  const tableHead = document.createElement("thead");
  const tableBody = document.createElement("tbody");

  const headRow = tableHead.insertRow();
  headRow.insertCell().textContent = "Fila";
  headRow.insertCell().textContent = "Nº Doc.";
  headRow.insertCell().textContent = "Error";

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const item = data[key];
      const row = table.insertRow();
      const indexCell = row.insertCell(0);
      const documentNumberCell = row.insertCell(1);
      const messagesCell = row.insertCell(2);

      indexCell.textContent = key;
      documentNumberCell.textContent = item.document_number;
      if (item.messages && Array.isArray(item.messages)) {
          messagesCell.textContent = item.messages.join(", ");
      }
    }
  }

  table.appendChild(tableHead);
  table.appendChild(tableBody);

  return table;
}

consumer.subscriptions.create("ExcessesChannel", {
  received(data) {
    if(data.action === "import_excess_xlsx") errors(data.errors)
  }
});