
$( document ).on('turbolinks:load', common_events)

function common_events() {
    const formulario = document.getElementById('form-bank');
    const inputs = document.querySelectorAll('#form-bank input');
    const expretions = {
        name: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras, numeros, guion y guion_bajo
        bank: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // Letras y espacios, pueden llevar acentos.
        bankType: /^[a-zA-ZÀ-ÿ\s]{1,40}$/, // 4 a 12 digitos.
        rut: /^[0-9_.+-]{4,60}$/, // 4 a 60 numeros, guiones y puntos.
        numberAccount: /^[0-9_.+-]{4,60}$/ // 4 a 60 numeros, guiones y puntos.
    }

    const campos = {
        name: false,
        bank: false,
        bankType: false,
        rut: false,
        numberAccount: false
    }

    const validateForm = function(e){
        switch (e.target.id) {
            case "bank-input":
                validarCampo(expretions.bank, e.target, 'bank-input');
            break;
            case "owner-input":
                validarCampo(expretions.name, e.target, 'owner-input');
            break;
            case "owner-rut-input":
                validarCampo(expretions.rut, e.target, 'owner-rut-input');
            break;
            case "type-input":
                validarCampo(expretions.bankType, e.target, 'type-input');
            break;
            case "account-number-input":
                validarCampo(expretions.numberAccount, e.target, 'account-number-input');
            break;
        }
    }

    const validarCampo = (expretion, input, campo) => {
        if(expretion.test(input.value)){
            $('#group-'+ campo).removeClass('form_group-incorrecto');
            $('#group-'+ campo).addClass('form_group-correcto');
            $('#group-'+ campo + ' .form_input-error').removeClass('form_input-error-activo');
            $('#submit-banks').removeClass('disabled');
            campos[campo] = true;
        } else {
            $('#group-'+ campo).addClass('form_group-incorrecto');
            $('#group-'+ campo).removeClass('form_group-correcto');
            $('#group-'+ campo + ' .form_input-error').addClass('form_input-error-activo');
            $('#submit-banks').addClass('disabled');
            campos[campo] = false;
        }
    }

    inputs.forEach(function (input){
        $(input).on('keyup', validateForm)
        $(input).on('blur', validateForm)
    })

    if (formulario != null){

        formulario.addEventListener('submit', (e) => {
            e.preventDefault();
            if(campos.bank && campos.name && campos.rut && campos.bankType && campos.numberAccount ){
                $('#submit-banks').removeClass('disabled');
            } else {
                $('#submit-banks').addClass('disabled');
            }
        });
    }
}




