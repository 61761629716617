$( document ).on('turbolinks:load', common_events)

function common_events(){
    function filePreview(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                $('#image-avatar').html('<img src="'+e.target.result+'" width="186" height="186" class="profile-image" />');
            }
            reader.readAsDataURL(input.files[0]);
        }
    }

    $("#user_profile_picture").on('change', function () {
        filePreview(this);
    });

    $('body').on('shown.bs.modal', '#modal-user', function() {
        $('.js-basic-single').select2({
            dropdownParent: $('#modal-user')
        });
    });

    $('#user_phone').mask('(+000) 000000000');
}