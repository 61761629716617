const alertText = `<div class="application-alert alert alert-dismissible fade d-flex align-items-center justify-content-between" role="alert">
    <div class="alert-loader mr-4 ml-2 d-none">
      <div class="d-flex justify-content-center align-items-center">
        <div class="spinner-border position-fixed" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <strong class="alert-seconds position-fixed">1</strong>
      </div>
    </div>
    <strong class="title d-none mr-2"></strong>
    <span class="message"></span>
    <button type="button" data-dismiss="alert" class="btn d-none open-url-button">
      IR
    </button>
    <button type="button" class="close ml-4" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>`

const createAlertObject = (alertObject, type) => {
  const {
    title,
    message,
    url,
    id
  } = alertObject;

  const $alert = $($.parseHTML(alertText));

  if (id) $alert.attr('id', id)
  $alert.addClass(`alert-${type}`);

  if (title) $alert.find('.title').text(`${title}:`).removeClass('d-none')

  if (url) {
    $alert.find('.open-url-button')
    .removeClass('d-none')
    .on('click', function() {
      window.open(url)
    })
  }

  $alert.find('.message').text(message)

  return $alert;
};

const createRowContainer = () => $($.parseHTML('<div class="row justify-content-end"></div>'));

const addAlertListeners = ($alert, $row, milliseconds, showTime) => {
  let interval = null;

  if (showTime) {
    $alert.find('.alert-loader').removeClass('d-none')
    $alert.addClass('alert-with-timer')
    interval = setInterval(function () {
      const currentSeconds = parseInt($alert.find('.alert-seconds').text())
      $alert.find('.alert-seconds').text(currentSeconds + 1)
    }, 1000);
  }

  $alert.on('closed.bs.alert', function() {
    $row.remove()
    if (interval) clearInterval(interval)
  })

  if (milliseconds) {
    setTimeout(() => {
      $row.remove()
    }, milliseconds)
  }
}

const appendRowAndAlert = ($row, $alert) => {
  $row.append($alert)
  $('#app-alerts-container').append($row)
  $alert.addClass('show')
}

const showAlert = (alertObject, millisecondsToClose, type = 'info', showTime) => {
  const $row = createRowContainer();
  const $alert = createAlertObject(alertObject, type)
  addAlertListeners($alert, $row, millisecondsToClose, showTime);
  appendRowAndAlert($row, $alert)
}

module.exports = {
  error: (message = '', milliseconds = 5000, showTime = false) => showAlert(message, milliseconds, 'danger', showTime),
  danger: (message = '', milliseconds = 5000, showTime = false) => showAlert(message, milliseconds, 'danger', showTime),
  success: (message = '', milliseconds = 5000, showTime = false) => showAlert(message, milliseconds, 'success', showTime),
  info: (message = '', milliseconds = 5000, showTime = false) => showAlert(message, milliseconds, 'info', showTime),
  warning: (message = '', milliseconds = 5000, showTime = false) => showAlert(message, milliseconds, 'warning', showTime),
}