export default function ({ name, rut, power_judicial, include_dte, message, pending }) {
  const $powerJudicialsModal = $('#power-judicials-modal')
  const $tabTabs = $powerJudicialsModal.find('#power-judicials-modal-tabs')
  const $tabContent = $powerJudicialsModal.find('#power-judicials-modal-tabs-content')

  $tabContent.html('')
  $tabTabs.html('')

  if (rut) $powerJudicialsModal.find('#rut').val(rut)
  if (name) $powerJudicialsModal.find('#name-input').val(name)
  if (include_dte) {
    $powerJudicialsModal.find('#include-dte-container').find('#include_dte').prop('checked', true);
    $powerJudicialsModal.find('#include-dte-container').find('label').addClass('active')
  }

  if (pending) {
    $('#power-judicials-modal-loader').removeClass('d-none')
  } else {
    $('#power-judicials-modal-loader').addClass('d-none')
  }

  if (message) {
    $('#power-judicials-modal-message').removeClass('d-none')
    $('#power-judicials-modal-message').find('.message').text(message)
  } else if (power_judicial) {
    Object.keys(power_judicial)
      .filter((key) => key !== 'Totales')
      .forEach((key) => {
      let label = key === 'Civil' ? 'Civiles' : key === 'Cobranza' ? 'Cobranza Laboral' : key;
  
      const $tabPane = $('<div></div>')
        .addClass(`tab-pane fade ${ key === 'Civil' ? 'active show' : ''}` )
        .attr('id', `tab-${key}`)
        .attr('role', 'tabpanel')
        .attr('aria-labelledby', `tab-${key}-tab`)
      const $boxTable = $('<div></div>').addClass('box-table box-table-power-judicial')
      const $cardHeader = $('<div></div>').addClass('card-header').html(`<h2>${label}</h2>`)
      const $tableResponsive = $('<div></div>').addClass('table-responsive')
      const $table = $('<table></table>').addClass('table table-striped table-directory table-directory-companies')
      const $tHead = $('<thead></thead>')
      $tHead.html(`
        <tr>
            <th></th>
            <th>Ref</th>
            <th>
            <span>Fecha</span>
            </th>
            <th>Caratulado</th>
            <th class="text-center">Tribunal</th>
        </tr>
      `)
      const $tBody = $('<tbody></tbody>')
  
      power_judicial[key].forEach((power) => {
        $tBody.append( `
          <tr>
          <td class="text-center">
            <div class="btn btn-link show-details-power" data-view="power_judicials_modal" data-target="#data-details-${key.toLocaleLowerCase()}"  data-name="Detalle Causa ${key}" data-details='${JSON.stringify(power["details"])}'>
                <img src = "/assets/icons/icon_search.svg" alt="Icon search"/>
            </div>
          </td>
          <td>${power['rit'] || power['rol']}</td>
          <td>${power['fecha ingreso'] || power["fecha"]}</td>
          <td>${power['caratulado']}</td>
          <td class="text-center">${power['tribunal']}</td>
          </tr>
        `)
      })
  
  
      $tabContent.append($tabPane)
      $tabPane.append($boxTable)
      $tabPane.append(`<div id="data-details-${key.toLocaleLowerCase()}"></div>`)
      $boxTable.append($cardHeader)
  
      if (power_judicial[key].length > 0) {
        $boxTable.append($tableResponsive)
        $tableResponsive.append($table)
        $table.append($tHead)
        $table.append($tBody)
      } else {
        $boxTable.append(`
            <div class="d-flex justify-content-center align-items-center rounded bg-white m-2 p-3 font-weight-bold">
              No hay registros
            </div>
        `)
      }
  
      // Button tab
  
      $tabTabs.append(`
        <button class="btn-link ${key === 'Civil' ? 'active' : '' }" id="v-pills-${key}-tab" data-toggle="pill" data-target="#tab-${key}" type="button" role="tab" aria-controls="v-pills-${key}" aria-selected="true">
            ${label}
            <span></span>
        </button>
      `)
    })
    $('#power-judicials-modal').find('#power-judicials-table').removeClass('d-none')
  }
}