import consumer from "./consumer"

function finishimportOperations(filename, url, message){
  $.ajax({
    url: url,
    dataType: 'script',
    method: 'GET',
    data: {
      filename: filename,
      url: url,
      message: message
    }
  })
}

function initImportOperations(filename){
  $alerts.info({ message: 'Estamos procesando el archivo, te informaremos cuando lo tengamos listo ' + filename }, 3000)
  $('.loader').fadeOut();
  $('#file_operations').val('');
}

consumer.subscriptions.create("OperationsChannel", {
  received(data) {
    if (data.action === 'finish_import_operation_volumen') finishimportOperations(data.filename, data.url, data.message)
    if (data.action === 'init_import_operation_volumen') initImportOperations(data.filename)
  }
});
