$( document ).on('turbolinks:load', events)

function events() {
  $('body').on('click', '#export-excesses', function() {
    console.log('export-excesses')
    const $filterForm = $('#excesses-filter')
    const $formatInput = $filterForm.find('#format')

    $filterForm.attr('data-remote', 'false')
    $formatInput.val('xlsx').trigger('change')
    $filterForm.find(':submit').trigger('click')
    $filterForm.attr('data-remote', 'true')
    $formatInput.val('js')
  })

  $('body').on('submit', '#excesses-filter', function() {
    const format = $(this).find('#format').val()
    const scrollLoading = $('#excesses-table').attr('scroll-loading')
    if (format == 'xlsx' || scrollLoading == 'true') return
    $('#no-excesses-message').find('span').addClass('d-none')
    $('#no-excesses-message').find('div').removeClass('d-none')
    $('#excesses-table').attr('loading', true)
  })

  $('body').on('change', '#excesses-filter', function(e) {
    const isMobile = $('#toggle-sidebar-mobile').css('display') === 'block'
    if (!isMobile) return
    $(this).find(':submit').trigger('click')
  })
}