
$( document ).on('turbolinks:load', common_events)
$(document).on("turbolinks:before-cache", function() {
  $('.js-basic-single').select2('destroy')
});

const getNotificationSound = () => {
  try {
    let audio = new Audio('https://dl.sndup.net/x6c6/pop_up.mp3')
    return audio;
  } catch (error) {
    console.warn('Notification Sound: ', error)
  }
}

function common_events(){
  let notificationSound = getNotificationSound();

  global.playNotificationSound = () => {
    if (notificationSound) {
      notificationSound.play();
    }
  }

  // pagination
  $(window).on('scroll', function(){
    if ($(window).scrollTop() + $(window).height() === $(document).height()){
      let url = $('.paginate-notification .pagination .next a').attr('href');

      if(url){
        $.ajax({
          type: "GET",
          url: url,
          dataType: "script",
          success: function () {
            $('.loader').fadeOut(500);
          },
        });
      }
    }
  })

  $('.notification-back-btn').on('click', function (){
    history.back()
  })
}