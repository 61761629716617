import customer from './consumer';

const updateTranferStatus = (data) => {
  const {
    document_number,
    status,
    feasibility_dte_load
  } = data;

  $(`.document-status-${ document_number }`).html(`<div class="popover-on-hover" data-content="${ status }" data-placement="left">
    ${ status.length > 20 ? status.substring(0,17) + '...' : status }
  </div>`);

  $(`#last-feasibility-load-${ document_number }`).text(feasibility_dte_load)
}

customer.subscriptions.create("DocumentsChannel", {
  received(data) {
    const { action } = data;

    if (action === 'update_transfer_status') updateTranferStatus(data)
  }
})