import customer from './consumer';
import camelcaseKeysDeep from 'camelcase-keys-deep';

const showNotificationAlert = (data) => {
  const {
    mutedNotifications,
    notification: {
      id,
      message,
      status,
      notificationType,
      linkPath
    }
  } = data;

  const showNotification = $alerts[notificationType]

  if (showNotification) {
    showNotification({
      message,
      url: linkPath ? `/notifications/${id}` : null,
      close: true,
    })

    if (!mutedNotifications) {
      playNotificationSound()
    }
  }
};

const getNewNotifications = () => {
  $.ajax({
    type: "GET",
    url: '/notifications',
    dataType: "script"
  });
}

customer.subscriptions.create("NotificationsChannel", {
  received(data) {
    const camelizedData = camelcaseKeysDeep(data);

    showNotificationAlert(camelizedData)
    getNewNotifications()
  }
})