import customer from './consumer';


customer.subscriptions.create("DebtorsChannel", {
  received(data) {
    if (data.action === 'update_cross_sales') {
      $alerts[data.status]({ title: data.title, message: data.message })
      if (data.status === 'success' && $('#cross-sell-tab').hasClass('active')) {
        $('#cross-sell-tab').trigger('click')
      }
      $('#update-cross-sales-button').removeAttr('disabled')
    }
  }
})