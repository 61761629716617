$(document).on("turbolinks:load", common_events);

function common_events() {
  $("body").on("change", "#button-add-excess", function () {
    let files = this.files;
    let url = $(this).data("url");
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      var name = element.name.split(".")[0];
      var extension = getFileExtension3(element.name);
      if (extension === "xlsx" || extension === "xls") {
        $alerts.info({ message: 'Estamos procesando el archivo, te notificaremos cuando esté listo.' }, 3000)
        readPortfolio(element, name, url);
      } else {
        alert("El archivo no es un archivo de lectura válido.");
      }
    }
    $(this).val("");
  });

  var XLSX = require("xlsx/xlsx.js");
  const moment = require("moment/moment.js");

  function readPortfolio(e, nameFile, url) {
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      var workbook = XLSX.read(data, { type: "binary" });
      let excess = [];

      workbook.SheetNames.forEach(function (sheetName) {
        // Here is my object
        var XL_row_object = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheetName],
          { header: 1, range: 4 }
        );
        var count = 0;
        for (let i = 0; i < XL_row_object.length; i++) {
          const element = XL_row_object[i];
          count += 1;
          // Separate of data
          if (!isNaN(element[4])) {
            let excess_item = {
              name_file: nameFile,
              rut: element[0],
              name: element[1],
              debtor_rut: element[2],
              debtor_name: element[3],
              number_excess: element[4],
              issued_at: element[5] ? moment(ExcelDateToJSDate(element[5])).format(
                "DD-MM-YYYY"
              ) : "",
              latest_transaction_date: element[6] ? moment(ExcelDateToJSDate(element[6])).format(
                "DD-MM-YYYY"
              ) : "",
              number_operation: element[7],
              origin: element[8],
              document_type: element[9],
              document_number: element[10],
              amount_excess: element[11],
              surplus_excess: element[12],
              sales_executive: element[13],
              collection_executive: element[14],
              branch: element[15],
              zone: element[16],
              status: element[17]
            };
            excess.push(excess_item);
          }
        }
        sendParamsToBD(excess, url);
        // getPortfolio(nameFile);
      });
    };
    reader.onerror = function (ex) {
      $alerts.info({message: ex})
    };

    reader.readAsBinaryString(e);
  }

  function getFileExtension3(filename) {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  }

  function ExcelDateToJSDate(serial) {
    var utc_days = Math.floor(serial - 25567);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);
    var fractional_day = serial - Math.floor(serial) + 0.0000001;
    var total_seconds = Math.floor(86400 * fractional_day);
    var seconds = total_seconds % 60;
    total_seconds -= seconds;
    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;
    return new Date(
      Date.UTC(
        date_info.getFullYear(),
        date_info.getMonth(),
        date_info.getDate(),
        hours,
        minutes,
        seconds
      )
    );
  }

  function sendParamsToBD(excess, url) {
    $.ajax({
      type: "POST",
      url: url,
      dataType: "json",
      contentType: "application/json",
      data: JSON.stringify({
        excess: excess,
      }),
    });
  }

  function rutParse(rut) {
    let newRut = rut.replace(/\./g, "").replace(/\-/g, "").trim().toLowerCase();
    let lastDigit = rut.slice(-1);
    let numberRut = rut.slice(0, newRut.length - 1);
    let rutFormat = numberRut.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return (rut = rutFormat + "-" + lastDigit);
  }
}