$( document ).on('turbolinks:load', common_events)

function common_events(){
  $('body').on('click', '.retention-radio', function() {
    const documentId = $(this).data('document-id');
    const $retentionModal = $('#retention-comment-modal')
    const $checkBox = $(this).find('input[type=checkbox]')
    const $label = $(this).find('label')
    const checked = $checkBox.prop('checked')
    const comment = $(`#retention_comment_${ documentId }`).val()

    if (checked) {
      $retentionModal.data('document-id', documentId)
      $retentionModal.modal('show')
      return
    }

    if (comment && confirm('Este documento tiene comentarios, al cambiar el estado se perderán')) {
      $retentionModal.data('document-id', null)
      $(`#retention_comment_${ documentId }`).val('')
      $(`#has_retention_${ documentId }`).val(false)
      $(`#show-comment-${ documentId }`).addClass('d-none')
      return
    }

    $checkBox.prop('checked', true)
    $checkBox.val("true")
    $label.addClass('active')
  })

  $('body').on('click', '#retention-comment-modal-accept', function(e) {
    const documentId = $('#retention-comment-modal').data('document-id');
    const comment = $('#retention-comment-textarea').val()

    if (!comment) {
      alert('Debe ingresar un comentario!')
      return
    }

    $(`#retention_comment_${ documentId }`).val(comment)
    $(`#has_retention_${ documentId }`).val(true)
    $(`#show-comment-${ documentId }`).removeClass('d-none')
    $('#retention-comment-modal').modal('hide')
    $('#retention-comment-textarea').val('')
  })

  $('body').on('click', '#retention-comment-modal-cancel', function() {
    const documentId = $('#retention-comment-modal').data('document-id');
    const $radio = $(`#retention-radio-${ documentId }`)
    const $checkBox = $radio.find('input')
    const $label = $radio.find('label')
    const comment = $(`#retention_comment_${ documentId }`).val();

    if (comment) return

    $checkBox.prop('checked', false)
    $checkBox.val("false")
    $label.removeClass('active')
  })

  $('body').on('click', '.show-comment-button', function() {
    const documentId = $(this).data('document-id');
    const comment = $(`#retention_comment_${ documentId }`).val();
    $('#retention-comment-modal').data('document-id', documentId);
    $('#retention-comment-modal').find('textarea').val(comment)
    $('#retention-comment-modal').modal('show')
  })

  $('#retention-comment-modal').on('hidden.bs.modal', function() {
    const documentId = $('#retention-comment-modal').data('document-id');
    $('#retention-comment-modal').data('document-id', undefined)
    $(this).find('textarea').val('')
  })
}