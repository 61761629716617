
$( document ).on('turbolinks:load', common_events)
function common_events(){

    $('body').on('keyup', 'input[name="sii_credential[password]"], input[name="sii_credential[electronic_signature_password]"]', function(){
        if ($(this).attr("name") == "sii_credential[electronic_signature_password]" && $('input[name="sii_credential[electronic_signature_password]"]').val().length > 0 && $('input[name="sii_credential[electronic_signature]"]').val().length > 0 ){
            $('input[type="submit"]').removeClass('disabled');
        }else if ($('input[name="sii_credential[password]"]').val() != undefined && $('input[name="sii_credential[password]"]').val().length > 0 ){
            $('input[type="submit"]').removeClass('disabled');
        }else{
            $('input[type="submit"]').addClass('disabled');
        }
    })

    $("body").on('change', 'input[name="sii_credential[electronic_signature]"]', function(){
        if($(this).val().length > 0 && $('input[name="sii_credential[electronic_signature_password]"]').val().length > 0){
            $('#buttons-modal-sii').find('input[type="submit"]').removeClass('disabled');
        }else{
            $('#buttons-modal-sii').find('input[type="submit"]').addClass('disabled');
        }

    });

}