const modalText = `<div class="modal fade modal-client" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content rounded-md">
        <div class="h-80px justify-content-end border-0 margin-0 p-2 px-4">
          <button type="button" class="close btn btn-primary" data-dismiss="modal" aria-label="Close">
            <span class="icon-close" aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="d-flex justify-content-center modal-icon mt-4 mb-4">
        </div>
        <div class="px-4">
          <h3 class="title text-center text-primary my-3 d-none"></h3>
        </div>
        
        <div class="modal-body text-center message">
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="app-modal-close-btn btn btn-secondary rounded-sm d-none" data-dismiss="modal">Cerrar</button>
          <button id="app-modal-accept-btn" type="button" class="btn btn-outline-primary rounded-sm" data-dismiss="modal">Volver</button>
        </div>
      </div>
    </div>
  </div>`

iconText = {
  error: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="icon-gen-new mb-4">
    <path d="M0 12C0 5.37337 5.37337 0 12 0C18.6306 0 24 5.37337 24 12C24 18.6266 18.6266 24 12 24C5.37337 24 0 18.6266 0 12Z" fill="#FF5C4E"></path>
    <path d="M13.3333 7.2C14.5115 7.2 15.4666 6.48366 15.4666 5.6C15.4666 4.71634 14.5115 4 13.3333 4C12.1551 4 11.2 4.71634 11.2 5.6C11.2 6.48366 12.1551 7.2 13.3333 7.2Z" fill="white"></path>
    <path d="M15.2593 15.9072C15.2028 15.8675 15.1394 15.84 15.0726 15.8263C15.0058 15.8126 14.9371 15.813 14.8705 15.8274C14.8038 15.8418 14.7406 15.87 14.6845 15.9103C14.6285 15.9505 14.5807 16.0021 14.5439 16.0619C14.05 16.8259 13.4449 17.5047 12.7505 18.0738C12.5819 18.2072 11.9534 18.7142 11.6877 18.6074C11.5037 18.5487 11.611 18.1698 11.6468 18.0097L11.9176 17.1719C12.03 16.8304 13.987 10.7682 14.2016 10.0744C14.5184 9.06052 14.3804 8.0626 12.9344 8.30274C12.541 8.34543 8.55036 8.88441 8.47883 8.88975C8.41173 8.8943 8.34614 8.91262 8.28582 8.94364C8.2255 8.97467 8.17161 9.0178 8.12725 9.07058C8.08289 9.12335 8.04891 9.18474 8.02726 9.25122C8.00561 9.31771 7.99672 9.388 8.00108 9.45808C8.00544 9.52816 8.02298 9.59666 8.05268 9.65966C8.08239 9.72266 8.12369 9.77893 8.17422 9.82527C8.22475 9.8716 8.28353 9.90708 8.34719 9.92969C8.41085 9.9523 8.47815 9.96159 8.54525 9.95704C8.54525 9.95704 10.0781 9.74892 10.2468 9.73291C10.3331 9.7241 10.4201 9.74158 10.4972 9.78325C10.5742 9.82491 10.6381 9.88898 10.6811 9.96771C10.7737 10.2666 10.761 10.5902 10.6453 10.8802C10.5125 11.4139 8.4124 17.5935 8.34598 17.951C8.27473 18.2502 8.29451 18.5652 8.40257 18.8521C8.51063 19.139 8.70159 19.3836 8.94891 19.552C9.41313 19.8751 9.96585 20.0307 10.5227 19.9949C11.0641 19.988 11.5995 19.8758 12.1015 19.664C13.3738 19.1304 14.7023 17.7109 15.4024 16.5956C15.4626 16.4823 15.4814 16.3499 15.455 16.2232C15.4287 16.0965 15.3591 15.9842 15.2593 15.9072Z" fill="white"></path>
  </svg>`,
  success: `<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="42" height="42" rx="50" fill="#28C76F" fill-opacity="0.12"/>
    <path d="M27.8505 15.127L17.7281 25.2494L14.1495 21.6708C13.9801 21.5014 13.7054 21.5014 13.536 21.6708L13.127 22.0798C12.9577 22.2492 12.9577 22.5238 13.127 22.6933L17.4214 26.9876C17.5908 27.157 17.8654 27.157 18.0349 26.9876L28.873 16.1495C29.0423 15.9801 29.0423 15.7055 28.873 15.536L28.464 15.127C28.2946 14.9577 28.0199 14.9577 27.8505 15.127Z" fill="#2FBC6B"/>
  </svg>`,
  info: '',
  warning: '',
  loading: `<div class="d-flex justify-content-center align-items-center">
    <div class="spinner-border position-fixed" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <strong class="modal-timer position-fixed d-none">1</strong>
  </div>`
}

function showModal(modalObject = {}, type = 'info') {
  const {
    title,
    message,
    messagesArray,
    acceptText,
    onAccept,
    close,
    closeText,
    onClose,
    milliseconds,
    un_dismiss,
    request_id,
    reaload,
    timer,
    url,
    body,
    modalWidth
  } = modalObject;

  let interval = null
  const $modal = $(`#${request_id}`).length ? $(`#${request_id}`) : $($.parseHTML(modalText))

  if (body) {
    $modal.find('.modal-body').html(body)
  } else {
    if (message) $modal.find('.modal-body').html(`<p class="align-middle">${message}</p>`)
    if (messagesArray) {
      messagesArray.forEach(msg => {
        $modal.find('.modal-body').append(`<p class="align-middle">${msg}</p>`)
      })
    }
  }
  if (title) $modal.find('.title').removeClass('d-none').text(title);

  $acceptBtn = $modal.find('#app-modal-accept-btn');
  if (acceptText) $acceptBtn.text(acceptText)
  if (onAccept) $acceptBtn.on('click', onAccept)
  if (onClose) $modal.find('.app-modal-close-btn').removeClass('d-none').on('click', onClose)
  if (closeText) $modal.find('.app-modal-close-btn').removeClass('d-none').text(closeText)

  $modal.find('.modal-icon').html(iconText[type])

  if (modalWidth) {
    $modal.find('.modal-dialog').addClass(`modal-${modalWidth}`)
  }
  if (url) {
    $acceptBtn.text('IR')
    $acceptBtn.on('click', () => window.open(url))
    $modal.find('.app-modal-close-btn').removeClass('d-none')
  }

  if (un_dismiss) {
    $modal.data('backdrop', 'static')
    $modal.find('#app-modal-accept-btn').addClass('d-none')
    $modal.find('.app-modal-close-btn').addClass('d-none')
  }

  if (reaload) {
    $modal.find('#app-modal-accept-btn').text('Aceptar')
    $modal.find('#app-modal-accept-btn').removeClass('d-none').on('click', () => location.reload())
    $modal.find('.app-modal-close-btn').on('click', () => location.reload())
  }

  if (timer) {
    if (!window.intervals) {
      window.intervals = {}
    }
    if (!window.intervals[request_id]) {
      $modal.find('.modal-timer').removeClass('d-none')
      interval = setInterval(function () {
        const currentSeconds = parseInt($modal.find('.modal-timer').text())
        $modal.find('.modal-timer').text(currentSeconds + 1)
      }, 1000);
      window.intervals[request_id] = interval
    }
  } else if (window.intervals && window.intervals[request_id]) {
    window.clearInterval(window.intervals[request_id])
    delete window.intervals[request_id]
  }

  if (!$modal.attr('id')) {
    $modal.attr('id', request_id)
    $('#app-modals-container').append($modal)

    $modal.modal('show')

    if (milliseconds) {
      setTimeout(() => {
        $modal.find('.app-modal-close-btn').trigger('click')
      }, milliseconds)
    }
  }

  $modal.on('hidden.bs.modal', function (e) {
    $(this).remove()
    if (interval) clearInterval(interval)
  })
}

module.exports = {
  error: (modalObject) => showModal(modalObject, 'error'),
  success: (modalObject) => showModal(modalObject, 'success'),
  info: (modalObject) => showModal(modalObject, 'info'),
  warning: (modalObject) => showModal(modalObject, 'warning'),
  loading: (modalObject) => showModal(modalObject, 'loading'),
}