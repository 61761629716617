module.exports = function(input, idContainer, className = '') {
  console.log(input.files)
  if (input.files && input.files[0]) {
    // $(idContainer).html('')
    function onLoadRender(e) {
      $(idContainer).append(`<img src="${e.target.result}" max-height="186" class="${className}" />`);
    }

    Object.values(input.files).forEach(file => {
      var reader = new FileReader();
      reader.onload = onLoadRender
      reader.readAsDataURL(file)
    })
  }
}