let sales_info_container_counter = 0;
$( document ).on('turbolinks:load', common_events)
function common_events(){

    $('body').on('change', '#switch-select', function() {
      $(this).parent().submit();
    });
    //ON LOAD
    let sales_content = $(".sales-info-container");
    if ($(window).width() < 992) {
      if ($(".sales-info-container").length){
          $(".sales-info-container").remove();
          sales_info_container_counter = 0;
      }
    }
    //ON RESIZE
    if (sales_content.length){
      window.addEventListener("resize", function (event) {
        if ($(window).width() < 992) {
          if ($(".sales-info-container").length){
              $(".sales-info-container").remove();    
              sales_info_container_counter = 0;
          } 
        }else{
              if ($(".sales-info-container").length){}
              else if (sales_info_container_counter == 0){
                  $(".login-page-container").prepend(sales_content[0].outerHTML);
                  sales_info_container_counter += 1;
              }
        }
      });
    }
}
