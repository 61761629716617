import customer from './consumer';
import renderPowerJudicialModal from '../packs/tools/power_judicials/render_power_judicials_modal';
import sendNotification from '../packs/utils/send_notification';
import updateLastPowerJudicialSessionStorage from '../packs/tools/power_judicials/update_last_power_judicial_session_storage';

const powerJudicialModal = (data) => {
  const { result: { message, power_judicial } } = data;
  const $powerJudicialsModal = $('#power-judicials-modal')
  const requestPowerJudicial = { power_judicial, message, not_seen: !$powerJudicialsModal.hasClass('show'), pending: false }

  if(!$powerJudicialsModal.hasClass('show')) {
    $('#open-power-judicials-modal-btn').find('.count-notifications').removeClass('d-none')
  }

  updateLastPowerJudicialSessionStorage(requestPowerJudicial)

  if ($powerJudicialsModal.hasClass('show')) {
    renderPowerJudicialModal(requestPowerJudicial)
  } else {
    sendNotification('Reporte judicial', 'solicitud lista')
  }
}

customer.subscriptions.create("PowerJudicialsChannel", {
  received(data) {
    if (data.action === 'power_judicials_modal') {
      powerJudicialModal(data)
    }

  }
})