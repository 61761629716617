const decamelize = require('decamelize-keys-deep');

function validateForm(showError) {
  let error = false;
  const $debtorRut = $('#deal_deals_documents_attributes_document_attributes_debtor_rut')
  const $companyName = $('#deal_deals_documents_attributes_document_attributes_debtor_name')
  const $totalAmount = $('#deal_deals_documents_attributes_document_attributes_total_amount')
  const $financingRate = $('#financing_rate')
  const $upfrontPercentage = $('#upfront_percentage_full')
  const $expireAt = $('#deal_deals_documents_attributes_document_attributes_expire_at')
  const $commissionInput = $('#deal_commission')

  if ($debtorRut.val()) {
    $debtorRut.removeClass('is-invalid')
    $debtorRut.siblings('.error').text('')
  } else {
    if (showError) {
      $debtorRut.addClass('is-invalid')
      $debtorRut.siblings('.error').text('Ingrese un rut')
    }
    error = true;
  }
  
  if ($companyName.val()) {
    $companyName.removeClass('is-invalid')
    $companyName.siblings('.error').text('')
  } else {
    if (showError) {
      $companyName.addClass('is-invalid')
      $companyName.siblings('.error').text('Ingrese un nombre')
    }
    error = true;
  }

  if ($totalAmount.val()) {
    $totalAmount.removeClass('is-invalid')
    $totalAmount.siblings('.error').text('')
  } else {
    if (showError) {
      $totalAmount.addClass('is-invalid')
      $totalAmount.siblings('.error').text('Ingrese un monto')
    }
    error = true;
  }

  if ($financingRate.val()) {
    $financingRate.removeClass('is-invalid')
    $financingRate.siblings('.error').text('')
  } else {
    if (showError) {
      $financingRate.addClass('is-invalid')
      $financingRate.siblings('.error').text('Ingrese una tasa de financiamiento')
    }
    error = true;
  }

  if ($upfrontPercentage.val()) {
    $upfrontPercentage.removeClass('is-invalid')
    $upfrontPercentage.siblings('.error').text('')
  } else {
    if (showError) {
      $upfrontPercentage.addClass('is-invalid')
      $upfrontPercentage.siblings('.error').text('Ingrese un porcentaje de anticipo')
    }
    error = true;
  }

  if ($commissionInput.val()) {
    $commissionInput.removeClass('is-invalid')
    $commissionInput.siblings('.error').text('')
  } else {
    if (showError) {
      $commissionInput.addClass('is-invalid')
      $commissionInput.siblings('.error').text('Ingrese un monto')
    }
    error = true;
  }
  
  if ($expireAt.val()) {
    $expireAt.removeClass('is-invalid')
    $expireAt.siblings('.error').text('')
  } else {
    if (showError) {
      $expireAt.addClass('is-invalid')
      $expireAt.siblings('.error').text('Ingrese una fecha')
    }
    error = true;
  }

  return !error;
}

module.exports = function () {
  $('body').on('click', '#partial-deal-send-email', function() {
    if (validateForm()) {
      $($(this).data('target')).modal('toggle')
    }
  })

  $('body').on('click', '#partial-deal-confirm-email', function() {
    const $debtorEmail = $('#debtor_email')
    if ($debtorEmail.val()) {
      $('.loader').fadeIn(0);
      $('#send-partial-deal-modal').modal('toggle')
      $.ajax({
        url: `${$(this).data("url")}?email=${$debtorEmail.val()}`,
        dataType: 'script',
        method: "GET",
        data: {company_id: $(this).val()}
      });
    } else {
      $debtorEmail.addClass('is-invalid')
      $debtorEmail.siblings('.error').text('Ingrese un email')
    }
  })

  $('body').on('change', '.partial-deal-input', function() {
    if(validateForm()) {
      $('#save-partial-deal-button').removeAttr('disabled')
    } else {
      $('#save-partial-deal-button').attr('disabled', 'disabled')
    }
  })

  $('body').on('click', '#input-amount-in-simulation', function(e) {
    const product = $(this).data('product')
    if (product !== 'leasing') {
        if ($('#numbers-of-documents').attr('action') != "/deals") {
            MultiplicationForDocument()
        }
    }
  })

  $('body').on('click', '#button-accept-cost-deal', function () {
    let amount_total = $('#partial-amount-cost-deal').text() || 0
    $('#partial-deal-costs-input').val(amount_total).trigger('input')
  })
}