import customer from "./consumer"

const bookOfPuchasesAndSales = (company_id) => {
  $.ajax({
    type: 'GET',
    dataType: "script",
    data: {
      company_id: company_id
    },  
    url: `/book_of_purchases_and_sales`,
  })
}

customer.subscriptions.create("FinancialBackgroundChannel", {
  received(data) {
    if (data.action === 'get_last_document_data_by_month_and_year') bookOfPuchasesAndSales(data.company_id)
  }
});
