import { breakpointUp, breakpointDown } from './utils/breakpoints';

$(document).on('turbolinks:load', () => {
  $('#toggle-menu').on('click', function () {
    $('#sidebar').toggleClass('active');
    $('#content').toggleClass('active');
    $('.top-nav').toggleClass('active');
    $('.bottom-nav').toggleClass('active');
    $('#help-link').toggleClass('ml-2 position-help');
  });

  $('.menu-sidebar').on('click', function() {
    $(this).toggleClass('active');
    $('#sidebar').toggleClass('d-block actives');
  })

  $(window).resize(function(){
    if(breakpointUp('xl')){
      $('#sidebar').removeClass('active d-block actives');
      $('.top-nav').removeClass('active');
      $('.bottom-nav').removeClass('active');
      $('#content').removeClass('active');
      $('#footer-session').removeClass('offset-5')
    }else{
      $('#sidebar').addClass('active');
      $('.top-nav').addClass('active');
      $('.bottom-nav').addClass('active');
      $('#content').addClass('active');
    }

    if (breakpointDown('md')) {
      $('#footer-session').removeClass('offset-5')
      $('#sidebar').removeClass('active');
      $('#slidebar').addClass('d-block actives')
    } else {
      $('#footer-session').addClass('offset-5')
      $('#sidebar').removeClass('d-block actives');
    }

    if (breakpointUp('md')) {
      $('.menu-sidebar').removeClass('active')
    }
  });

  $('body').on('click', '#toggle-sidebar, #toggle-sidebar-mobile', function() {
    const $sidebar = $('#client-sidebar')
    $sidebar.find('.collapse').collapse('hide')
    const currentStatus = $sidebar.attr('close') == 'true'
    $sidebar.attr('close', !currentStatus)
  })

  $('body').on('show.bs.modal', '#modal-sii', function(){
    const $sidebar = $('#client-sidebar')
    if (breakpointDown('md')) {
      const currentStatus = $sidebar.attr('close') == 'true'
      $sidebar.attr('close', !currentStatus)
    }
  })

  $('body').on('click', '.dropdown-toggle', function() {
    $(this).toggleClass('active')
  })

  $('body').on('click', '#app-content', function(e) {
    const isAppContent = $(e.target).attr('id') === 'app-content'
    const $sideBar = $('#client-sidebar')
    const sideBarIsClose = $sideBar.attr('close') == true
    const isMobile = $('#toggle-sidebar-mobile').css('display') === 'block'

    if (isMobile && !sideBarIsClose && isAppContent) {
      $sideBar.attr('close', true)
    }
  })
})