$( document ).on('turbolinks:load', common_events)

function common_events() {
  $('#big-payers-btn-add-new-document').on('click', () => {
    $('#big-payers-documents-list-container').toggleClass('d-none')
    $('#big-payers-upload-file-form-container').toggleClass('d-none')
    $('.input-archives').val('');
    $('.input-archives').val('');
    $('#upload_filename').html('')
    $('#upload_date').html('')
    $('#upload_username').html('')
    $('#big-payer-file-table').addClass('d-none')
  })

  $('#return-link').on('click', (e) => {
    if ($('#big-payers-documents-list-container').hasClass('d-none')) {
      e.preventDefault()
      $('#big-payers-documents-list-container').toggleClass('d-none')
      $('#big-payers-upload-file-form-container').toggleClass('d-none')
      $('.input-archives').val('');
      $('#big-payer-file-table').html('')
    }
  })

  $('#big-payers-file-input').on('change', (e) => {
    const target = $(e.currentTarget)
    const fileName = target.prop('files')[0].name
    const username = target.data('username')

    $('#upload_filename').html(fileName)
    $('#upload_date').html(new Date().toLocaleDateString().replaceAll('/','-'))
    $('#upload_username').html(username)
    $('#big-payer-file-table').removeClass('d-none')
  })
  
  $('body').on('click', '#big-payers-delete-file', () => {
    $('.input-archives').val('');
    $('#upload_filename').html('')
    $('#upload_date').html('')
    $('#upload_username').html('')
    $('#big-payer-file-table').addClass('d-none')
  })

  $('body').on('click', '.archive-preview-show-btn', () => {
    $('.loader').fadeIn();
  })

  $('body').on('click', '#archive-preview-download-btn', (e) => {
    e.preventDefault();
    const url = $(e.currentTarget).data('url')
    window.open(url)
  })
}