$( document ).on('turbolinks:load', common_events)

function common_events(){

    function getQuerySearch() {
        const search_box_clients = $('#search-box-clients, #search-box-users')
        const search_box_clients_add_client = $('#search-box-clients-add-client')
        console.log(search_box_clients.val() || search_box_clients_add_client.val())
        return {
            query: search_box_clients.val() || search_box_clients_add_client.val()
        }
    }

    $('body').on('keydown', '.input-only-number', function(e) {
        return valideKey(e);
    });

    function getQuerySearch() {
        const search_box_clients = $('#search-box-clients, #search-box-users')
        const search_box_clients_add_client = $('#search-box-clients-add-client')
        console.log(search_box_clients.val() || search_box_clients_add_client.val())
        return {
            query: search_box_clients.val() || search_box_clients_add_client.val()
        }
    }

    function valideKey(evt){
			
        // code is the decimal ASCII representation of the pressed key.
        var code = (evt.which) ? evt.which : evt.keyCode;
        if(code==8 || code==9) { // backspace.
            return true;
        } else if(code>=48 && code<=57) { // is a number.
            return true;
        } else if(code == 188 || code == 190) {
            return true;
        } else{ // other keys.
            return false;
        }
    }
    
    
    $('body').on('change', '.date-crm, #select_sales_executive_crm', function() {
        let date = $('#date').val()
        let sales_executive = $('#select_sales_executive_crm').val();
        $('.loader').fadeIn(100);
        $.ajax({
            url: "/crms_today",
            dataType: 'script',
            method: "GET",
            data: {
                date:date,
                sales_executive: sales_executive
            }
        });
    })

    $('body').on('ajax:beforeSend', '.card-crms-new, .beforeSendLoader', function(){
        $('.loader').fadeIn(100);
    });
    $('body').on('click', '.beforeSendLoader', function(){
        $('.loader').fadeIn(100);
    });

    $('body').on('click', '.search-company-crm', function(){
        let data = getQuerySearch();

        $.ajax({
            url: $(this).data("url"),
            dataType: 'script',
            method: "GET",
            data: data
        });
    });
}
