function rutFormat(element){
      
  let rut = element.val();
  const newRut = rut.replace(/\./g,'').replace(/\-/g, '').trim();
  let rutDigits = newRut
  let format = '';

  let last3digits= rutDigits.slice(-3)
  while (last3digits.length === 3){
     format = "." + last3digits + format;
     rutDigits = rutDigits.slice(0,-3);
     last3digits= rutDigits.slice(-3)
  }
  format = rutDigits + format;

  element.val(format);
}

module.exports = rutFormat;