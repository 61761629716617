import customer from './consumer';

function showCurrentStatus(data) {
  const {
    message,
    title,
    type,
    timer,
    status,
    action
  } = data;

  $alerts[type]({ message, title, id: `${action}-${status}` }, 3000)

  if (timer) {
    $alerts[type]({ message: 'Procesando solicitud', title, id: `${action}-timer` }, null, true)
  }

  if (status === 'end') {
    $(`#${action}-timer`).alert('close')
  }
}

customer.subscriptions.create({ channel: 'AntmanChannel' }, {
  received(data) {
    const {
      action,
      category,
      status,
      company_id
    } = data;


    if (action === 'update_tgr_certificate') {
      if (status == 'start') {
        $('#update-tgr-certificate-button').attr('disabled', 'disabled')
      }

      if (status == 'end') {
        $('#update-tgr-certificate-button').removeAttr('disabled')
      }

      if (status === 'error') {
        showCurrentStatus(data)
      }

      if (category === 'request_status_update') {
        showCurrentStatus(data)
      }

      if (category === 'action_on_success') {
        $.ajax({
          type: 'GET',
          dataType: "script",
          url: `/companies/${ company_id }/external_reports/tgr_certificate`,
        })
      }
    }

  }
})