const formatDateTime = require('../../utils/format_date_time');

module.exports = (data) => {
  const {
    comment: {
      id,
      created_at,
      comment_type,
      commentable_id,
      message
    },
    user_image_url,
    user_name
  } = data;

  const parsedMessage = message.replaceAll(/</g, "&lt;").replaceAll(/>/g, "&gt;")

  $(`#box-coments-${comment_type}-${commentable_id}`).prepend(
    `<div id="comment-${ id }">
      <div class="box-content-comment-deal d-flex align-items-center">
        <div class="avatar-user">
          <img class="avatar" src="${ user_image_url || '/assets/icons/user-placeholder.svg'}">
        </div>
        <div class="name-user ml-3">
          <div class="">${ user_name }</div>
          <div class="date">${ formatDateTime(created_at) }</div>
        </div>
      </div>
      <div class="d-flex">
        <div class="content-comment-executive">
          <div class="message mb-4">
            ${ parsedMessage }
          </div>
        </div>
      </div>
    </div>`
  )

  $(`#deal-${commentable_id}-chat`).find('.messages').prepend(
    `<div class="message from-other">
      <div class="message-content">
        <p class="message-text">${ parsedMessage }</p>
        <div class="subtitle">
          <div class="image">
            <img src="${ user_image_url || '/assets/icons/user-placeholder.svg' }">
          </div>
          <div class="datetime">
            ${ formatDateTime(created_at) }
          </div>
        </div>
      </div>
    </div>`
  )
};