$( document ).on('turbolinks:load', events)

function events() {
  $('body').on('click', '#export-operations', function() {
    const $filterForm = $('#operations-filter')
    const $formatInput = $filterForm.find('#format')

    $filterForm.attr('data-remote', 'false')
    $formatInput.val('xlsx').trigger('change')
    $filterForm.attr('data-remote', 'true')
    $formatInput.val('js')
  })

  $('body').on('change', '.gps-resource-purchase-order-checkbox', function() {
    const gpsCheckedNumber = $('.gps-resource-purchase-order-checkbox:checked').length

    if (gpsCheckedNumber) {
      return $('#form-generate-gps-purchase-order').find('input:submit').removeAttr('disabled')
    }

    $('#form-generate-gps-purchase-order').find('input:submit').attr('disabled', true)
  })
}