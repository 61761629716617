import customer from './consumer';

function showCurrentStatus(data) {
  const {
    status,
    title,
    updated_companies,
    message_type,
    error
  } = data;

  if (message_type === 'modal') {
    $modals[status](data)
  }

  if (message_type == 'alert') {
    if (status === 'start') {
      $alerts.info({ message: 'Solicitud enviada a gestiona tu PYME', title }, 3000)
      $alerts.info({ message: 'Esperando respuesta de gestiona tu PYME', title }, null, true)
    }
    if (status === 'end') {
      $alerts.success({ message: 'Respuesta recibida desde gestiona tu PYME', title }, 3000)
      $('.alert-with-timer').alert('close')
    }
  
    if (status === 'updated') {
      const updatedCompaniesArray = JSON.parse(updated_companies)
      updatedCompaniesArray.forEach(company => {
        if (company.bulletin_status == 'yes') {
          $(`.id-${company.id}`).html(`<a class="open-bulletin-detail" data-rut="${company.rut}" data-url="/get_bulletin_status_body">Si</a>`);
        } else if (company.bulletin_status == 'no') {
          $(`.id-${company.id}`).html('No');
        }
      })
  
      $alerts.success({ message: 'Información actualizada', title })
    }
  }
}

customer.subscriptions.create("GestionaTuPymeChannel", {
  received(data) {
    if (data.action === 'status_update') showCurrentStatus(data)
  }
})