const formatNumber = require('./format_number');

function getStateFromInfocred(companyRut, tableId = '#infocred-table') {
  $infocredTable = $(tableId)
  $infocredTable.find('table').addClass('d-none')
  $infocredTable.find('.table-loader').removeClass('d-none').addClass('d-flex')
  $('#infocred-pagination-container').html('')
  $infocredTable.find('tbody').html('')

  $.ajax({
    type: "GET",
    url: `/infocred_online_debt?rut=${companyRut}`,
    dataType: "json",
    success: function (data){
      data.periodo.string.forEach((date, index) => {
        const dvigente = data.dvigente.string[index]
        const dmorosa = data.dmorosa.string[index]
        const dvencida = data.dvencida.string[index]
        const indirecta_vigente = data.indirecta_vigente.string[index]
        const indirecta_vencida = data.indirecta_vencida.string[index]
        const lcredito = data.lcredito.string[index]
        $infocredTable.find('tbody').append(`
          <tr>
            <td class="align-middle text-center">${date}</td>
            <td class="align-middle text-center">$${formatNumber(Number(dvigente) * 1000)}</td>
            <td class="align-middle text-center">$${formatNumber(Number(dmorosa) * 1000)}</td>
            <td class="align-middle text-center">$${formatNumber(Number(dvencida) * 1000)}</td>
            <td class="align-middle text-center">$${formatNumber(Number(indirecta_vigente) * 1000)}</td>
            <td class="align-middle text-center">$${formatNumber(Number(indirecta_vencida) * 1000)}</td>
            <td class="align-middle text-center">$${formatNumber(Number(lcredito) * 1000)}</td>
            <td class="align-middle text-center d-none">
              <div class="btn btn-icon p-0">
                <img src="/assets/icons/icon-eye.svg">
              </div>
            </td>
          </tr>
        `)
      })
      $infocredTable.find('.table').removeClass('d-none')
      $infocredTable.find('.table-loader').removeClass('d-flex').addClass('d-none')
    },
    error: function (data){
      console.error(data)
    }
  });
}

function getBusinessReportFromApi(rut) {
  const efaRadio = $('#efa-radio')
  const equifaxRadio = $('#equifax-radio')
  const infocredRadio = $('#infocred-radio')
  const sinacofiRadio = $('#sinacofi-radio')

  if (efaRadio.is(':checked')) {
    console.log('efaRadio')
  }

  if (equifaxRadio.is(':checked')) {
    console.log('equifaxRadio')
  }

  if (infocredRadio.is(':checked')) {
    getStateFromInfocred(rut)
  }

  if (sinacofiRadio.is(':checked')) {
    console.log('sinacofiRadio')
  }
}

module.exports = {
  getBusinessReportFromApi,
  getStateFromInfocred
}