const { getBusinessReportFromApi } = require('../utils/get_business_report');

$('body').on('click', '.client-business-radio-input', function(e) {
  const name = $(this).prop('name')
  const companyRut = $(this).data('company-rut')
  if (name === 'infocred') {
    getBusinessReportFromApi(companyRut)
  }
})

$('body').on('shown.bs.modal', '#modal-client-business', function () {
  const companyId = $(this).data('company-id')

  $.ajax({
    type: 'GET',
    dataType: "script",
    url: `/companies/${companyId}/external_reports/infocred_debts_reports?table_id=infocred-table`,
  })
})